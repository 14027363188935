const ACTION_TYPES = {
	SET: 'SET'
};

const reducers = (state, action) => {
	switch (action.type) {
		case ACTION_TYPES.SET: {
			return {
				...state,
				filters: action.data
			};
		}
		default: {
			return state;
		}
	}
};

export { reducers, ACTION_TYPES };
