import { gql } from '@apollo/client';

export const GET_POSTS = gql`
	query GetPosts {
		listPosts {
			id
			title
		}
	}
`;

export const GET_POSTS_PAGINATE = gql`
	query PaginatePosts(
		$first: Int! = 30
		$page: Int! = 1
		$search: String
		$where: QueryPaginatePostsWhereWhereConditions
	) {
		paginatePosts(
			first: $first
			page: $page
			search: $search
			where: $where
		) {
			paginatorInfo {
				count
				currentPage
				firstItem
				perPage
				lastItem
				total
			}
			data {
				id
				title
				subtitle
				cover_image
				file
				template_fields
				status
				created_at
				topic {
					id
					title
					category {
						id
						title
					}
				}
				template {
					id
					title
				}
			}
		}
	}
`;

export const GET_POST = gql`
	query GetPost($id: ID!) {
		getPost(id: $id) {
			id
			title
			subtitle
			cover_image
			file
			url_embed
			template_id
			template_fields
			status
			topic {
				id
				title
				category {
					id
					title
				}
			}
			template {
				id
				title
			}
			imports {
				id
				type
				filename
				key
			}
		}
	}
`;

export const CREATE_POST = gql`
	mutation CreatePost($input: CreatePost) {
		createPost(input: $input) {
			id
			title
			subtitle
			cover_image
			file
			template_fields
			topic {
				id
				title
				category {
					id
					title
				}
			}
			template {
				id
				title
			}
		}
	}
`;

export const UPDATE_POST = gql`
	mutation UpdatePost($input: UpdatePost) {
		updatePost(input: $input) {
			id
			title
			subtitle
			cover_image
			file
			template_fields
			topic {
				id
				title
				category {
					id
					title
				}
			}
			template {
				id
				title
			}
		}
	}
`;

export const DELETE_POST = gql`
	mutation DeletePost($where: WhereConditions) {
		deletePost(where: $where) {
			id
		}
	}
`;

export const DELETE_IMPORT = gql`
	mutation DeleteImport($where: WhereConditions) {
		deleteImport(where: $where) {
			id
		}
	}
`;

export const GET_TEMPLATES = gql`
	query GetTemplates {
		listTemplates {
			id
			title
			fields
		}
	}
`;
