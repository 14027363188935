import { useHistory } from 'react-router-dom';

import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	HStack,
	Input,
	Select,
	Skeleton,
	Text,
	useColorModeValue,
	useToast
} from '@chakra-ui/react';
import { useCreateTopic } from 'hooks/topics';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';

import { useAllCategories } from 'hooks/categories';

const schema = yup.object().shape({
	title: yup.string().required('O campo Título é obrigatório'),
	category_id: yup.string().required('O campo Menu Pai é obrigatório')
});

const CreateTopic = () => {
	const methods = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		resolver: yupResolver(schema)
	});
	const toast = useToast();
	const history = useHistory();

	const inputHover = useColorModeValue('appai.main', 'appai.main');
	const textColor = useColorModeValue('gray.700', 'white');

	const {
		data: { listCategories: categories } = [],
		loading: loadingCategories
	} = useAllCategories();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset
	} = methods;

	const [createTopic, { loading }] = useCreateTopic({
		onSuccess: (data) => {
			reset();
			toast({
				title: 'Tópico cadastrado com sucesso.',
				status: 'success',
				duration: 5000,
				isClosable: true
			});
			history.push(`/sistema/conteudos/topicos/${data.createTopic.id}`);
		},
		onError: () => {
			toast({
				title: 'Ocorreu um erro',
				status: 'error',
				duration: 5000,
				isClosable: true
			});
		}
	});

	const onSubmit = (data) => {
		const variables = {
			input: {
				...data
			}
		};

		createTopic({ variables });
	};

	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card w={{ md: '100%', lg: '50%' }}>
				<CardHeader mb="40px">
					<Flex direction="column">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Cadastrar Tópico
						</Text>
						<Text
							color="gray.400"
							fontWeight="normal"
							fontSize="sm"
						>
							Preencha os campos para realizar o cadastro
						</Text>
					</Flex>
				</CardHeader>
				<CardBody>
					<FormProvider {...methods}>
						<form
							style={{ width: '100%' }}
							onSubmit={handleSubmit(onSubmit)}
						>
							<Flex direction="column" w="100%">
								<Grid
									templateColumns={{
										sm: '1fr',
										md: 'repeat(2, 1fr)'
									}}
									gap="24px"
								>
									<FormControl isInvalid={errors.title}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Título
										</FormLabel>
										<Input
											{...register('title')}
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
										/>
										{errors.title && (
											<FormErrorMessage>
												{errors.title.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.parent_id}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Menu Pai
										</FormLabel>
										{loadingCategories ? (
											<Skeleton height="40px" />
										) : (
											<Select
												{...register('category_id')}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
												placeholder="Selecione..."
											>
												{categories?.map((category) => (
													<option value={category.id}>
														{category.title}
													</option>
												))}
											</Select>
										)}
										{errors.category_id && (
											<FormErrorMessage>
												{errors.category_id.message}
											</FormErrorMessage>
										)}
									</FormControl>
								</Grid>
								<Flex
									w="full"
									justifyContent="flex-end"
									mt="24px"
								>
									<HStack spacing={4}>
										<Button
											disabled={loading}
											variant="ghost"
											colorScheme="gray"
											alignSelf="flex-end"
											size="md"
											onClick={() => history.goBack()}
										>
											Cancelar
										</Button>
										<Button
											isLoading={loading}
											disabled={loading}
											colorScheme="purple"
											type="submit"
											alignSelf="flex-end"
											size="md"
										>
											Cadastrar
										</Button>
									</HStack>
								</Flex>
							</Flex>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default CreateTopic;
