import { CategoriesProvider } from 'hooks/categories/index';
import { TopicsProvider } from 'hooks/topics/index';
import ManagerQueryProvider from 'services/manager';

const DashboardsProvider = ({ children }) => (
	<ManagerQueryProvider>
		<CategoriesProvider>
			<TopicsProvider>{children}</TopicsProvider>
		</CategoriesProvider>
	</ManagerQueryProvider>
);

export default DashboardsProvider;
