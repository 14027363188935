import { gql } from '@apollo/client';

export const GET_TICKETS = gql`
	query GetTickets {
		listTickets {
			id
			status
			priority
			source
			causes
			abstract
			created_at
			updated_at
			deleted_at
		}
	}
`;

export const GET_TICKETS_PAGINATE = gql`
	query GET_TICKETS(
		$first: Int! = 30
		$page: Int! = 1
		$where: QueryPaginateTicketsWhereWhereConditions
	) {
		paginateTickets(first: $first, page: $page, where: $where) {
			paginatorInfo {
				count
				currentPage
				firstItem
				perPage
				lastItem
				total
			}
			data {
				id
				status
				priority
				source
				causes
				abstract
				created_at
				updated_at
				deleted_at
			}
		}
	}
`;

export const GET_TICKET = gql`
	query GetTicket($id: ID!) {
		getTicket(id: $id) {
			id
			status
			priority
			source
			causes
			abstract
			created_at
			updated_at
			deleted_at
		}
	}
`;

export const CREATE_TICKET = gql`
	mutation CreateTicket($input: CreateTicket) {
		createTicket(input: $input) {
			id
			status
			priority
			source
			causes
			abstract
			created_at
			updated_at
			deleted_at
		}
	}
`;

export const UPDATE_TICKET = gql`
	mutation UpdateTicket($input: UpdateTicket) {
		updateTicket(input: $input) {
			id
			status
			priority
			source
			causes
			abstract
			created_at
			updated_at
			deleted_at
		}
	}
`;
