// Chakra imports
import { ChakraProvider, Portal, useDisclosure } from '@chakra-ui/react';
import 'assets/css/pud-dashboard-styles.css';
import React, { useState } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import Configurator from 'components/Configurator/Configurator';
import FixedPlugin from 'components/FixedPlugin/FixedPlugin';
import Footer from 'components/Footer/Footer';
// Custom components
import MainPanel from 'components/Layout/MainPanel';
import PanelContainer from 'components/Layout/PanelContainer';
import PanelContent from 'components/Layout/PanelContent';
// Layout components
import AdminNavbar from 'components/Navbars/AdminNavbar';
import Sidebar from 'components/Sidebar/Sidebar';
import { SidebarContext } from 'contexts/SidebarContext';
import 'react-quill/dist/quill.snow.css'; // ES6
import routes from 'routes';
// Custom Chakra theme
import theme from 'theme/theme';
import menuItems from 'menu';
import BackgroundCard from 'components/BackgroundCard/BackgroundCard';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';

export default function Dashboard(props) {
	const { ...rest } = props;
	// states and functions
	const [sidebarVariant, setSidebarVariant] = useState('transparent');
	const [fixed, setFixed] = useState(false);
	const [toggleSidebar, setToggleSidebar] = useState(false);
	const [sidebarWidth, setSidebarWidth] = useState(275);
	// ref for main panel div
	const mainPanel = React.createRef();
	// functions for changing the states from components
	const getRoute = () =>
		window.location.pathname !== '/admin/full-screen-maps';
	const getActiveRoute = (routes) => {
		const activeRoute =
			window.location.pathname == '/sistema/perfil' ? 'Perfil' : 'Appai';
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				const collapseActiveRoute = getActiveRoute(routes[i].items);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else if (routes[i].category) {
				const categoryActiveRoute = getActiveRoute(routes[i].items);
				if (categoryActiveRoute !== activeRoute) {
					return categoryActiveRoute;
				}
			} else if (window.location.href.indexOf(routes[i].path) !== -1) {
				return routes[i].name;
			}
		}
		return activeRoute;
	};
	const getActiveNavbar = (routes) => {
		const activeNavbar = false;
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				const collapseActiveNavbar = getActiveNavbar(routes[i].items);
				if (collapseActiveNavbar !== activeNavbar) {
					return collapseActiveNavbar;
				}
			} else if (routes[i].category) {
				const categoryActiveNavbar = getActiveNavbar(routes[i].items);
				if (categoryActiveNavbar !== activeNavbar) {
					return categoryActiveNavbar;
				}
			} else if (window.location.href.indexOf(routes[i].path) !== -1) {
				return routes[i].secondaryNavbar;
			}
		}
		return activeNavbar;
	};
	const getRoutes = (routes) =>
		routes.map((prop, key) => {
			if (prop.provider !== null)
				return (
					<PrivateRoute
						exact
						path={prop.layout + prop.path}
						key={key}
						roles={prop.roles}
					>
						<prop.provider>
							<prop.component />
						</prop.provider>
					</PrivateRoute>
				);
			return (
				<PrivateRoute
					exact
					path={prop.layout + prop.path}
					key={key}
					component={prop.component}
					roles={prop.roles}
				/>
			);
		});

	const { isOpen, onOpen, onClose } = useDisclosure();
	document.documentElement.dir = 'ltr';
	document.documentElement.layout = 'admin';
	// Chakra Color Mode
	return (
		<ChakraProvider theme={theme} resetCss={false}>
			<SidebarContext.Provider
				value={{
					sidebarWidth,
					setSidebarWidth,
					toggleSidebar,
					setToggleSidebar
				}}
			>
				<Sidebar
					routes={menuItems}
					logoText=""
					display="none"
					sidebarVariant={sidebarVariant}
					{...rest}
				/>
				<MainPanel
					ref={mainPanel}
					w={{
						base: '100%',
						xl: `calc(100% - ${sidebarWidth}px)`
					}}
				>
					<Portal>
						<AdminNavbar
							onOpen={onOpen}
							logoText=""
							brandText={getActiveRoute(menuItems)}
							secondary={getActiveNavbar(menuItems)}
							fixed={fixed}
							{...rest}
						/>
					</Portal>

					{getRoute() ? (
						<PanelContent>
							<PanelContainer>
								<BackgroundCard />
								<Switch>
									{getRoutes(routes)}
									<Redirect
										from="/sistema"
										to="/sistema/dashboards/default"
									/>
								</Switch>
							</PanelContainer>
						</PanelContent>
					) : null}
					<Footer />
					<Portal>
						<FixedPlugin fixed={fixed} onOpen={onOpen} />
					</Portal>
					<Configurator
						secondary={getActiveNavbar(routes)}
						isOpen={isOpen}
						onClose={onClose}
						isChecked={fixed}
						onSwitch={(value) => {
							setFixed(value);
						}}
						onOpaque={() => setSidebarVariant('opaque')}
						onTransparent={() => setSidebarVariant('transparent')}
						sidebarVariant={sidebarVariant}
					/>
				</MainPanel>
			</SidebarContext.Provider>
		</ChakraProvider>
	);
}
