import { Text, Select, VStack } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

const Fields = () => {
	const methods = useFormContext();

	const { register } = methods;

	return (
		<VStack spacing={2} alignItems="flex-start">
			<Text size="sm">Status</Text>
			<Select
				placeholder="Selecione uma opção..."
				{...register('status')}
			>
				<option value={1}>Ativo</option>
				<option value={0}>Inativo</option>
			</Select>
		</VStack>
	);
};

export default Fields;
