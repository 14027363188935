import { ResponsivePie } from '@nivo/pie';
import { useColorModeValue } from '@chakra-ui/react';
import { risksColorsPlus } from 'variables/colors';

import { border, tooltip } from 'variables/nivo/default';
import {
	arc,
	arcLabelsTextColor,
	arcLinkLabelsColor,
	legendsDark,
	legendsLight,
	margin,
	pieProps
} from 'variables/nivo/pie';

const buildData = (data) => {
	const values = [];

	if (!data) return values;

	values.push({
		id: 'cinco',
		label: 'Cincou ou mais porções',
		value: data.cinco
	});
	values.push({
		id: 'tres',
		label: 'Três ou quatro porções',
		value: data.tres
	});
	values.push({
		id: 'duas',
		label: 'Duas porções',
		value: data.duas
	});
	values.push({
		id: 'uma',
		label: 'Uma porção',
		value: data.uma
	});
	values.push({
		id: 'nenhuma',
		label: 'Nenhuma porção',
		value: data.nenhuma
	});
	return values;
};

const total = (data) =>
	data.cinco + data.tres + data.duas + data.uma + data.nenhuma;

const VegatableConsumptionChart = ({ data }) => (
	<ResponsivePie
		data={buildData(data)}
		margin={margin}
		colors={risksColorsPlus}
		{...border}
		{...arc}
		arcLinkLabelsColor={arcLinkLabelsColor}
		arcLabelsTextColor={arcLabelsTextColor}
		legends={useColorModeValue(legendsLight, legendsDark)}
		tooltip={({ datum }) =>
			tooltip(datum.label, datum.value, total(data), datum.color)
		}
		{...pieProps}
		enableArcLabels={false}
		innerRadius={0.7}
	/>
);

export default VegatableConsumptionChart;
