import { FiltersProvider } from 'hooks/filters';
import { NewsletterProvider as Provider } from 'hooks/newsletters/index';

const NewsletterProvider = ({ children }) => (
	<FiltersProvider>
		<Provider>{children}</Provider>
	</FiltersProvider>
);

export default NewsletterProvider;
