import { ResponsiveBar } from '@nivo/bar';
import { useColorModeValue } from '@chakra-ui/react';
import { padding } from 'variables/nivo/bar';
import { monochromeMainColors } from 'variables/colors';
import {
	axisLeft,
	themeDark,
	themeLight,
	tooltip
} from 'variables/nivo/default';

const buildData = (data) => {
	const values = [];

	if (!data) return values;

	values.push({
		id: 'nenhum',
		label: 'Nenhuma',
		value: data.nenhum
	});
	values.push({
		id: 'outros',
		label: 'Outros',
		value: data.outros
	});
	values.push({
		id: 'viagem',
		label: 'Viagem',
		value: data.viagem
	});
	values.push({
		id: 'teatro',
		label: 'Teatro',
		value: data.teatro
	});
	values.push({
		id: 'praia',
		label: 'Praia',
		value: data.praia
	});
	values.push({
		id: 'passeio',
		label: 'Passeio cultural',
		value: data.passeio
	});
	values.push({
		id: 'concerto',
		label: 'Concerto musical',
		value: data.concerto
	});

	return values;
};

const LeisureChart = ({ data }) => (
	<ResponsiveBar
		data={buildData(data)}
		keys={['value']}
		indexBy="label"
		layout="horizontal"
		margin={{ top: 5, right: 10, bottom: 5, left: 120 }}
		padding={padding}
		colors={monochromeMainColors}
		labelSkipWidth={11}
		theme={useColorModeValue(themeLight, themeDark)}
		axisBottom={null}
		axisLeft={axisLeft}
		labelTextColor="#FFF"
		tooltip={({ indexValue, value }) => tooltip(indexValue, value)}
	/>
);

export default LeisureChart;
