import { ResponsiveBar } from '@nivo/bar';
import { useColorMode } from '@chakra-ui/react';
import {
	axisLeft,
	themeDark,
	themeLight,
	tooltip,
	axisBottom
} from 'variables/nivo/default';
import { margin } from 'variables/nivo/bar';
import { monochromeMainColors } from 'variables/colors';

const data = [
	{
		name: 'Catarata',
		total: 5
	},
	{
		name: 'Alzheimer',
		total: 4
	},
	{
		name: 'Diabetes',
		total: 3
	},
	{
		name: 'Surdez',
		total: 2
	}
];

const ExampleChart = () => {
	const { colorMode } = useColorMode();

	const getColor = (label) =>
		label == 'Declaração de Saúde'
			? monochromeMainColors[0]
			: monochromeMainColors[1];

	return (
		<ResponsiveBar
			data={data}
			keys={['total']}
			layout="horizontal"
			indexBy="name"
			margin={{ ...margin, right: 50, left: 80 }}
			padding={0.1}
			innerPadding={0.1}
			colors={monochromeMainColors}
			colorBy={({ indexValue }) => getColor(indexValue)}
			theme={colorMode === 'light' ? themeLight : themeDark}
			axisBottom={axisBottom}
			axisLeft={axisLeft}
			labelTextColor="#FFF"
			enableLabel={false}
			tooltip={({ indexValue, value }) => tooltip(`${indexValue}`, value)}
		/>
	);
};

export default ExampleChart;
