import { Link, useHistory, useParams } from 'react-router-dom';
import {
	Button,
	Flex,
	FormControl,
	FormLabel,
	Grid,
	HStack,
	Skeleton,
	Text,
	useColorModeValue
} from '@chakra-ui/react';

import { useContact, useContacts } from 'hooks/contacts';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { phoneFormat } from 'functions/maskFormats';

const ViewContact = () => {
	const { id } = useParams();
	const { contact } = useContacts();
	const { loading } = useContact(id);
	const history = useHistory();
	const textColor = useColorModeValue('gray.700', 'white');

	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card w={{ md: '100%', lg: '50%' }}>
				<CardHeader mb="40px">
					<Flex direction="row" w="100%" justify="space-between">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Contato
						</Text>
						<Flex>
							<HStack spacing={4} alignItems="flex-start">
								<Link to={`${id}/editar`}>
									<Button colorScheme="purple" size="md">
										Editar
									</Button>
								</Link>
								<Button
									variant="ghost"
									colorScheme="gray"
									size="md"
									onClick={() => history.goBack()}
								>
									Voltar
								</Button>
							</HStack>
						</Flex>
					</Flex>
				</CardHeader>
				<CardBody>
					<Flex direction="column" w="100%">
						<Grid
							templateColumns={{
								sm: '1fr',
								md: 'repeat(2, 1fr)'
							}}
							gap="24px"
							mt="24px"
						>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Nome
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text
										color={textColor}
										fontSize="lg"
										fontWeight="bold"
										mb="3px"
									>
										{contact?.name}
									</Text>
								</Skeleton>
							</FormControl>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Matrícula
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text
										color={textColor}
										fontSize="lg"
										fontWeight="bold"
										mb="3px"
									>
										{contact?.appai_id}
									</Text>
								</Skeleton>
							</FormControl>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Telefone
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text
										color={textColor}
										fontSize="lg"
										fontWeight="bold"
										mb="3px"
									>
										{phoneFormat(contact?.phone)}
									</Text>
								</Skeleton>
							</FormControl>
						</Grid>
					</Flex>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default ViewContact;
