import { useFormContext } from 'react-hook-form';
import { Text, Select, VStack } from '@chakra-ui/react';
import { useAllCategories } from 'hooks/categories';

const Fields = () => {
	const methods = useFormContext();
	const { data: { listCategories: categories } = [] } = useAllCategories();

	const { register } = methods;

	return (
		<VStack spacing={2} alignItems="flex-start">
			<Text size="sm">Categoria</Text>
			<Select
				placeholder="Selecione uma opção..."
				{...register('category_id')}
			>
				{categories?.map((category) => (
					<option value={category?.id}>{category?.title}</option>
				))}
			</Select>
		</VStack>
	);
};

export default Fields;
