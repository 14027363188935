import { createContext, useContext, useReducer } from 'react';

import { reducers } from './reducers';

const FiltersStateContext = createContext();
const FiltersDispatchContext = createContext();

const initialState = {
	filters: {}
};

const FiltersProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducers, initialState);

	return (
		<FiltersStateContext.Provider
			value={{
				...state
			}}
		>
			<FiltersDispatchContext.Provider value={dispatch}>
				{children}
			</FiltersDispatchContext.Provider>
		</FiltersStateContext.Provider>
	);
};

const useFilters = () => {
	const context = useContext(FiltersStateContext);

	if (!context)
		throw new Error('useFilters must be used within an FiltersProvider');

	return context;
};

const useDispatch = () => {
	const dispatch = useContext(FiltersDispatchContext);

	if (dispatch === undefined)
		throw new Error('useDispatch must be used within a FiltersProvider');

	return dispatch;
};

export { FiltersProvider, useFilters, useDispatch };
