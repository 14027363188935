/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { Box, useStyleConfig } from '@chakra-ui/react';

function PanelContent(props) {
	const { variant, children, ...rest } = props;
	const styles = useStyleConfig('PanelContent', { variant });

	return (
		<Box __css={styles} {...rest} pr="30px">
			{children}
		</Box>
	);
}

export default PanelContent;
