import { API } from '../api';

export async function ME() {
	const { data } = await API.get(`api/user/me`);

	return data;
}

export async function GET_ALL_REGIONALS() {
	const { data } = await API.get(`api/user/my-regionals`);

	return data;
}

export async function SELECT_REGIONAL(payload) {
	const { data } = await API.post(`api/user/regional-change`, payload);

	return data;
}
