import { FiltersProvider } from 'hooks/filters';
import { NewAssociatesProvider as Provider } from 'hooks/newAssociates/index';

const NewAssociatesProvider = ({ children }) => (
	<FiltersProvider>
		<Provider>{children}</Provider>
	</FiltersProvider>
);

export default NewAssociatesProvider;
