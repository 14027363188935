import { Box, Flex, Image, Text, VStack, useToast } from '@chakra-ui/react';
import { TrashSimple } from '@phosphor-icons/react';
import { useDeleteImport } from 'hooks/posts';

const Imports = ({ imports = [] }) => {
	const toast = useToast();

	const [deleteImport] = useDeleteImport({
		onSuccess: () => {
			toast({
				title: `Arquivo removido com sucesso!`,
				status: 'success',
				duration: 5000,
				isClosable: true
			});
		}
	});

	const onDelete = (key) => {
		deleteImport({
			variables: {
				where: {
					column: 'ID',
					operator: 'EQ',
					value: key
				}
			}
		});
	};

	return (
		<Box w="full" mt="8">
			<VStack w="full" spacing={4}>
				{imports.map((file) => (
					<Flex w="full">
						<Box w="200px" h="120px" mr="4" position="relative">
							<Image src={file.key} w="full" h="full" />
							<Box
								w="35px"
								h="35px"
								borderRadius="full"
								position="absolute"
								right="2"
								top="2"
								backgroundColor="white"
								display="flex"
								justifyContent="center"
								alignItems="center"
								cursor="pointer"
								onClick={() => onDelete(file.id)}
							>
								<TrashSimple size="22" />
							</Box>
						</Box>
						<Text fontWeight="bold" fontSize="md">
							{file.filename}
						</Text>
					</Flex>
				))}
			</VStack>
		</Box>
	);
};

export default Imports;
