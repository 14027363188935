import { gql } from '@apollo/client';

export const GET_NEW_ASSOCIATES = gql`
	query GetContacts {
		listContacts {
			id
			name
			registration_number
			phone
			created_at
			updated_at
			deleted_at
		}
	}
`;

export const GET_NEW_ASSOCIATES_PAGINATE = gql`
	query PaginateNewAssociates(
		$first: Int! = 30
		$page: Int! = 1
		$search: String
		$orderBy: [QueryPaginateNewAssociatesOrderByOrderByClause!]
	) {
		paginateNewAssociates(
			first: $first
			page: $page
			search: $search
			orderBy: $orderBy
		) {
			paginatorInfo {
				count
				currentPage
				firstItem
				perPage
				lastItem
				total
			}
			data {
				id
				description
				user {
					id
					name
				}
				imports {
					type
					filename
					key
				}
				created_at
				updated_at
				deleted_at
			}
		}
	}
`;

export const IMPORT_ASSOCIATES = gql`
	mutation ImportNewAssociate($input: ImportNewAssociate!) {
		importNewAssociate(input: $input) {
			id
			description
			user {
				id
				name
			}
			imports {
				type
				filename
				key
			}
			created_at
		}
	}
`;
