import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HStack, Text, Tooltip } from '@chakra-ui/react';
import {
	useNewAssociates,
	usePaginateNewAssociates
} from 'hooks/newAssociates';

import AsyncTable from 'components/Tables/AsyncTable';
import { useFilters } from 'hooks/filters';
import dateFormat from 'functions/dateFormat';
import { DownloadSimple, Info } from '@phosphor-icons/react';

const columns = [
	{
		Header: 'Data de envio',
		accessor: 'created_at',
		Cell: ({ value }) => (
			<Text>{dateFormat(value, 'dd/MM/yyyy HH:mm')}</Text>
		)
	},
	{
		Header: 'Usuário',
		accessor: 'user.name'
	},
	{
		Header: 'Descrição',
		accessor: 'description',
		Cell: ({ value }) => (
			<Tooltip cursor="pointer" label={value}>
				<Info size={20} weight="fill" />
			</Tooltip>
		)
	},
	{
		Header: 'Arquivo',
		accessor: 'imports[0].key',
		Cell: ({ value }) => (
			<Link to={{ pathname: value }} target="_blank">
				<HStack spacing={2} _hover={{ opacity: 0.5 }}>
					<DownloadSimple size={20} weight="bold" />
					<Text fontSize="md" fontWeight="bold">
						Baixar
					</Text>
				</HStack>
			</Link>
		)
	}
];

const NewAssociatesTable = () => {
	const { filters } = useFilters();
	const { rows, page, perPage, total, setToDelete, setDeleteModalOpen } =
		useNewAssociates();
	const [paginateAssociates, { loading }] = usePaginateNewAssociates();

	const deleteContact = (id) => {
		setToDelete(id);
		setDeleteModalOpen(true);
	};

	const fetchData = ({ pageSize, pageIndex, search }) => {
		if (pageSize != perPage) pageIndex = 1;
		paginateAssociates({
			variables: {
				first: pageSize || perPage,
				page: pageIndex || page,
				search: search || '',
				orderBy: [
					{
						column: 'CREATED_AT',
						order: 'DESC'
					}
				],
				...filters
			}
		});
	};

	useEffect(() => {
		if (filters && Object.keys(filters).length > 0) {
			paginateAssociates({
				variables: {
					first: perPage,
					page,
					...filters
				}
			});
		}
	}, [filters]);

	return (
		<AsyncTable
			fetchData={fetchData}
			isLoaded={!loading}
			data={!loading ? rows : [{}, {}, {}, {}]}
			columns={columns}
			total={total}
			size={perPage}
			asyncPage={page}
			searchByName
			baseRoute="contatos"
			removeAction={deleteContact}
			noView
			noEdit
			noDelete
		/>
	);
};

export default NewAssociatesTable;
