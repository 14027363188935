import { Badge, HStack, Text } from '@chakra-ui/react';

const StatusIndicator = ({ isActive, size = 'md' }) => (
	<HStack spacing={2} alignItems="center">
		<Badge
			backgroundColor={isActive ? 'green.500' : 'red.500'}
			height={size === 'md' ? '18px' : size === 'sm' ? '14px' : '18px'}
			width={size === 'md' ? '18px' : size === 'sm' ? '14px' : '18px'}
			borderRadius="full"
		/>
		<Text
			fontSize={size === 'md' ? '18px' : size === 'sm' ? '14px' : '18px'}
		>
			{!isActive ? 'Inativo' : 'Ativo'}
		</Text>
	</HStack>
);

export default StatusIndicator;
