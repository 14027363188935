import { createContext, useContext, useReducer, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';

import {
	GET_NEW_ASSOCIATES_PAGINATE,
	IMPORT_ASSOCIATES
} from 'graphql/newAssociates';

import { ACTION_TYPES, reducers } from './reducers';

const AssociatesStateContext = createContext();
const AssociatesDispatchContext = createContext();

const initialState = {
	rows: [],
	page: 1,
	perPage: 15,
	newAssociate: {}
};

const NewAssociatesProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducers, initialState);
	const [toDelete, setToDelete] = useState(null);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	return (
		<AssociatesStateContext.Provider
			value={{
				...state,
				toDelete,
				setToDelete,
				deleteModalOpen,
				setDeleteModalOpen
			}}
		>
			<AssociatesDispatchContext.Provider value={dispatch}>
				{children}
			</AssociatesDispatchContext.Provider>
		</AssociatesStateContext.Provider>
	);
};

const useNewAssociates = () => {
	const context = useContext(AssociatesStateContext);

	if (!context)
		throw new Error(
			'useAssociates must be used within an AssociatesProvider'
		);

	return context;
};

const useDispatch = () => {
	const dispatch = useContext(AssociatesDispatchContext);

	if (dispatch === undefined)
		throw new Error('useDispatch must be used within a AssociatesProvider');

	return dispatch;
};

const usePaginateNewAssociates = () => {
	const dispatch = useDispatch();

	return useLazyQuery(GET_NEW_ASSOCIATES_PAGINATE, {
		onCompleted: (data) => {
			window.history.replaceState(
				null,
				'',
				`./novos-associados?page=${data.paginateNewAssociates.paginatorInfo.currentPage}&size=${data.paginateNewAssociates.paginatorInfo.perPage}`
			);
			dispatch({
				type: ACTION_TYPES.LIST,
				data: data.paginateNewAssociates
			});
		}
	});
};

const useCreateNewAssociate = (options = {}) => {
	const dispatch = useDispatch();
	const { onSuccess, onError } = options;

	return useMutation(IMPORT_ASSOCIATES, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
			dispatch({ type: ACTION_TYPES.ADD, data: data.importNewAssociate });
		},
		onError: (error) => {
			if (onError) onError(error);
		}
	});
};

export {
	NewAssociatesProvider,
	useNewAssociates,
	useCreateNewAssociate,
	usePaginateNewAssociates
};
