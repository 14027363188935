import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			refetchOnMount: true,
			retry: 1
		}
	}
});

export const QueryProvider = ({ children }) => (
	<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);
