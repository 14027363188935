import { ResponsivePie } from '@nivo/pie';
import { useColorModeValue } from '@chakra-ui/react';
import { risksColors } from 'variables/colors';

import { border, tooltip } from 'variables/nivo/default';
import {
	arc,
	arcLabelsTextColor,
	arcLinkLabelsColor,
	legendsDark,
	legendsLight,
	margin,
	pieProps
} from 'variables/nivo/halfPie';

const buildData = (data) => {
	const values = [];

	if (!data) return values;

	values.push({
		id: 'respondidos',
		label: 'Respondidos',
		value: data.respondidos
	});
	values.push({
		id: 'nao_respondidos',
		label: 'Não Respondidos',
		value: data.nao_respondidos
	});
	return values;
};

const total = (data) => data.respondidos + data.nao_respondidos;

const AssessmentsChart = ({ data }) => (
	<ResponsivePie
		data={buildData(data)}
		margin={margin}
		colors={[risksColors[0], risksColors[2]]}
		{...border}
		{...arc}
		arcLinkLabelsColor={arcLinkLabelsColor}
		arcLabelsTextColor={arcLabelsTextColor}
		legends={useColorModeValue(legendsLight, legendsDark)}
		tooltip={({ datum }) =>
			tooltip(datum.label, datum.value, total(data), datum.color)
		}
		{...pieProps}
		enableArcLabels={false}
		innerRadius={0.7}
	/>
);

export default AssessmentsChart;
