const ACTION_TYPES = {
	LIST: 'LIST',
	SELECT: 'SELECT',
	ADD: 'ADD',
	UPDATE: 'UPDATE',
	REMOVE: 'REMOVE',
	REMOVE_IMPORT: 'REMOVE_IMPORT',
	VIEW: 'VIEW',
	LIST_TEMPLATES: 'LIST_TEMPLATES'
};

const reducers = (state, action) => {
	switch (action.type) {
		case ACTION_TYPES.ALL: {
			return {
				...state,
				posts: action.data
			};
		}
		case ACTION_TYPES.LIST: {
			return {
				...state,
				rows: action.data.data,
				page: action.data.paginatorInfo.currentPage,
				perPage: action.data.paginatorInfo.perPage,
				total: action.data.paginatorInfo.total,
				currentPage: action.data.paginatorInfo.currentPage
			};
		}
		case ACTION_TYPES.ADD: {
			return {
				...state,
				rows: [...state.rows, action.data]
			};
		}
		case ACTION_TYPES.VIEW: {
			return {
				...state,
				post: action.data
			};
		}
		case ACTION_TYPES.UPDATE: {
			return {
				...state,
				posts:
					state?.posts?.length > 0
						? state.posts.map((item) =>
							item.id === action.data.post.id
								? action.data.post
								: item
						  )
						: [],
				rows:
					state?.rows?.length > 0
						? state.rows.map((item) =>
							item.id === action.data.post.id
								? action.data.post
								: item
						  )
						: []
			};
		}
		case ACTION_TYPES.REMOVE: {
			return {
				...state,
				rows: state?.rows?.filter(({ id }) => id !== action.id)
			};
		}
		case ACTION_TYPES.REMOVE_IMPORT: {
			const imports = state.post.imports.filter(
				(imp) => imp.id != action.id
			);
			const newPost = {
				...state.post,
				imports
			};
			return {
				...state,
				post: newPost
			};
		}
		case ACTION_TYPES.LIST_TEMPLATES: {
			return {
				...state,
				templates: action.data
			};
		}
		default: {
			return state;
		}
	}
};

export { reducers, ACTION_TYPES };
