import { gql } from '@apollo/client';

export const GET_REPORT = gql`
	query GetReports {
		reports {
			id
			label
			status
			url
			created_at
		}
	}
`;

export const GET_REPORTS_PAGINATE = gql`
	query paginateDocuments(
		$first: Int!, 
		$page: Int,
		$orderBy: [QueryPaginateDocumentsOrderByOrderByClause!]
		$where: QueryPaginateDocumentsWhereWhereConditions
	) {
		paginateDocuments(first: $first, page: $page, orderBy: $orderBy, where: $where) {
			data {
				id
				label
				status
				url
				created_at
			}
			paginatorInfo {
				count
				currentPage
				perPage
				total
			}
		}
	}
`;

export const CREATE_REPORT = gql`
	mutation CreateDocument($input: InputDocumentCreate!) {
		createDocument(input: $input) {
			id
			label
			status
			url
			created_at
		}
	}
`;
