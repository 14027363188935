import { Link, useHistory, useParams } from 'react-router-dom';

import {
	Button,
	Flex,
	FormControl,
	FormLabel,
	Grid,
	HStack,
	Skeleton,
	Text,
	useColorModeValue
} from '@chakra-ui/react';
import { useTicket } from 'hooks/tickets';

import dateFormat from 'functions/dateFormat';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';

const ViewTicket = () => {
	const { id } = useParams();
	const { data, loading } = useTicket(id);
	const ticket = data?.getTicket ?? {};

	const history = useHistory();

	const textColor = useColorModeValue('gray.700', 'white');

	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card w={{ md: '100%', lg: '50%' }}>
				<CardHeader mb="40px">
					<Flex direction="row" w="100%" justify="space-between">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Suporte
						</Text>
						<Flex>
							<HStack spacing={4} alignItems="flex-start">
								<Link to={`${id}/editar`}>
									<Button colorScheme="purple" size="md">
										Editar
									</Button>
								</Link>
								<Button
									variant="ghost"
									colorScheme="gray"
									size="md"
									onClick={() => history.goBack()}
								>
									Voltar
								</Button>
							</HStack>
						</Flex>
					</Flex>
				</CardHeader>
				<CardBody>
					<Flex direction="column" w="100%">
						<Grid
							templateColumns={{
								sm: '1fr',
								md: 'repeat(2, 1fr)'
							}}
							gap="24px"
							mb="24px"
						>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Status
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text fontSize="lg" fontWeight="bold">
										{ticket?.status === 'open' && 'Aberto'}
										{ticket?.status === 'working' &&
											'Em andamento'}
										{ticket?.status === 'closed' &&
											'Finalizado'}
									</Text>
								</Skeleton>
							</FormControl>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Prioridade
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text fontSize="lg" fontWeight="bold">
										{ticket?.priority === 'low' && 'Baixa'}
										{ticket?.priority === 'normal' &&
											'Normal'}
										{ticket?.priority === 'high' && 'Alta'}
										{ticket?.priority === 'urgent' &&
											'Urgente'}
									</Text>
								</Skeleton>
							</FormControl>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Onde encontrou o erro?
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text fontSize="lg" fontWeight="bold">
										{ticket?.source}
									</Text>
								</Skeleton>
							</FormControl>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									O que o erro causa?
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text fontSize="lg" fontWeight="bold">
										{ticket?.causes}
									</Text>
								</Skeleton>
							</FormControl>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Resumo do Erro
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text fontSize="lg" fontWeight="bold">
										{ticket?.abstract}
									</Text>
								</Skeleton>
							</FormControl>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Aberto Em
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text fontSize="lg" fontWeight="bold">
										{dateFormat(ticket?.created_at)}
									</Text>
								</Skeleton>
							</FormControl>
						</Grid>
					</Flex>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default ViewTicket;
