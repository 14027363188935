import { Badge, HStack, Text } from '@chakra-ui/react';

const TicketStatusIndicator = ({ color = 'gray.500', label, size = 'md' }) => (
	<HStack spacing={2} alignItems="center">
		<Badge
			backgroundColor={color}
			height={size === 'md' ? '18px' : size === 'sm' ? '14px' : '18px'}
			width={size === 'md' ? '18px' : size === 'sm' ? '14px' : '18px'}
			borderRadius="full"
		/>
		<Text
			fontSize={size === 'md' ? '18px' : size === 'sm' ? '14px' : '18px'}
		>
			{label}
		</Text>
	</HStack>
);

export default TicketStatusIndicator;
