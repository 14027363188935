import { FormProvider, useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import {
	Button,
	Center,
	HStack,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
	Text,
	Box,
	useToast,
	Flex,
	Grid,
	FormControl,
	FormErrorMessage
} from '@chakra-ui/react';

import { useImportContacts, usePaginateContacts } from 'hooks/contacts';

import modeloImportacao from 'assets/csv/modelo_importacao_contatos.csv';

const ImportContacts = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [paginateContacts] = usePaginateContacts();
	const toast = useToast();

	const methods = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur'
	});

	const {
		formState: { errors },
		getValues,
		setError,
		reset
	} = methods;

	const { getRootProps, getInputProps, acceptedFiles, isDragActive } =
		useDropzone({
			accept: ['text/csv'],
			multiple: false
		});

	const handleDownload = () => {
		window.open(modeloImportacao, '_blank');
	};

	const [importBeneficiaries, { loading }] = useImportContacts({
		onSuccess: () => {
			paginateContacts();
			toast({
				title: 'Importação realizada com sucesso.',
				status: 'success',
				duration: 5000,
				isClosable: true
			});
			onClose();
			reset();
		},
		onError: () => {
			toast({
				title: 'Ocorreu um erro',
				status: 'error',
				duration: 5000,
				isClosable: true
			});
		}
	});

	const onSubmit = () => {
		const file = acceptedFiles[0];

		if (!file) {
			setError('file', {
				type: 'custom',
				message: 'Anexe um arquivo para realizar a importação'
			});
			return;
		}

		const unitId = getValues('unit_id');

		importBeneficiaries({
			variables: {
				unit_id: unitId,
				file
			}
		});
	};

	return (
		<>
			<Button colorScheme="purple" size="md" onClick={onOpen}>
				Importar Contatos
			</Button>
			<Modal
				isCentered
				onClose={onClose}
				isOpen={isOpen}
				motionPreset="slideInBottom"
				size="lg"
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Importação de Vidas</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<FormProvider {...methods}>
							<form style={{ width: '100%' }}>
								<Flex direction="column" w="100%">
									<Grid
										templateColumns={{
											sm: '1fr',
											md: 'repeat(1, 1fr)'
										}}
										gap="24px"
									>
										<FormControl isInvalid={errors.file}>
											<Center
												align="center"
												border="1px dashed"
												borderColor="green.500"
												borderRadius="15px"
												minH="200px"
												cursor="pointer"
												{...getRootProps({
													className: 'dropzone'
												})}
											>
												<Input {...getInputProps()} />
												<Text>
													{acceptedFiles?.length ===
													0 ? (
															<Text>
																{isDragActive
																	? 'Solte os arquivos aqui'
																	: 'Arraste ou selecione uma planilha aqui'}
															</Text>
														) : (
															<Box>
																<Text mb="1em">
																Arquivo
																selecionado:{' '}
																	{
																		acceptedFiles[0]
																			.name
																	}
																</Text>
															</Box>
														)}
												</Text>
											</Center>
											{errors.file && (
												<FormErrorMessage>
													{errors.file.message}
												</FormErrorMessage>
											)}
										</FormControl>
									</Grid>
								</Flex>
							</form>
						</FormProvider>
					</ModalBody>
					<ModalFooter>
						<Button
							disabled={loading}
							mr={3}
							variant="ghost"
							size="md"
							onClick={onClose}
						>
							Cancelar
						</Button>
						<HStack spacing={4}>
							<Button
								disabled={loading}
								colorScheme="purple"
								size="md"
								onClick={handleDownload}
							>
								Baixar planilha modelo
							</Button>
							<Button
								isLoading={loading}
								disabled={loading}
								colorScheme="purple"
								size="md"
								onClick={onSubmit}
							>
								Importar
							</Button>
						</HStack>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};

export default ImportContacts;
