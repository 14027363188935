const ACTION_TYPES = {
	LIST: 'LIST',
	SELECT: 'SELECT',
	ADD: 'ADD',
	UPDATE: 'UPDATE',
	UPDATE_STATUS: 'UPDATE_STATUS',
	REMOVE: 'REMOVE',
	VIEW: 'VIEW'
};

const reducers = (state, action) => {
	switch (action.type) {
		case ACTION_TYPES.LIST: {
			return {
				...state,
				rows: action.data.data,
				page: action.data.paginatorInfo.currentPage,
				perPage: action.data.paginatorInfo.perPage,
				total: action.data.paginatorInfo.total,
				currentPage: action.data.paginatorInfo.currentPage
			};
		}
		case ACTION_TYPES.ADD: {
			return {
				...state,
				rows: [...state.rows, action.data]
			};
		}
		case ACTION_TYPES.VIEW: {
			return {
				...state,
				associate: action.data
			};
		}
		case ACTION_TYPES.UPDATE: {
			return {
				...state,
				associates:
					state?.associates?.length > 0
						? state.associates.map((item) =>
							item.id === action.data.associate.id
								? action.data.associate
								: item
						  )
						: [],
				rows:
					state?.rows?.length > 0
						? state.rows.map((item) =>
							item.id === action.data.associate.id
								? action.data.associate
								: item
						  )
						: []
			};
		}
		case ACTION_TYPES.UPDATE_STATUS: {
			return {
				...state,
				rows: state.rows.map((row) => {
					if (row.id == action.data.id)
						row.status = action.data.status;
					return row;
				}),
				associate: { ...state.associate, status: action.data.status }
			};
		}
		case ACTION_TYPES.REMOVE: {
			return {
				...state,
				rows: state?.rows?.filter(({ id }) => id !== action.data.id)
			};
		}
		default: {
			return state;
		}
	}
};

export { reducers, ACTION_TYPES };
