import ChartToolTip from 'components/ChartToolTip/ChartToolTip';

const themeDark = {
	axis: {
		fontSize: '14px',
		fontWeight: 'bold',
		tickColor: '#EEE',
		ticks: {
			line: {
				stroke: '#EEE'
			},
			text: {
				fill: '#EEE'
			}
		},
		legend: {
			title: {
				text: {
					fill: '#EEE'
				}
			},
			text: {
				fill: '#EEE'
			}
		}
	},
	grid: {
		line: {
			stroke: '#EEEA',
			strokeWidth: 0.25
			// strokeDasharray: "10 10"
		}
	},
	labels: {
		text: {
			fontSize: 14,
			fill: '#EEEA',
			weight: 'bold'
		}
	},
	legends: {
		title: {
			text: {
				fill: '#EEE'
			}
		},
		text: {
			fill: '#EEE'
		}
	},
	tooltip: {
		container: {
			background: '#121121',
			color: '#fff'
		}
	}
};

const themeLight = {
	axis: {
		fontSize: '14px',
		tickColor: '#000',
		fontWeight: 'bold',
		ticks: {
			line: {
				stroke: '#000'
			},
			text: {
				fill: '#000'
			}
		},
		legend: {
			text: {
				fill: '#000'
			}
		}
	},
	grid: {
		line: {
			stroke: '#000',
			strokeWidth: 0.25
			// strokeDasharray: "10 10"
		}
	},
	labels: {
		text: {
			fontSize: 14,
			fill: '#000',
			weight: 'bold'
		}
	},
	legends: {
		title: {
			text: {
				fill: '#000'
			}
		},
		text: {
			fill: '#000'
		}
	}
};

const border = {
	borderWidth: 1,
	borderColor: {
		from: 'color',
		modifiers: [['darker', '0']]
	}
};

const axisBottom = {
	tickSize: 5,
	tickPadding: 5,
	tickRotation: 0,
	legendPosition: 'middle',
	legendOffset: 32
};

const axisTop = {
	tickSize: 5,
	tickPadding: 5,
	tickRotation: 0,
	legendPosition: 'middle',
	legendOffset: 32
};

const axisLeft = {
	tickSize: 5,
	tickPadding: 5,
	tickRotation: 0,
	legendPosition: 'middle',
	legendOffset: -40
};

const labelTextColor = {
	from: 'color',
	modifiers: [['brighter', 10]]
};

const chartColors = ['#216D40', '#2B4F6B', '#988B2E', '#3D2667', '#98422E'];

const tooltip = (label, value = null, total = null, color = null) => (
	<ChartToolTip label={label} value={value} total={total} color={color} />
);

export {
	themeDark,
	themeLight,
	border,
	axisBottom,
	axisTop,
	axisLeft,
	labelTextColor,
	chartColors,
	tooltip
};
