import {
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useColorModeValue,
	useToast
} from '@chakra-ui/react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCreateCarousel } from 'hooks/carousel';
import { FormProvider, useForm } from 'react-hook-form';
import UploadField from 'components/UploadField';

const schema = yup.object().shape({
	title: yup.string().required('O campo Título é obrigatório')
});

const AddImage = ({ isOpen, onClose, images }) => {
	const toast = useToast();

	const methods = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		resolver: yupResolver(schema)
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset
	} = methods;

	const onCloseModal = () => {
		reset();
		onClose();
	};

	const [createImage, { loading }] = useCreateCarousel({
		onSuccess: () => {
			toast({
				title: 'Imagem cadastrada com sucesso.',
				status: 'success',
				duration: 5000,
				isClosable: true
			});
			onCloseModal();
		},
		onError: () => {
			toast({
				title: 'Ocorreu um erro.',
				status: 'error',
				duration: 5000,
				isClosable: true
			});
		}
	});

	const onSubmit = (data) => {
		const variables = {
			input: {
				...data,
				order: String(images + 1),
				class: 'Carousel'
			}
		};

		createImage({ variables });
	};

	const inputHover = useColorModeValue('appai.main', 'appai.main');

	return (
		<Modal
			isCentered
			onClose={onCloseModal}
			isOpen={isOpen}
			motionPreset="slideInBottom"
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Cadastrar Nova Imagem</ModalHeader>
				<ModalCloseButton />
				<FormProvider {...methods}>
					<form
						style={{ width: '100%' }}
						onSubmit={handleSubmit(onSubmit)}
					>
						<ModalBody>
							<Grid
								templateColumns={{
									sm: '1fr',
									md: 'repeat(1, 1fr)'
								}}
								gap="24px"
								mb="24px"
							>
								<FormControl
									isInvalid={errors?.label}
									mb="0.5rem"
								>
									<FormLabel
										fontSize="xs"
										fontWeight="bold"
										mb="10px"
									>
										Titulo
									</FormLabel>
									<Input
										{...register('title')}
										borderRadius="8px"
										focusBorderColor={inputHover}
										fontSize="md"
										color="gray.400"
									/>
									{errors?.label && (
										<FormErrorMessage>
											{errors?.label?.message}
										</FormErrorMessage>
									)}
								</FormControl>
								<UploadField label="Image" filePrefix="cover" />
							</Grid>
						</ModalBody>
						<ModalFooter>
							<Button
								mr={3}
								variant="ghost"
								disabled={loading}
								onClick={onCloseModal}
							>
								Cancelar
							</Button>
							<Button
								isLoading={loading}
								disabled={loading}
								colorScheme="green"
								type="submit"
							>
								Cadastrar
							</Button>
						</ModalFooter>
					</form>
				</FormProvider>
			</ModalContent>
		</Modal>
	);
};

export default AddImage;
