import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import './markdown.css';

import {
	Button,
	Center,
	Flex,
	FormControl,
	FormLabel,
	Grid,
	HStack,
	Skeleton,
	Spinner,
	Text,
	VStack,
	useColorModeValue
} from '@chakra-ui/react';

import { Browser, Download } from '@phosphor-icons/react';
import { usePost } from 'hooks/posts';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import ImagePreview from 'components/ImagePreview';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { postStatus } from 'variables/postStatus';

const ViewPost = () => {
	const { id } = useParams();
	const { data: { getPost: post } = {}, loading } = usePost(id);
	const [templateFields, setTemplateFields] = useState({});
	const history = useHistory();
	const textColor = useColorModeValue('gray.700', 'white');

	useEffect(() => {
		if (post?.template_fields) {
			const parsed = JSON.parse(JSON.parse(post.template_fields));
			setTemplateFields(parsed);
		}
	}, [post]);

	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card w={{ md: '100%', lg: '50%' }}>
				<CardHeader mb="40px">
					<Flex direction="row" w="100%" justify="space-between">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Post
						</Text>
						<Flex>
							<HStack spacing={4} alignItems="flex-start">
								{post?.status == 'draft' && (
									<a
										href={`${process.env.REACT_APP_APPAI_FRONT_URL}/rascunho/${id}`}
										target="_blank"
										rel="noreferrer"
									>
										<Button
											colorScheme="purple"
											size="md"
											leftIcon={<Browser size="18" />}
										>
											Prévia
										</Button>
									</a>
								)}
								{/* {post?.status == 'published' && (
									<a
										href={`${process.env.REACT_APP_APPAI_FRONT_URL}/${id}`}
										target="_blank"
										rel="noreferrer"
									>
										<Button
											colorScheme="purple"
											size="md"
											leftIcon={<Browser size="18" />}
										>
											Visualizar
										</Button>
									</a>
								)} */}
								<Link to={`${id}/editar`}>
									<Button colorScheme="purple" size="md">
										Editar
									</Button>
								</Link>
								<Button
									variant="ghost"
									colorScheme="gray"
									size="md"
									onClick={() => history.goBack()}
								>
									Voltar
								</Button>
							</HStack>
						</Flex>
					</Flex>
				</CardHeader>
				<CardBody>
					<Flex direction="column" w="100%">
						<Grid
							templateColumns={{
								sm: '1fr',
								md: 'repeat(2, 1fr)'
							}}
							gap="24px"
							marginY="24px"
						>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Status
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text
										color={textColor}
										fontSize="lg"
										fontWeight="bold"
										mb="3px"
									>
										{post?.status &&
											postStatus[post.status]}
									</Text>
								</Skeleton>
							</FormControl>
						</Grid>
						<Grid
							templateColumns={{
								sm: '1fr',
								md: 'repeat(2, 1fr)'
							}}
							gap="24px"
							marginY="24px"
						>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Título
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text
										color={textColor}
										fontSize="lg"
										fontWeight="bold"
									>
										{post?.title}
									</Text>
								</Skeleton>
							</FormControl>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Subtítulo
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text
										color={textColor}
										fontSize="lg"
										fontWeight="bold"
									>
										{post?.subtitle}
									</Text>
								</Skeleton>
							</FormControl>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Categoria
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text
										color={textColor}
										fontSize="lg"
										fontWeight="bold"
									>
										{post?.topic?.category?.title}
									</Text>
								</Skeleton>
							</FormControl>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Tópico
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text
										color={textColor}
										fontSize="lg"
										fontWeight="bold"
									>
										{post?.topic?.title}
									</Text>
								</Skeleton>
							</FormControl>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Template
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text
										color={textColor}
										fontSize="lg"
										fontWeight="bold"
										mb="3px"
									>
										{post?.template?.title}
									</Text>
								</Skeleton>
							</FormControl>
							{post?.url_embed && (
								<FormControl>
									<FormLabel
										color={textColor}
										fontWeight="bold"
										fontSize="xs"
									>
										URL do Vídeo
									</FormLabel>
									<Skeleton
										minHeight="25px"
										isLoaded={!loading}
									>
										<a
											href={post?.url_embed}
											target="_blank"
											rel="noreferrer"
										>
											<Text
												color={textColor}
												fontSize="lg"
												fontWeight="bold"
												mb="3px"
											>
												Acessar
											</Text>
										</a>
									</Skeleton>
								</FormControl>
							)}
						</Grid>

						{loading ? (
							<Center>
								<Spinner padding="1em" size="xl" mb="24px" />
							</Center>
						) : (
							<Flex direction="column" gap="24px">
								<FormControl>
									<FormLabel
										color={textColor}
										fontWeight="bold"
										fontSize="xs"
									>
										Imagem de capa
									</FormLabel>
									<ImagePreview
										link={
											post?.imports?.filter(
												(i) => i?.type === 'cover'
											)[0]?.key
										}
									/>
								</FormControl>

								<VStack spacing="24px">
									{Object.keys(templateFields).length > 0 &&
										Object.keys(templateFields).map(
											(key) => (
												<FormControl key={key}>
													<FormLabel
														color={textColor}
														fontWeight="bold"
														fontSize="xs"
													>
														{key}
													</FormLabel>
													{key === 'body' ? (
														<ReactMarkdown
															remarkPlugins={[
																remarkGfm
															]}
														>
															{
																templateFields[
																	key
																]
															}
														</ReactMarkdown>
													) : (
														<Text
															fontSize="lg"
															fontWeight="bold"
														>
															{
																templateFields[
																	key
																]
															}
														</Text>
													)}
												</FormControl>
											)
										)}
								</VStack>

								<Flex gap="12px" direction="column">
									{post?.imports
										?.filter((i) =>
											i.type.includes('template')
										)
										?.map((document) => (
											<FormControl>
												<FormLabel
													color={textColor}
													fontWeight="bold"
													fontSize="xs"
												>
													{document?.type}
												</FormLabel>
												<Flex
													align="center"
													_hover={{
														cursor: 'pointer'
													}}
													onClick={() => {
														window.open(
															document?.key
														);
													}}
												>
													<Text
														color={textColor}
														fontSize="lg"
														fontWeight="bold"
														mr="12px"
													>
														{document?.filename}
													</Text>
													<Download
														color="grey"
														size={36}
													/>
												</Flex>
											</FormControl>
										))}
								</Flex>

								<FormControl>
									<FormLabel
										color={textColor}
										fontWeight="bold"
										fontSize="xs"
									>
										Outros arquivos para download
									</FormLabel>
									<Flex gap="12px" direction="column">
										{post?.imports
											?.filter(
												(i) => i.type === 'download'
											)
											?.map((document) => (
												<Flex
													align="center"
													_hover={{
														cursor: 'pointer'
													}}
													onClick={() => {
														window.open(
															document?.key
														);
													}}
												>
													<Text
														color={textColor}
														fontSize="lg"
														fontWeight="bold"
														mr="12px"
													>
														{document.filename}
													</Text>
													<Download
														color="grey"
														size={36}
													/>
												</Flex>
											))}
									</Flex>
								</FormControl>
							</Flex>
						)}
					</Flex>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default ViewPost;
