/* eslint-disable no-undef */
import {
	Flex,
	Select,
	Skeleton,
	Text,
	Tooltip,
	useColorModeValue
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import Icon from 'components/FontAwesomeIcons/Icon';
// import {useRegionals, useSelectRegional} from "hooks/globalRegionals";

const RegionalSelect = () => {
	const { pathname } = useLocation();
	const showInfo =
		pathname === '/sistema/administracao/regionais' ||
		pathname === '/sistema/administracao/cpt';
	// const {regionals, regional, isLoading, isFetching} = useRegionals();

	const bgColor = useColorModeValue('white', 'gray.700');
	const inputHover = useColorModeValue('appai.main', 'appai.main');

	const { mutateAsync: selectRegional, isLoading: loadingSelect } =
		useSelectRegional();

	const handleSelect = (regional) => {
		selectRegional({
			regional_id: regional
		});
	};

	return (
		<Flex size="lg" alignItems="center" mr="16px">
			{showInfo && (
				<Tooltip
					label="A visualização dessa página não é afetada por esse filtro."
					placement="bottom"
					fontSize="md"
				>
					<Text mr="8px" fontSize="lg" color="#FFF">
						<Icon icon="info" />
					</Text>
				</Tooltip>
			)}
			<Skeleton isLoaded={!isLoading && !isFetching && !loadingSelect}>
				<Select
					defaultValue
					value={regional}
					focusBorderColor={inputHover}
					backgroundColor={bgColor}
					borderRadius="8px"
					fontSize="md"
					color="gray.400"
					onChange={({ target }) => handleSelect(target.value)}
				>
					{regionals &&
						regionals.map((reg) => (
							<option key={reg.id} value={reg.id}>
								{reg.name}
							</option>
						))}
				</Select>
			</Skeleton>
		</Flex>
	);
};

export default RegionalSelect;
