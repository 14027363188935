import { FormProvider, useForm } from 'react-hook-form';
import Dropzone from 'react-dropzone';
import {
	Button,
	HStack,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
	useToast,
	Flex,
	Grid,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Textarea
} from '@chakra-ui/react';

import { useCreateNewAssociate } from 'hooks/newAssociates';
import { useState } from 'react';

const ImportNewAssociates = () => {
	const [files, setFiles] = useState([]);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const toast = useToast();

	const methods = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur'
	});

	const {
		register,
		formState: { errors },
		getValues,
		setValue,
		setError,
		reset
	} = methods;

	const [importAssociate, { loading }] = useCreateNewAssociate({
		onSuccess: () => {
			toast({
				title: 'Importação realizada com sucesso.',
				status: 'success',
				duration: 5000,
				isClosable: true
			});
			onClose();
			reset();
			setFiles([]);
			setValue('description', null);
		},
		onError: () => {
			toast({
				title: 'Ocorreu um erro',
				status: 'error',
				duration: 5000,
				isClosable: true
			});
		}
	});

	const onSubmit = () => {
		if (!files || files.length == 0) {
			setError('file', {
				type: 'custom',
				message: 'Anexe um arquivo para realizar a importação'
			});
			return;
		}

		importAssociate({
			variables: {
				input: {
					description: getValues('description'),
					class: 'NewAssociate',
					imports: files
				}
			}
		});
	};

	const handleDrop = (uploads) => {
		const filesArr = [];
		const prevFiles = getValues('imports') || [];

		uploads.map((upload) => {
			const file = new File([upload], upload.name, {
				type: upload?.type
			});

			filesArr.push(file);
		});

		setFiles([...prevFiles, ...filesArr]);
		setError('file', null);
	};

	return (
		<>
			<Button colorScheme="purple" size="md" onClick={onOpen}>
				Upload de Arquivos
			</Button>
			<Modal
				isCentered
				onClose={onClose}
				isOpen={isOpen}
				motionPreset="slideInBottom"
				size="lg"
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Upload de Arquivos</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<FormProvider {...methods}>
							<form style={{ width: '100%' }}>
								<Flex direction="column" w="100%">
									<Grid
										templateColumns={{
											sm: '1fr',
											md: 'repeat(1, 1fr)'
										}}
										gap="24px"
									>
										<FormControl>
											<FormLabel
												fontWeight="bold"
												fontSize="xs"
											>
												Descrição
											</FormLabel>
											<Textarea
												{...register('description')}
												borderRadius="8px"
												fontSize="md"
											/>
										</FormControl>
										<FormControl isInvalid={errors.file}>
											<Dropzone onDrop={handleDrop}>
												{({
													getRootProps,
													getInputProps
												}) => (
													<div
														{...getRootProps({
															className:
																'dropzone'
														})}
													>
														<input
															{...getInputProps()}
														/>
														{files.length < 1 ? (
															<p>
																Clique aqui para
																adicionar um
																arquivo
															</p>
														) : (
															<p>
																{files[0]?.name}
															</p>
														)}
													</div>
												)}
											</Dropzone>
											{errors.file && (
												<FormErrorMessage>
													{errors.file.message}
												</FormErrorMessage>
											)}
										</FormControl>
									</Grid>
								</Flex>
							</form>
						</FormProvider>
					</ModalBody>
					<ModalFooter>
						<Button
							disabled={loading}
							mr={3}
							variant="ghost"
							size="md"
							onClick={onClose}
						>
							Cancelar
						</Button>
						<HStack spacing={4}>
							<Button
								isLoading={loading}
								disabled={loading}
								colorScheme="purple"
								size="md"
								onClick={onSubmit}
							>
								Importar
							</Button>
						</HStack>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};

export default ImportNewAssociates;
