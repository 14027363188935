import { Amplify } from 'aws-amplify';

Amplify.configure({
	mandatorySignIn: true,
	region: process.env.REACT_APP_AWS_REGION,
	userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
	identityPoolId: process.env.REACT_APP_COGNITO_POOL_IDENTITY_ID,
	userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID
});

export default Amplify;
