const HOMOLOG_PREFIX = process.env.REACT_APP_REPORT_PREFIX
	? process.env.REACT_APP_REPORT_PREFIX
	: '';

const REPORT_TAGS = [
	{
		label: 'Relatório de Associados',
		tag: `${HOMOLOG_PREFIX}appai_associates`,
		filters: {}
	},
	{
		label: 'Relatório de Visualizações',
		tag: `${HOMOLOG_PREFIX}appai_views`,
		filters: {}
	},
	{
		label: 'Relatório de Visualizações Consolidadas',
		tag: `${HOMOLOG_PREFIX}appai_consolidated_views`,
		filters: {}
	}
];

const REPORT_STATUSES = [
	{ id: 1, label: 'Gerando', value: 'report_gen' },
	{ id: 2, label: 'Finalizado', value: 'report_done' },
	{ id: 3, label: 'Erro', value: 'report_error' }
];

export { REPORT_TAGS, REPORT_STATUSES };
