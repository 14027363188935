import { useEffect, useState } from 'react';
import { Grid, Text } from '@chakra-ui/react';

import { usePostsDashboard } from 'hooks/dashboards';

import ChartContainer from 'components/Charts/ChartContainer';
import FiltersBox from 'components/FiltersBox';
import Filters from './Filters';
import MostViewsChart from './MostViewsChart';

const initialFilters = {
	category_id: '',
	topic_id: '',
	gender: '',
	year: '',
	month: ''
};

const PostsDashboard = () => {
	const [getData, { data, loading }] = usePostsDashboard();
	const [filters, setFilters] = useState(initialFilters);

	const clearFilter = () => {
		setFilters(initialFilters);
		getData();
	};

	useEffect(() => {
		getData();
	}, []);

	const handleFilter = async () => {
		await getData({
			variables: {
				filters
			}
		});
	};

	return (
		<>
			<Grid
				templateColumns={{ sm: '1fr', md: '1fr' }}
				templateRows={{ sm: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' }}
				gap="12px"
				pt={{ sm: '125px', lg: '160px' }}
			>
				<FiltersBox
					data={filters}
					setFilters={setFilters}
					handleClick={handleFilter}
					handleClear={clearFilter}
				>
					<Filters data={filters} setFilters={setFilters} />
				</FiltersBox>
			</Grid>
			{loading ? (
				<Text data-testid="loading">Carregando...</Text>
			) : (
				<>
					<Grid
						templateColumns={{ sm: '1fr', md: '1fr 1fr' }}
						templateRows={{
							sm: 'repeat(1, 1fr)',
							md: 'repeat(1, 1fr)'
						}}
						gap="12px"
					>
						<ChartContainer dataTestId="post-views-chart" title="POSTS MAIS VISUALIZADOS">
							<MostViewsChart
								data={data?.getPostsDashboard.post_views}
							/>
						</ChartContainer>
						<ChartContainer dataTestId="categories-views-chart" title="CATEGORIAS MAIS VISUALIZADAS">
							<MostViewsChart
								data={data?.getPostsDashboard.categories_views}
							/>
						</ChartContainer>
						<ChartContainer dataTestId="topics-views-chart" title="TÓPICOS MAIS VISUALIZADOS">
							<MostViewsChart
								data={data?.getPostsDashboard.topics_views}
							/>
						</ChartContainer>
					</Grid>
					<div />
				</>
			)}
		</>
	);
};

export default PostsDashboard;
