import { createContext, useContext, useReducer, useState } from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import {
	GET_CONTACT,
	CREATE_CONTACT,
	IMPORT_CONTACTS,
	UPDATE_CONTACT,
	DELETE_CONTACT,
	GET_CONTACTS_PAGINATE
} from 'graphql/contacts';
import { ACTION_TYPES, reducers } from './reducers';

const ContactsStateContext = createContext();
const ContactsDispatchContext = createContext();

const initialState = {
	rows: [],
	page: 1,
	perPage: 15,
	contact: {}
};

const ContactsProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducers, initialState);
	const [toDelete, setToDelete] = useState(null);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	return (
		<ContactsStateContext.Provider
			value={{
				...state,
				toDelete,
				setToDelete,
				deleteModalOpen,
				setDeleteModalOpen
			}}
		>
			<ContactsDispatchContext.Provider value={dispatch}>
				{children}
			</ContactsDispatchContext.Provider>
		</ContactsStateContext.Provider>
	);
};

const useContacts = () => {
	const context = useContext(ContactsStateContext);

	if (!context)
		throw new Error('useContacts must be used within an ContactsProvider');

	return context;
};

const useDispatch = () => {
	const dispatch = useContext(ContactsDispatchContext);

	if (dispatch === undefined)
		throw new Error('useDispatch must be used within a ContactsProvider');

	return dispatch;
};

const usePaginateContacts = () => {
	const dispatch = useDispatch();

	return useLazyQuery(GET_CONTACTS_PAGINATE, {
		onCompleted: (data) => {
			window.history.replaceState(
				null,
				'',
				`./contatos?page=${data.paginateContacts.paginatorInfo.currentPage}&size=${data.paginateContacts.paginatorInfo.perPage}`
			);
			dispatch({ type: ACTION_TYPES.LIST, data: data.paginateContacts });
		}
	});
};

const useCreateContact = (options = {}) => {
	const dispatch = useDispatch();
	const { onSuccess, onError } = options;

	return useMutation(CREATE_CONTACT, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
			dispatch({ type: ACTION_TYPES.ADD, data: data.createContact });
		},
		onError: (error) => {
			if (onError) onError(error);
		}
	});
};

const useImportContacts = (options = {}) => {
	const { onSuccess, onError } = options;

	return useMutation(IMPORT_CONTACTS, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
		},
		onError: (error) => {
			if (onError) onError(error);
		}
	});
};

const useContact = (id) => {
	const dispatch = useContext(ContactsDispatchContext);

	return useQuery(GET_CONTACT, {
		fetchPolicy: 'no-cache',
		variables: {
			id
		},
		onCompleted: (data) =>
			dispatch({ type: ACTION_TYPES.VIEW, data: data.getContact })
	});
};

const useUpdateContact = (options = {}) => {
	const dispatch = useContext(ContactsDispatchContext);
	const { onSuccess, onError } = options;

	return useMutation(UPDATE_CONTACT, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
			dispatch({ type: ACTION_TYPES.UPDATE, data: data.updateContact });
		},
		onError: (error) => {
			if (onError) onError(error);
		},
		refetchQueries: [
			{
				query: GET_CONTACTS_PAGINATE
			}
		]
	});
};

const useDeleteContact = (options = {}) => {
	const dispatch = useDispatch();
	const { onSuccess } = options;

	return useMutation(DELETE_CONTACT, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess();
			dispatch({
				type: ACTION_TYPES.REMOVE,
				data: data.deleteContact
			});
		}
	});
};

export {
	ContactsProvider,
	useContacts,
	useCreateContact,
	useImportContacts,
	usePaginateContacts,
	useContact,
	useUpdateContact,
	useDeleteContact
};
