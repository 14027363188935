import { Box, Grid, Flex, Text, useColorModeValue } from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';

import Filters from './Filters';
import AssociatesTable from './AssociatesTable';
import DeleteAssociate from './DeleteAssociate';

const Associates = () => {
	const textColor = useColorModeValue('gray.700', 'white');

	return (
		<Grid
			templateColumns={{ sm: '1fr', md: '1fr' }}
			templateRows={{ sm: 'repeat(8, 1fr)', md: 'repeat(1, 1fr)' }}
			gap="12px"
			pt={{ sm: '125px', lg: '160px' }}
		>
			<Filters />
			<Card px="0px" pb="24px" minHeight="320px">
				<CardHeader mb="34px" px="22px">
					<Box w="100%">
						<Flex
							justify="space-between"
							align="center"
							fontSize={{ sm: '10px', lg: '12px' }}
							color="gray.400"
						>
							<Text
								color={textColor}
								fontSize="lg"
								fontWeight="bold"
							>
								Associados
							</Text>
						</Flex>
					</Box>
				</CardHeader>
				<CardBody h="100%">
					<Box w="100%" h="100%">
						<AssociatesTable />
					</Box>
				</CardBody>
			</Card>
			<DeleteAssociate />
		</Grid>
	);
};

export default Associates;
