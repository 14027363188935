import { useEffect } from 'react';
import { useTopics, usePaginateTopics } from 'hooks/topics';

import AsyncTable from 'components/Tables/AsyncTable';
import { useFilters } from 'hooks/filters';

const columns = [
	{
		Header: 'Título',
		accessor: 'title'
	},
	{
		Header: 'Categoria',
		accessor: 'category.title'
	}
];

const TopicsTable = () => {
	const { filters } = useFilters();
	const { rows, page, perPage, total, setToDelete, setDeleteModalOpen } =
		useTopics();
	const [paginateTopics, { loading }] = usePaginateTopics();

	const deleteCategory = (id) => {
		setToDelete(id);
		setDeleteModalOpen(true);
	};

	const fetchData = ({ pageSize, pageIndex, search }) => {
		if (pageSize != perPage) pageIndex = 1;
		paginateTopics({
			variables: {
				first: pageSize || perPage,
				page: pageIndex || page,
				search: search || '',
				...filters
			}
		});
	};

	useEffect(() => {
		if (filters && Object.keys(filters).length > 0) {
			paginateTopics({
				variables: {
					first: perPage,
					page,
					...filters
				}
			});
		}
	}, [filters]);

	return (
		<AsyncTable
			fetchData={fetchData}
			isLoaded={!loading}
			data={!loading ? rows : [{}, {}, {}, {}]}
			columns={columns}
			total={total}
			size={perPage}
			asyncPage={page}
			searchByName
			baseRoute="topicos"
			removeAction={deleteCategory}
			linkRow
		/>
	);
};

export default TopicsTable;
