const ACTION_TYPES = {
	LIST: 'LIST',
	ADD: 'ADD',
	UPDATE: 'UPDATE',
	UPDATE_LIST_ORDER: 'UPDATE_LIST_ORDER',
	REMOVE: 'REMOVE',
};

const reducers = (state, action) => {
	switch (action.type) {
		case ACTION_TYPES.ALL: {
			return {
				...state,
				carousel: action.data.map(img => {
					img.order = Number(img.order)
					return img;
				})
			};
		}
		case ACTION_TYPES.ADD: {
			return {
				...state,
				carousel: [...state.carousel, action.data]
			}
		}
		case ACTION_TYPES.UPDATE: {
			return {
				...state,
				carousel: state.carousel.map(car => {
					if (car.id == action.data.id) car = action.data
					return car
				})
			}
		}
		case ACTION_TYPES.UPDATE_LIST_ORDER: {
			return {
				...state,
				carousel: state.carousel.map(car => {
					const updated = action.data.find(up => up.id == car.id);
					car.order = updated.order;
					return car;
				})
			}
		}
		case ACTION_TYPES.REMOVE: {
			return {
				...state,
				carousel: state.carousel.filter(car => car.id != action.data.id)
			}
		}
		default: {
			return state;
		}
	}
};

export { reducers, ACTION_TYPES };
