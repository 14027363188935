import { useLazyQuery, useQuery } from '@apollo/client';

const {
	GET_OPERATIONAL_DASHBOARD,
	GET_POSTS_DASHBOARD
} = require('graphql/dashboards');

const useOperationalDashboard = () => useQuery(GET_OPERATIONAL_DASHBOARD);

const usePostsDashboard = () => useLazyQuery(GET_POSTS_DASHBOARD);

export { useOperationalDashboard, usePostsDashboard };
