import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

import { createUploadLink } from 'apollo-upload-client';
import { JWT_TOKEN_COGNITO_ID } from '../../variables/enumLabels';

const ManagerQueryProvider = ({ children }) => {
	const client = new ApolloClient({
		link: createUploadLink({
			uri: `${process.env.REACT_APP_API_URL}/graphql`,
			headers: {
				authorization: `Bearer ${window.localStorage.getItem(
					JWT_TOKEN_COGNITO_ID
				)}`,
				api: `Bearer ${window.localStorage.getItem(
					JWT_TOKEN_COGNITO_ID
				)}`
			}
		}),
		cache: new InMemoryCache()
	});

	return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ManagerQueryProvider;
