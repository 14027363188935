import { Link, useHistory, useParams } from 'react-router-dom';

import {
	Button,
	Center,
	Flex,
	FormControl,
	FormLabel,
	Grid,
	HStack,
	Skeleton,
	Spinner,
	Text,
	useColorModeValue
} from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';

import { useCategory } from 'hooks/categories';
import ImagePreview from 'components/ImagePreview';

const ViewCategory = () => {
	const { id } = useParams();

	const { data: { getCategory: category } = {}, loading } = useCategory(id);

	const history = useHistory();
	const textColor = useColorModeValue('gray.700', 'white');

	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card w={{ md: '100%', lg: '50%' }}>
				<CardHeader mb="40px">
					<Flex direction="row" w="100%" justify="space-between">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Categoria
						</Text>
						<Flex>
							<HStack spacing={4} alignItems="flex-start">
								<Link to={`${id}/editar`}>
									<Button colorScheme="purple" size="md">
										Editar
									</Button>
								</Link>
								<Button
									variant="ghost"
									colorScheme="gray"
									size="md"
									onClick={() => history.goBack()}
								>
									Voltar
								</Button>
							</HStack>
						</Flex>
					</Flex>
				</CardHeader>
				<CardBody>
					<Flex direction="column" w="100%">
						<Grid
							templateColumns={{
								sm: '1fr',
								md: 'repeat(2, 1fr)'
							}}
							gap="24px"
							mt="24px"
							mb="24px"
						>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Título
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text
										color={textColor}
										fontSize="lg"
										fontWeight="bold"
										mb="3px"
									>
										{category?.title}
									</Text>
								</Skeleton>
							</FormControl>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Descrição
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text
										color={textColor}
										fontSize="lg"
										fontWeight="bold"
										mb="3px"
									>
										{category?.description}
									</Text>
								</Skeleton>
							</FormControl>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Tipo
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text
										color={textColor}
										fontSize="lg"
										fontWeight="bold"
										mb="3px"
									>
										{category?.type}
									</Text>
								</Skeleton>
							</FormControl>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Etiqueta do botão
								</FormLabel>
								<Skeleton height="25px" isLoaded={!loading}>
									<Text
										color={textColor}
										fontSize="lg"
										fontWeight="bold"
										mb="3px"
									>
										{category?.button_label}
									</Text>
								</Skeleton>
							</FormControl>
						</Grid>

						{loading ? (
							<Center>
								<Spinner padding="1em" size="xl" mb="24px" />
							</Center>
						) : (
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Imagem de capa
								</FormLabel>
								<ImagePreview link={category?.icon} />
							</FormControl>
						)}
					</Flex>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default ViewCategory;
