import { Select, Text, VStack } from '@chakra-ui/react';
import dateFormat from 'functions/dateFormat';
import { useAllCategories } from 'hooks/categories';
import { useLazyTopics } from 'hooks/topics';

const Filters = ({ data, setFilters }) => {
	const { data: dataCat } = useAllCategories();
	const categories = dataCat?.listCategories || [];

	const [getTopics, { data: dataTopics }] = useLazyTopics();
	const topics = dataTopics?.listTopics || [];

	const handleCategoryChange = (value) => {
		setFilters({ ...data, topic_id: '', category_id: value });
		getTopics({
			variables: {
				where: {
					column: 'CATEGORY_ID',
					operator: 'EQ',
					value
				}
			}
		});
	};

	const getYearList = () => {
		const years = [];

		const currentYear = Number(dateFormat(new Date(), 'yyyy'));

		for (let i = currentYear; i >= 2023; i--) {
			years.push(i);
		}

		return years;
	};

	const getMonthList = () => {
		const months = [];

		const currentYear = Number(dateFormat(new Date(), 'yyyy'));
		const currentMonth = Number(dateFormat(new Date(), 'MM'));

		if (currentYear == data.year) {
			for (let i = currentMonth; i >= 1; i--) {
				months.push(i);
			}
		} else {
			for (let i = 1; i <= 12; i++) {
				months.push(i);
			}
		}

		return months;
	};

	return (
		<>
			<VStack spacing={2} alignItems="flex-start">
				<Text size="sm">Categoria</Text>
				<Select
					value={data.category_id}
					placeholder="Selecione uma opção..."
					onChange={({ target }) =>
						handleCategoryChange(target.value)
					}
				>
					{categories.map((cat) => (
						<option key={cat.id} value={cat.id}>{cat.title}</option>
					))}
				</Select>
			</VStack>
			<VStack spacing={2} alignItems="flex-start">
				<Text size="sm">Tópico</Text>
				<Select
					value={data.topic_id}
					placeholder={
						data.category_id == ''
							? 'Selecione uma categoria'
							: 'Selecione uma opção...'
					}
					disabled={data.category_id == ''}
					onChange={({ target }) =>
						setFilters({ ...data, topic_id: target.value })
					}
				>
					{topics.map((top) => (
						<option key={top.id} value={top.id}>{top.title}</option>
					))}
				</Select>
			</VStack>
			<VStack spacing={2} alignItems="flex-start">
				<Text size="sm">Sexo</Text>
				<Select
					value={data.gender}
					placeholder="Selecione uma opção..."
					onChange={({ target }) =>
						setFilters({ ...data, gender: target.value })
					}
				>
					<option value="Masculino">Masculino</option>
					<option value="Feminino">Feminino</option>
					<option value="NA">Não Informado</option>
				</Select>
			</VStack>
			<VStack spacing={2} alignItems="flex-start">
				<Text size="sm">Ano</Text>
				<Select
					value={data.year}
					placeholder="Selecione uma opção..."
					onChange={({ target }) =>
						setFilters({ ...data, year: target.value, month: '' })
					}
				>
					{getYearList().map((year) => (
						<option key={year} value={year}>{year}</option>
					))}
				</Select>
			</VStack>
			<VStack spacing={2} alignItems="flex-start">
				<Text size="sm">Mês</Text>
				<Select
					value={data.month}
					placeholder={
						data.year == ''
							? 'Selecione um ano'
							: 'Selecione uma opção...'
					}
					disabled={data.year == ''}
					onChange={({ target }) =>
						setFilters({ ...data, month: target.value })
					}
				>
					{getMonthList().map((month) => (
					
						<option key={month} value={month}>{month}</option>
					))}
				</Select>
			</VStack>
		</>
	);
};

export default Filters;
