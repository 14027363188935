import { CarouselProvider as Provider } from 'hooks/carousel';
import ManagerQueryProvider from 'services/manager';

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const CarouselProvider = ({ children }) => (
	<DndProvider backend={HTML5Backend}>
		<ManagerQueryProvider>
			<Provider>{children}</Provider>
		</ManagerQueryProvider>
	</DndProvider>
);

export default CarouselProvider;
