import { FiltersProvider } from 'hooks/filters';
import { AssociatesProvider as Provider } from 'hooks/associates/index';

const AssociatesProvider = ({ children }) => (
	<FiltersProvider>
		<Provider>{children}</Provider>
	</FiltersProvider>
);

export default AssociatesProvider;
