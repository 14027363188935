const monochromeMainColors = [
	'#623D80',
	'#794C9D',
	'#C1AED4',
	'#FD8913',
	'#FFD400'
];

const risks = {
	veryHigh: '#CB4333',
	high: '#D97325',
	medium: '#E0A51B',
	low: '#22B357',
	veryLow: '#3E993C'
};

const risksBooleanColors = ['#22b357', '#CB4333'];

const risksColors = ['#22b357', '#E0A51B', '#CB4333'];

const risksColorsPlus = ['#3E993C', '#22b357', '#E0A51B', '#d97325', '#cb4c33'];

const defaultColors = ['#3E993C', '#15ADA7', '#4255C2'];

const defaultColorsPlus = [
	'#3E993C',
	'#22b357',
	'#15ADA7',
	'#4255C2',
	'#8940bc',
	'#c04ac0',
	'#df74b4'
];

export {
	monochromeMainColors,
	risks,
	risksBooleanColors,
	risksColors,
	risksColorsPlus,
	defaultColors,
	defaultColorsPlus
};
