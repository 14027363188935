import { Text, Select, VStack } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

const Fields = () => {
	const methods = useFormContext();

	const { register } = methods;

	return (
		<>
			<VStack spacing={2} alignItems="flex-start">
				<Text size="sm">Status</Text>
				<Select
					placeholder="Selecione uma opção..."
					{...register('status')}
				>
					<option value="open">Aberto</option>
					<option value="working">Em andamento</option>
					<option value="closed">Finalizado</option>
				</Select>
			</VStack>
			<VStack spacing={2} alignItems="flex-start">
				<Text size="sm">Prioridade</Text>
				<Select
					placeholder="Selecione uma opção..."
					{...register('priority')}
				>
					<option value="low">Baixa</option>
					<option value="normal">Normal</option>
					<option value="high">Alta</option>
					<option value="urgent">Urgente</option>
				</Select>
			</VStack>
		</>
	);
};

export default Fields;
