import { gql } from '@apollo/client';

export const GET_TOPICS = gql`
	query GetTopics($where: QueryListTopicsWhereWhereConditions) {
		listTopics(where: $where) {
			id
			title
			category_id
		}
	}
`;

export const GET_TOPICS_PAGINATE = gql`
	query PaginateTopics(
		$first: Int! = 30
		$page: Int! = 1
		$search: String
		$where: QueryPaginateTopicsWhereWhereConditions
	) {
		paginateTopics(
			first: $first
			page: $page
			search: $search
			where: $where
		) {
			paginatorInfo {
				count
				currentPage
				firstItem
				perPage
				lastItem
				total
			}
			data {
				id
				title
				category {
					title
				}
			}
		}
	}
`;

export const GET_TOPIC = gql`
	query GetTopic($id: ID!) {
		getTopic(id: $id) {
			id
			title
			category {
				title
			}
		}
	}
`;

export const CREATE_TOPIC = gql`
	mutation CreateTopic($input: CreateTopic) {
		createTopic(input: $input) {
			id
			title
			category {
				title
			}
		}
	}
`;

export const UPDATE_TOPIC = gql`
	mutation UpdateTopic($input: UpdateTopic) {
		updateTopic(input: $input) {
			id
			title
			category {
				title
			}
		}
	}
`;

export const DELETE_TOPIC = gql`
	mutation DeleteTopic($where: WhereConditions) {
		deleteTopic(where: $where) {
			id
		}
	}
`;
