import { ResponsiveBar } from '@nivo/bar';
import { useColorModeValue } from '@chakra-ui/react';
import { padding } from 'variables/nivo/bar';
import { monochromeMainColors } from 'variables/colors';
import {
	axisLeft,
	themeDark,
	themeLight,
	tooltip
} from 'variables/nivo/default';

const MostViewsChart = ({ data }) => {
	const buildData = (data) => {
		if (!data) return [];

		const values = data?.map((val) => ({
			label:
				val.title.length > 25
					? `${val.title.substring(0, 25)}`
					: val.title,
			title: val.title,
			value: val.view_count
		}));

		return values.sort((a, b) => (a.value > b.value ? 1 : -1));
	};

	/* const getMaxStringSize = (data) => {
		if (!data) return 20;

		let maxStringSize = 0;
		const sw = window.screen.width;

		data?.map((val) => {
			maxStringSize =
				val.title.length > maxStringSize
					? val.title.length
					: maxStringSize;
		});

		console.log(sw);
		return (maxStringSize * 9500) / sw;
	}; */

	return (
		<ResponsiveBar
			data={buildData(data)}
			keys={['value']}
			indexBy="label"
			layout="horizontal"
			margin={{
				top: 5,
				right: 10,
				bottom: 5,
				left: 150
			}}
			padding={padding}
			colors={monochromeMainColors}
			labelSkipWidth={11}
			theme={useColorModeValue(themeLight, themeDark)}
			axisBottom={null}
			axisLeft={axisLeft}
			labelTextColor="#FFF"
			tooltip={({ data, value }) => tooltip(data.title, value)}
		/>
	);
};

export default MostViewsChart;
