import {
	Box,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	useColorModeValue,
	useDisclosure
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { DotsThreeVertical } from '@phosphor-icons/react';
import ActiveAssociateModal from './ActiveAssociateModal';

const ActionsMenu = ({ id, status }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const iconColor = useColorModeValue('gray.200', 'gray.800');
	const iconHoverColor = useColorModeValue('gray.300', 'gray.900');

	return (
		<Box position="absolute" top={0} right={0}>
			<Menu>
				<MenuButton
					px={1}
					py={1}
					transition="all 0.2s"
					borderRadius="md"
					backgroundColor={iconColor}
					_hover={{ bg: iconHoverColor }}
				>
					<DotsThreeVertical size="30" weight="bold" />
				</MenuButton>
				<MenuList>
					<MenuItem>
						<Link to={`${id}/editar`}>Editar Dados</Link>
					</MenuItem>
					<MenuItem onClick={onOpen}>
						{status ? 'Desativar' : 'Ativar'}
					</MenuItem>
				</MenuList>
			</Menu>
			<ActiveAssociateModal
				id={id}
				isOpen={isOpen}
				onClose={onClose}
				isActive={status}
			/>
		</Box>
	);
};

export default ActionsMenu;
