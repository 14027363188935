import { format as fnsFormat } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

// const dateFormat = (value, format = "dd/MM/yyyy") => {
//     try {
//         if (!value) return value;
//         return fnsFormat(new Date(value), format, {locale: ptBR});
//     } catch (e) {
//         return value;
//     }
// }

const dateFormat = (value, format = 'dd/MM/yyyy') => {
	if (!value) return value;
	try {
		if (typeof value === 'string') {
			return String(fnsFormat(new Date(value), format, { locale: ptBR }));
		}
		return String(fnsFormat(value, format, { locale: ptBR }));
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error('Ocorreu um erro ao formatar a data', e);
		return null;
	}
};

export default dateFormat;
