/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra Imports
import { List as ListIcon, DotsThreeVertical } from '@phosphor-icons/react';
import {
	Box,
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	Flex,
	Icon,
	Link,
	useColorModeValue
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { CgMenuRight } from 'react-icons/cg';
import { SidebarContext } from 'contexts/SidebarContext';
import FIcon from 'components/FontAwesomeIcons/Icon';
import AdminNavbarLinks from './AdminNavbarLinks';

export default function AdminNavbar(props) {
	const [scrolled, setScrolled] = useState(false);
	const { sidebarWidth, setSidebarWidth, toggleSidebar, setToggleSidebar } =
		useContext(SidebarContext);
	const { variant, children, fixed, secondary, brandText, onOpen, ...rest } =
		props;

	// Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
	let mainText = useColorModeValue('gray.700', 'gray.200');
	let secondaryText = useColorModeValue('gray.400', 'gray.200');
	let navbarPosition = 'absolute';
	let navbarFilter = 'none';
	let navbarBackdrop = 'blur(21px)';
	let navbarShadow = 'none';
	let navbarBg = 'none';
	let navbarBorder = 'transparent';
	let secondaryMargin = '0px';
	let paddingS = '15px';
	let paddingX = '15px';
	if (props.fixed === true)
		if (scrolled === true) {
			navbarPosition = 'fixed';
			navbarShadow = useColorModeValue(
				'0px 7px 23px rgba(0, 0, 0, 0.05)',
				'none'
			);
			navbarBg = useColorModeValue(
				'linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)',
				'linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)'
			);
			navbarBorder = useColorModeValue(
				'#FFFFFF',
				'rgba(255, 255, 255, 0.31)'
			);
			navbarFilter = useColorModeValue(
				'none',
				'drop-shadow(0px 7px 23px rgba(0, 0, 0, 0.05))'
			);
		}
	if (props.secondary) {
		navbarBackdrop = 'none';
		navbarPosition = 'absolute';
		mainText = 'white';
		secondaryText = 'white';
		secondaryMargin = '22px';
		paddingS = '40px';
		paddingX = '30px';
	}
	const changeNavbar = () => {
		if (window.scrollY > 1) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};
	window.addEventListener('scroll', changeNavbar);
	return (
		<Flex
			position={navbarPosition}
			mr="5px"
			boxShadow={navbarShadow}
			bg={navbarBg}
			borderColor={navbarBorder}
			filter={navbarFilter}
			borderWidth="1.5px"
			borderStyle="solid"
			transitionDelay="0s, 0s, 0s, 0s"
			transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
			transition-property="box-shadow, background-color, filter, border"
			transitionTimingFunction="linear, linear, linear, linear"
			alignItems={{ xl: 'center' }}
			borderRadius="16px"
			display="flex"
			minH="75px"
			left={document.documentElement.dir === 'rtl' ? '30px' : ''}
			right={document.documentElement.dir === 'rtl' ? '' : '30px'}
			justifyContent={{ xl: 'center' }}
			lineHeight="25.6px"
			mx="auto"
			mt={secondaryMargin}
			pb="8px"
			px={{
				sm: paddingX,
				md: '30px'
			}}
			ps={{
				sm: paddingS,
				md: '20px'
			}}
			pt="8px"
			top="18px"
			w={{
				sm: 'calc(100vw - 30px)',
				xl: `calc(100vw - 75px - ${sidebarWidth}px)`
			}}
		>
			<Flex
				w="100%"
				flexDirection={{
					sm: 'column',
					md: 'row'
				}}
				alignItems={{ xl: 'center' }}
			>
				<Box mb={{ sm: '8px', md: '0px' }}>
					{/* Here we create navbar brand, based on route name */}
					<Link
						color="white"
						href="#"
						bg="inherit"
						borderRadius="inherit"
						fontWeight="bold"
						_active={{
							bg: 'inherit',
							transform: 'none',
							borderColor: 'transparent'
						}}
						_focus={{
							boxShadow: 'none'
						}}
					>
						{brandText}
					</Link>
				</Box>
				{toggleSidebar ? (
					<Box marginLeft="4" cursor="pointer">
						<ListIcon
							size={20}
							color="#FFF"
							weight="bold"
							onClick={() => {
								setSidebarWidth(
									sidebarWidth === 275 ? 120 : 275
								);
								setToggleSidebar(!toggleSidebar);
							}}
						/>
					</Box>
				) : (
					<Box marginLeft="4" cursor="pointer">
						<ListIcon
							size={18}
							color="#FFF"
							weight="bold"
							onClick={() => {
								setSidebarWidth(
									sidebarWidth === 275 ? 120 : 275
								);
								setToggleSidebar(!toggleSidebar);
							}}
						/>
					</Box>
				)}
				<Box ms="auto" w={{ sm: '100%', md: 'unset' }}>
					<AdminNavbarLinks
						onOpen={props.onOpen}
						logoText={props.logoText}
						secondary={props.secondary}
						fixed={props.fixed}
					/>
				</Box>
			</Flex>
		</Flex>
	);
}

AdminNavbar.propTypes = {
	brandText: PropTypes.string,
	variant: PropTypes.string,
	secondary: PropTypes.bool,
	fixed: PropTypes.bool,
	onOpen: PropTypes.func
};
