import { createContext, useContext, useReducer } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import {
	CREATE_REPORT,
	GET_REPORT,
	GET_REPORTS_PAGINATE
} from 'graphql/reports';
import { ACTION_TYPES, reducers } from './reducers';

const ReportsStateContext = createContext();
const ReportsDispatchContext = createContext();

const ReportsProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducers, {
		rows: [],
		perPage: 15,
		page: 1,
		total: 0,
		currentPage: 1
	});

	return (
		<ReportsStateContext.Provider value={state}>
			<ReportsDispatchContext.Provider value={dispatch}>
				{children}
			</ReportsDispatchContext.Provider>
		</ReportsStateContext.Provider>
	);
};

const useReports = () => {
	const context = useContext(ReportsStateContext);

	if (!context)
		throw new Error('useReports must be used within an ReportsProvider');

	return context;
};

const useDispatch = () => {
	const dispatch = useContext(ReportsDispatchContext);

	if (dispatch === undefined)
		throw new Error('useDispatch must be used within a ReportsProvider');

	return dispatch;
};

const usePaginateReports = () => {
	const dispatch = useDispatch();

	return useLazyQuery(GET_REPORTS_PAGINATE, {
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			dispatch({
				type: ACTION_TYPES.LIST,
				data: data?.paginateDocuments
			});
		}
	});
};

const useCreateReport = (options = {}) => {
	const dispatch = useDispatch();
	const { onSuccess, onError } = options;

	return useMutation(CREATE_REPORT, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
			dispatch({ type: ACTION_TYPES.ADD, data: data?.createDocument });
		},
		onError: (error) => {
			if (onError) onError(error);
		}
	});
};

const useReport = (id) => {
	const dispatch = useContext(ReportsDispatchContext);

	return useQuery(
		GET_REPORT,
		{
			fetchPolicy: 'no-cache',
			variables: {
				id
			}
		},
		{
			onCompleted: (data) => {
				dispatch({ type: ACTION_TYPES.VIEW, data: data.report });
			}
		}
	);
};

export {
	ReportsProvider,
	useReports,
	useDispatch,
	useCreateReport,
	usePaginateReports,
	useReport
};
