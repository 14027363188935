import { useFormContext } from 'react-hook-form';
import { Text, Select, VStack } from '@chakra-ui/react';
import { useAllCategories } from 'hooks/categories';

import { useAllTopics } from 'hooks/topics';

const Fields = () => {
	const methods = useFormContext();

	const { data: { listCategories: categories } = [] } = useAllCategories();
	const { data: { listTopics: topics } = [] } = useAllTopics();

	const { register } = methods;

	return (
		<>
			<VStack spacing={2} alignItems="flex-start">
				<Text size="sm">Status</Text>
				<Select
					placeholder="Selecione uma opção..."
					{...register('status')}
				>
					<option value="draft">Rascunho</option>
					<option value="published">Publicado</option>
					<option value="archived">Arquivado</option>
				</Select>
			</VStack>
			<VStack spacing={2} alignItems="flex-start">
				<Text size="sm">Categoria</Text>
				<Select
					placeholder="Selecione uma opção..."
					{...register('has_topic_category')}
				>
					{categories?.map((category) => (
						<option value={category?.id}>{category?.title}</option>
					))}
				</Select>
			</VStack>
			<VStack spacing={2} alignItems="flex-start">
				<Text size="sm">Tópico</Text>
				<Select
					placeholder="Selecione uma opção..."
					{...register('topic_id')}
				>
					{topics?.map((topic) => (
						<option value={topic?.id}>{topic?.title}</option>
					))}
				</Select>
			</VStack>
		</>
	);
};

export default Fields;
