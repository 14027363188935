/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra Imports
import { Gear } from '@phosphor-icons/react';
import { Button, useColorModeValue } from '@chakra-ui/react';
// Custom Icons
import PropTypes from 'prop-types';
import React from 'react';
import Icon from 'components/FontAwesomeIcons/Icon';

export default function FixedPlugin(props) {
	const { secondary, onChange, onSwitch, fixed, ...rest } = props;
	// Chakra Color Mode
	const navbarIcon = useColorModeValue('#718096', '#E2E8F0');
	const bgButton = useColorModeValue('white', 'gray.600');

	const settingsRef = React.useRef();
	return (
		<Button
			h="52px"
			w="52px"
			onClick={props.onOpen}
			bg={bgButton}
			position="fixed"
			variant="no-hover"
			left={document.documentElement.dir === 'rtl' ? '35px' : ''}
			right={document.documentElement.dir === 'rtl' ? '' : '35px'}
			bottom="30px"
			borderRadius="50px"
			boxShadow="0 2px 12px 0 rgb(0 0 0 / 16%)"
			cursor="pointer"
		>
			<Gear size={32} color={navbarIcon} />
		</Button>
	);
}

FixedPlugin.propTypes = {
	fixed: PropTypes.bool,
	onChange: PropTypes.func,
	onSwitch: PropTypes.func
};
