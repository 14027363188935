import { ResponsivePie } from '@nivo/pie';
import { useColorModeValue } from '@chakra-ui/react';
import { risksColors } from 'variables/colors';

import { border, tooltip } from 'variables/nivo/default';
import {
	arc,
	arcLabelsTextColor,
	arcLinkLabelsColor,
	legendsDark,
	legendsLight,
	margin,
	pieProps
} from 'variables/nivo/halfPie';

const buildData = (data) => {
	const values = [];

	if (!data) return values;

	values.push({
		id: 'fumantes',
		label: 'Fumantes',
		value: data.sim
	});
	values.push({
		id: 'parei',
		label: 'Parei de fumar',
		value: data.parei
	});
	values.push({
		id: 'nunca',
		label: 'Nunca fumei',
		value: data.nao
	});
	return values;
};

const total = (data) => data.sim + data.parei + data.nao;

const SmokeChart = ({ data }) => (
	<ResponsivePie
		data={buildData(data)}
		margin={margin}
		colors={[risksColors[2], risksColors[1], risksColors[0]]}
		{...border}
		{...arc}
		arcLinkLabelsColor={arcLinkLabelsColor}
		arcLabelsTextColor={arcLabelsTextColor}
		legends={useColorModeValue(legendsLight, legendsDark)}
		tooltip={({ datum }) =>
			tooltip(datum.label, datum.value, total(data), datum.color)
		}
		{...pieProps}
		enableArcLabels={false}
		innerRadius={0.7}
	/>
);

export default SmokeChart;
