import { gql } from '@apollo/client';

export const GET_NEWSLETTERS = gql`
	query GetNewsletter {
		listNewsletter {
			id
			title
			url
			scheduled_to
			sent_at
			total_sent
			created_at
			updated_at
			deleted_at
		}
	}
`;

export const PAGINATE_NEWSLETTER = gql`
	query PaginaNewsletter(
		$first: Int! = 30
		$page: Int! = 1
		$search: String
	) {
		paginateNewsletters(first: $first, page: $page, search: $search) {
			paginatorInfo {
				count
				currentPage
				firstItem
				perPage
				lastItem
				total
			}
			data {
				id
				title
				url
				scheduled_to
				sent_at
				total_sent
				created_at
				updated_at
				deleted_at
			}
		}
	}
`;

export const GET_NEWSLETTER = gql`
	query GetNewsletter($id: ID!) {
		getNewsletter(id: $id) {
			id
			title
			url
			scheduled_to
			sent_at
			total_sent
			created_at
			updated_at
			deleted_at
		}
	}
`;

export const CREATE_NEWSLETTER = gql`
	mutation CreateNewsletter($input: CreateNewsletter) {
		createNewsletter(input: $input) {
			id
			title
			url
			scheduled_to
			sent_at
			total_sent
			created_at
		}
	}
`;

export const UPDATE_NEWSLETTER = gql`
	mutation UpdateNewsletter($input: UpdateNewsletter) {
		updateNewsletter(input: $input) {
			id
			title
			url
			scheduled_to
			sent_at
			total_sent
			created_at
		}
	}
`;
