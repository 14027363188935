import { gql } from '@apollo/client';

export const GET_OPERATIONAL_DASHBOARD = gql`
	query GetOperationalDashboard {
		getOperationalDashboard {
			assessments {
				respondidos
				nao_respondidos
			}
			gender {
				masculino
				feminino
			}
			age {
				eighteen
				twentythree
				twentyeight
				thirtythree
				thirtyeight
				fourtythree
				fourtyeight
				fiftythree
				fiftyeight
				sixtynine
				eighty
				eightyplus
			}
			imc {
				abaixo
				normal
				sobrepeso
				obesidade_1
				obesidade_2
				obesidade_3
			}
			animal {
				nunca
				raramente
				moderadamente
				frequentemente
				quase_sempre
			}
			vegatable {
				cinco
				tres
				duas
				uma
				nenhuma
			}
			medic {
				sim
				nao
			}
			smoke {
				nao
				parei
				sim
			}
			drink {
				nunca
				raramente
				menos_tres
				tres_seis
				sete_mais
			}
			health {
				excelente
				boa
				regular
				ruim
				pessima
			}
			activity {
				academia
				caminhada
				corrida
				esportes
				funcional
				danca
				nenhum
			}
			leisure {
				concerto
				passeio
				praia
				teatro
				viagem
				outros
				nenhum
			}
			life_quality {
				tristeza
				interesse
				ansiedade
				controle
			}
			pathologies {
				hipertensao
				diabetes
			}
		}
	}
`;

export const GET_POSTS_DASHBOARD = gql`
	query GetPostsDashboard($filters: PostDashboardFilter) {
		getPostsDashboard(filters: $filters) {
			post_views {
				title
				view_count
			}
			categories_views {
				title
				view_count
			}
			topics_views {
				title
				view_count
			}
		}
	}
`;
