import { ACTION_TYPES } from './reducers';

const listLastReports = (reports, dispatch) => {
	dispatch({
		type: ACTION_TYPES.LIST,
		data: reports
	});
};

const addReport = (report, dispatch) => {
	dispatch({
		type: ACTION_TYPES.ADD,
		data: report
	});
};

const updateReport = (report, dispatch) => {
	dispatch({
		type: ACTION_TYPES.UPDATE,
		data: report
	});
};

export { listLastReports, addReport, updateReport };
