import { useEffect, useState } from 'react';
import { useCreateReport } from 'hooks/reports';
import InputMask from 'react-input-mask';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isValid } from 'date-fns';

import {
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	Input,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	useColorModeValue,
	useToast
} from '@chakra-ui/react';

import { useForm } from 'react-hook-form';

import { REPORT_TAGS } from 'variables/reports';
import { useAuth } from 'hooks/useAuth';

const schema = yup?.object()?.shape({
	label: yup?.string()?.required('O campo Nome é obrigatório'),
	report_tag: yup
		?.string()
		?.nullable()
		?.required('O campo Tipo de Relatório é obrigatório'),
	start_date: yup
		?.string()
		?.nullable()
		?.required('O campo Data de Início é obrigatório')
		?.test('date-valid', 'Informe uma data válida', (field) => {
			const date = new Date(`${field} 00:00:00`);
			return isValid(date);
		}),
	end_date: yup
		?.string()
		?.nullable()
		?.required('O campo Data de Término é obrigatório')
		?.test('date-valid', 'Informe uma data válida', (field) => {
			const date = new Date(`${field} 00:00:00`);
			return isValid(date);
		})
});

const CreateReport = ({ tagReport, filters, children, ...props }) => {
	const toast = useToast();

	const { user } = useAuth();

	const [noParseStart, setNoParseStart] = useState(null);
	const [noParseEnd, setNoParseEnd] = useState(null);
	const [modalOpen, isModalOpen] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		setValue
	} = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		resolver: yupResolver(schema)
	});

	const [createReport, { loading }] = useCreateReport({
		onSuccess: (data) => {
			toast({
				title: 'Relatório solicitado com sucesso.',
				status: 'success',
				duration: 5000,
				isClosable: true
			});
			try {
				const event = new CustomEvent('report-created', {
					detail: data?.createDocument
				});
				document.dispatchEvent(event);
			} catch (err) {
				toast({
					title: 'Ocorreu um erro ao criar o relatório.',
					status: 'error',
					duration: 5000,
					isClosable: true
				});
			}
			isModalOpen(false);
		},
		onError: () => {
			toast({
				title: 'Ocorreu um erro.',
				status: 'error',
				duration: 5000,
				isClosable: true
			});
		}
	});

	const handleChange = (value) => {
		const result = value?.replace(/[^a-z0-9]/gi, '');
		setValue('label', result);
	};

	useEffect(() => {
		if (noParseStart && noParseStart?.replace('_', '').length === 10) {
			const date = noParseStart?.split('/');
			setValue('start_date', `${date[2]}-${date[1]}-${date[0]}`);
		} else setValue('start_date', null);
	}, [noParseStart]);

	useEffect(() => {
		if (noParseEnd && noParseEnd?.replace('_', '').length === 10) {
			const date = noParseEnd?.split('/');
			setValue('end_date', `${date[2]}-${date[1]}-${date[0]}`);
		} else setValue('end_date', null);
	}, [noParseEnd]);

	const onSubmit = (data) => {
		const variables = {
			start_date: getValues('start_date'),
			end_date: getValues('end_date')
		};
		const filters = JSON.stringify(variables);

		createReport({
			variables: {
				input: {
					label: data?.label,
					report_tag: data?.report_tag,
					status: 'report_gen',
					specs: JSON.stringify(filters) || '{}',
					user_id: user.id
				}
			}
		});
	};

	const buttonBg = useColorModeValue('appai.darker', 'appai.darker');
	const inputHover = useColorModeValue('appai.main', 'appai.main');
	const textColor = useColorModeValue('gray.700', 'white');

	return (
		<>
			<form>
				<Modal
					isOpen={modalOpen}
					onClose={() => isModalOpen(false)}
					autoFocus={false}
				>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>Nome do relatório</ModalHeader>
						<ModalBody>
							<Grid
								templateColumns={{
									sm: '1fr',
									md: 'repeat(1, 1fr)'
								}}
								gap="24px"
								mb="24px"
							>
								<FormControl
									isInvalid={errors?.label}
									mb="0.5rem"
								>
									<FormLabel
										fontSize="xs"
										fontWeight="bold"
										mb="10px"
									>
										Nome
									</FormLabel>
									<Input
										{...register('label')}
										borderRadius="8px"
										focusBorderColor={inputHover}
										fontSize="md"
										color="gray.400"
										onChange={({ target }) =>
											handleChange(target?.value)
										}
									/>
									{errors?.label && (
										<FormErrorMessage>
											{errors?.label?.message}
										</FormErrorMessage>
									)}
								</FormControl>
								<FormControl isInvalid={errors?.report_tag}>
									<FormLabel fontSize="xs" fontWeight="bold">
										Tipo de relatório
									</FormLabel>
									<Select
										{...register('report_tag')}
										focusBorderColor={inputHover}
										placeholder="Selecione uma opção..."
										color="gray.400"
										size="md"
										borderRadius="8px"
										cursor="pointer"
									>
										{REPORT_TAGS.map((r) => (
											<option value={r?.tag}>
												{r?.label}
											</option>
										))}
									</Select>
									{errors?.report_tag && (
										<FormErrorMessage>
											{errors?.report_tag?.message}
										</FormErrorMessage>
									)}
								</FormControl>
							</Grid>
							<Grid
								templateColumns={{
									sm: '1fr',
									md: 'repeat(2, 1fr)'
								}}
								gap="24px"
							>
								<FormControl isInvalid={errors?.start_date}>
									<FormLabel
										color={textColor}
										fontWeight="bold"
										fontSize="xs"
									>
										Data de Início
									</FormLabel>
									<Input
										as={InputMask}
										mask="99/99/9999"
										focusBorderColor={inputHover}
										borderRadius="8px"
										fontSize="md"
										onChange={({ target }) =>
											setNoParseStart(target?.value)
										}
									/>
									{errors?.start_date && (
										<FormErrorMessage>
											{errors?.start_date?.message}
										</FormErrorMessage>
									)}
								</FormControl>
								<FormControl isInvalid={errors?.end_date}>
									<FormLabel
										color={textColor}
										fontWeight="bold"
										fontSize="xs"
									>
										Data de Término
									</FormLabel>
									<Input
										as={InputMask}
										mask="99/99/9999"
										focusBorderColor={inputHover}
										borderRadius="8px"
										fontSize="md"
										onChange={({ target }) =>
											setNoParseEnd(target?.value)
										}
									/>
									{errors?.end_date && (
										<FormErrorMessage>
											{errors?.end_date?.message}
										</FormErrorMessage>
									)}
								</FormControl>
							</Grid>
						</ModalBody>
						<ModalFooter>
							<Button
								isLoading={loading}
								type="submit"
								variant="no-hover"
								bg={buttonBg}
								alignSelf="flex-end"
								w="100px"
								h="35px"
								onClick={() => handleSubmit(onSubmit)()}
							>
								Gerar
							</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
			</form>
			<Button
				{...props}
				isLoading={loading}
				onClick={() => isModalOpen(true)}
			>
				{children}
			</Button>
		</>
	);
};

export default CreateReport;
