import { useFormContext } from 'react-hook-form';

import {
	FormControl,
	FormLabel,
	Input,
	Textarea,
	useColorModeValue
} from '@chakra-ui/react';

import UploadField from 'components/UploadField';

export default ({ fields = [], body = null }) => {
	const textColor = useColorModeValue('gray.700', 'white');

	const { register } = useFormContext();

	const getFieldComponent = (field) => {
		const name = Object.keys(field).pop();
		const type = field[name]?.type;
		const label = field[name]?.label;

		const decoded = body !== null ? JSON.parse(JSON.parse(body))?.body : '';

		switch (type) {
			case 'text':
				return (
					<FormControl>
						<FormLabel
							color={textColor}
							fontWeight="bold"
							fontSize="xs"
						>
							{field[Object.keys(field).pop()]?.label}
						</FormLabel>
						<Input {...register(`template_fields.${name}`)} />{' '}
					</FormControl>
				);
			case 'markdown':
				return (
					<FormControl>
						<FormLabel
							color={textColor}
							fontWeight="bold"
							fontSize="xs"
						>
							{field[Object.keys(field).pop()]?.label}
						</FormLabel>
						<Textarea
							resize="vertical"
							defaultValue={decoded}
							{...register(`template_fields.${name}`)}
						/>{' '}
					</FormControl>
				);
			case 'upload':
				return (
					<UploadField
						label={label}
						filePrefix={`${name}-template`}
					/>
				);
			default:
				break;
		}
	};

	return fields.map((field) => getFieldComponent(field));
};
