import { FiltersProvider } from 'hooks/filters';
import { ContactsProvider as Provider } from 'hooks/contacts/index';

const ContactsProvider = ({ children }) => (
	<FiltersProvider>
		<Provider>{children}</Provider>
	</FiltersProvider>
);

export default ContactsProvider;
