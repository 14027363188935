import Pusher from 'pusher-js';

const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY ?? '', {
	cluster: process.env.REACT_APP_PUSHER_CLUSTER ?? '',
	authEndpoint: process.env.REACT_APP_SOCKET_URL ?? ''
	/* auth: {
		headers: {
			"Authorization": `Bearer ${window.localStorage.getItem(JWT_TOKEN_COGNITO_ID)}`,
			"Access-Control-Allow-Origin": "*"
		}
	} */
});

export default pusher;
