import { ResponsiveBar } from '@nivo/bar';
import { useColorModeValue } from '@chakra-ui/react';
import { padding } from 'variables/nivo/bar';
import { monochromeMainColors } from 'variables/colors';
import {
	axisLeft,
	themeDark,
	themeLight,
	tooltip
} from 'variables/nivo/default';

const buildData = (data) => {
	const values = [];

	if (!data) return values;

	values.push({
		id: 'hipertensao',
		label: 'Hipertensão',
		value: data.hipertensao
	});
	values.push({
		id: 'diabetes',
		label: 'Diabetes',
		value: data.diabetes
	});

	return values;
};

const DiseasesChart = ({ data }) => (
	<ResponsiveBar
		data={buildData(data)}
		keys={['value']}
		indexBy="label"
		layout="horizontal"
		margin={{ top: 5, right: 10, bottom: 5, left: 120 }}
		padding={padding}
		colors={monochromeMainColors}
		labelSkipWidth={11}
		theme={useColorModeValue(themeLight, themeDark)}
		axisBottom={null}
		axisLeft={axisLeft}
		labelTextColor="#FFF"
		tooltip={({ indexValue, value }) => tooltip(indexValue, value)}
	/>
);

export default DiseasesChart;
