import { useHistory, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import InputMask from 'react-input-mask';

import {
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	HStack,
	Input,
	Skeleton,
	Text,
	useColorModeValue,
	useToast
} from '@chakra-ui/react';
import { useUpdateContact, useContact } from 'hooks/contacts';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { phoneRegex } from 'variables/regex';

const schema = yup.object().shape({
	name: yup.string().required('O campo Nome é obrigatório'),
	phone: yup
		.string()
		.required('O campo Telefone é obrigatório')
		.matches(phoneRegex, 'Insira um telefone válido'),
	appai_id: yup.string().required('O campo Matrícula é obrigatório')
});

const EditContact = () => {
	const { id } = useParams();
	const { data, loading } = useContact(id);
	const contact = data?.getContact ?? {};

	const history = useHistory();

	const methods = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		resolver: yupResolver(schema),
		defaultValues: contact
	});
	const toast = useToast();

	const {
		register,
		reset,
		handleSubmit,
		formState: { errors }
	} = methods;

	const [updateContact, { loading: loadingUpdate }] = useUpdateContact({
		onSuccess: (data) => {
			reset();
			toast({
				title: 'Contato atualizado com sucesso.',
				status: 'success',
				duration: 5000,
				isClosable: true
			});
			history.push(
				`/sistema/administracao/contatos/${data.updateContact.id}`
			);
		},
		onError: () => {
			toast({
				title: 'Ocorreu um erro',
				status: 'error',
				duration: 5000,
				isClosable: true
			});
		}
	});

	const onSubmit = (data) => {
		data.phone = data.phone
			.replaceAll('(', '')
			.replaceAll(')', '')
			.replaceAll('-', '')
			.replaceAll(' ', '');

		updateContact({
			variables: {
				input: {
					id,
					...data
				}
			}
		});
	};

	const inputHover = useColorModeValue('appai.main', 'appai.main');
	const textColor = useColorModeValue('gray.700', 'white');

	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card w={{ md: '100%', lg: '50%' }}>
				<CardHeader mb="40px">
					<Flex direction="column">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Editar Contato
						</Text>
					</Flex>
				</CardHeader>
				<CardBody>
					<FormProvider {...methods}>
						<form
							style={{ width: '100%' }}
							onSubmit={handleSubmit(onSubmit)}
						>
							<Flex direction="column" w="100%">
								<Grid
									templateColumns={{
										sm: '1fr',
										md: 'repeat(2, 1fr)'
									}}
									gap="24px"
								>
									<FormControl isInvalid={errors.name}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Nome
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('name')}
												defaultValue={
													contact.name || null
												}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors?.name && (
											<FormErrorMessage>
												{errors?.name?.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.appai_id}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Matrícula
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('appai_id')}
												defaultValue={
													contact.appai_id || null
												}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors?.appai_id && (
											<FormErrorMessage>
												{errors?.appai_id?.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.phone}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Telefone
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('phone')}
												as={InputMask}
												mask="(99) 99999-9999"
												defaultValue={
													contact.phone || null
												}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors?.phone && (
											<FormErrorMessage>
												{errors?.phone?.message}
											</FormErrorMessage>
										)}
									</FormControl>
								</Grid>
								<Flex
									w="full"
									justifyContent="flex-end"
									mt="24px"
								>
									<HStack spacing={4}>
										<Button
											disabled={loadingUpdate}
											variant="ghost"
											colorScheme="gray"
											alignSelf="flex-end"
											size="md"
											onClick={() => history.goBack()}
										>
											Cancelar
										</Button>
										<Button
											isLoading={loadingUpdate}
											disabled={loadingUpdate}
											colorScheme="purple"
											type="submit"
											alignSelf="flex-end"
											size="md"
										>
											Atualizar
										</Button>
									</HStack>
								</Flex>
							</Flex>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default EditContact;
