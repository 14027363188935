import { Grid, Text } from '@chakra-ui/react';

import { useOperationalDashboard } from 'hooks/dashboards';

import ChartContainer from 'components/Charts/ChartContainer';
import ActivitiesChart from './ActivitiesChart';
import AnimalConsumptionChart from './AnimalConsumptionChart';
import AssessmentsChart from './AssessmentsChart';
import BeneficiariesByAgeChart from './BeneficiariesByAgeChart';
import BeneficiariesByGenderChart from './BeneficiariesByGenderChart';
import BeneficiariesByIMCChart from './BeneficiariesByIMCChart';
import DiseasesChart from './DiseasesChart';
import DrinkConsumptionChart from './DrinkConsumptionChart';
import HealthPerceptionChart from './HealthPerceptionChart';
import LeisureChart from './LeisureChart';
import LifeQualityChart from './LifeQualityChart';
import MedicChart from './MedicChart';
import SmokeChart from './SmokeChart';
import VegatableConsumptionChart from './VegatableConsumptionChart';

const OperationalDashboard = () => {
	const { data, loading } = useOperationalDashboard();

	if (loading) return <Text data-testid="loading">Carregando...</Text>;

	return (
		<>
			<Grid
				templateColumns={{ sm: '1fr', md: '1fr 1fr' }}
				templateRows={{ sm: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' }}
				gap="12px"
				pt={{ sm: '125px', lg: '160px' }}
			>
				<ChartContainer dataTestId="assessments-chart" title="FORMULÁRIO PREENCHIDO x NÃO PREENCHIDO">
					<AssessmentsChart
						data={data?.getOperationalDashboard.assessments}
					/>
				</ChartContainer>
				<ChartContainer dataTestId="beneficiaries-by-gender-chart" title="POPULAÇÃO POR GÊNERO">
					<BeneficiariesByGenderChart
						data={data?.getOperationalDashboard.gender}
					/>
				</ChartContainer>
			</Grid>
			<Grid
				templateColumns={{ sm: '1fr', md: '1fr 1fr' }}
				templateRows={{ sm: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' }}
				gap="12px"
				pt={{ sm: '12px', lg: '12px' }}
			>
				<ChartContainer dataTestId="beneficiaries-by-age-chart" title="POPULAÇÃO POR IDADE">
					<BeneficiariesByAgeChart
						data={data?.getOperationalDashboard.age}
					/>
				</ChartContainer>
				<ChartContainer dataTestId="beneficiaries-by-imc-chart" title="POPULAÇÃO POR IMC">
					<BeneficiariesByIMCChart
						data={data?.getOperationalDashboard.imc}
					/>
				</ChartContainer>
			</Grid>
			<Grid
				templateColumns={{ sm: '1fr', md: '1fr 1fr 1fr' }}
				templateRows={{ sm: '1fr', md: '1fr' }}
				gap="12px"
				pt={{ sm: '12px', lg: '12px' }}
			>
				<ChartContainer dataTestId="animal-consumption-chart" title="QUALIDADE ALIMENTAR GORDURA ANIMAL">
					<AnimalConsumptionChart
						data={data?.getOperationalDashboard.animal}
					/>
				</ChartContainer>
				<ChartContainer dataTestId="vegetal-consumption-chart" title="QUALIDADE ALIMENTAR VEGETAL">
					<VegatableConsumptionChart
						data={data?.getOperationalDashboard.vegatable}
					/>
				</ChartContainer>
				<ChartContainer dataTestId="medic-chart" title="ACOMPANHAMENTO MÉDICO">
					<MedicChart data={data?.getOperationalDashboard.medic} />
				</ChartContainer>
				<ChartContainer dataTestId="smoke-chart" title="FUMANTES">
					<SmokeChart data={data?.getOperationalDashboard.smoke} />
				</ChartContainer>
				<ChartContainer dataTestId="drink-consumption-chart" title="CONSUMO DE BEBIDA ALCOÓLICA">
					<DrinkConsumptionChart
						data={data?.getOperationalDashboard.drink}
					/>
				</ChartContainer>
				<ChartContainer dataTestId="health-perception-chart" title="AUTO PERCEPÇÃO DA SAÚDE">
					<HealthPerceptionChart
						data={data?.getOperationalDashboard.health}
					/>
				</ChartContainer>
			</Grid>
			<Grid
				templateColumns={{ sm: '1fr', md: '1fr 1fr' }}
				templateRows={{ sm: '1fr', md: '1fr' }}
				gap="12px"
				pt={{ sm: '12px', lg: '12px' }}
			>
				<ChartContainer dataTestId="activities-chart" title="ATIVIDADES FÍSICAS">
					<ActivitiesChart
						data={data?.getOperationalDashboard.activity}
					/>
				</ChartContainer>
				<ChartContainer dataTestId="leisure-chart" title="ATIVIDADES DE LAZER">
					<LeisureChart
						data={data?.getOperationalDashboard.leisure}
					/>
				</ChartContainer>
				<ChartContainer dataTestId="life-quality-chart" title="QUALIDADE DE VIDA">
					<LifeQualityChart
						data={data?.getOperationalDashboard.life_quality}
					/>
				</ChartContainer>
				<ChartContainer dataTestId="diseases-chart" title="PATOLOGIAS">
					<DiseasesChart
						data={data?.getOperationalDashboard.pathologies}
					/>
				</ChartContainer>
			</Grid>
		</>
	);
};

export default OperationalDashboard;
