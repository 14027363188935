import { gql } from '@apollo/client';

export const GET_CATEGORIES = gql`
	query GetCategories {
		listCategories {
			id
			title
			description
			type
			icon
			button_label
			topics {
				id
				title
			}
			parent {
				id
				title
			}
		}
	}
`;

export const GET_CATEGORIES_PAGINATE = gql`
	query PaginateCategories(
		$first: Int! = 30
		$page: Int! = 1
		$search: String
		$where: QueryPaginateCategoriesWhereWhereConditions
	) {
		paginateCategories(
			first: $first
			page: $page
			search: $search
			where: $where
		) {
			paginatorInfo {
				count
				currentPage
				firstItem
				perPage
				lastItem
				total
			}
			data {
				id
				title
				type
				icon
				button_label
				topics {
					id
					title
				}
				parent {
					id
					title
				}
			}
		}
	}
`;

export const GET_CATEGORY = gql`
	query GetCategory($id: ID!) {
		getCategory(id: $id) {
			id
			title
			description
			type
			icon
			button_label
			topics {
				id
				title
			}
			parent {
				id
				title
			}
		}
	}
`;

export const CREATE_CATEGORY = gql`
	mutation CreateCategory($input: CreateCategory) {
		createCategory(input: $input) {
			id
			title
			description
			type
			icon
			button_label
			topics {
				id
				title
			}
			parent {
				id
				title
			}
		}
	}
`;

export const UPDATE_CATEGORY = gql`
	mutation UpdateCategory($input: UpdateCategory) {
		updateCategory(input: $input) {
			id
			title
			description
			type
			icon
			button_label
			topics {
				id
				title
			}
			parent {
				id
				title
			}
		}
	}
`;

export const DELETE_CATEGORY = gql`
	mutation DeleteCategory($where: WhereConditions) {
		deleteCategory(where: $where) {
			id
		}
	}
`;
