import { ChartBar, GearSix, Notebook } from '@phosphor-icons/react';

const menuItems = [
	{
		name: 'Dashboards',
		path: '/dashboards',
		icon: (color) => (
			<ChartBar size={18} weight="bold" color={color || '#72b6ae'} />
		),
		collapse: true,
		roles: [],
		items: (dashboards = []) => [
			{
				name: 'Operacional',
				path: '/dashboards/operacional',
				roles: []
			},
			{
				name: 'Conteúdos',
				path: '/dashboards/conteudo',
				roles: []
			},
			...dashboards.map((dash) => ({
				name: dash.name,
				path: `/dashboards/${dash.id}`,
				roles: []
			}))
		]
	},
	{
		name: 'Administração',
		path: '/administracao',
		icon: (color) => (
			<GearSix size={18} weight="bold" color={color || '#72b6ae'} />
		),
		collapse: true,
		roles: [],
		items: () => [
			{
				name: 'Associados',
				path: '/administracao/associados',
				roles: []
			},
			{
				name: 'Upload de Arquivos',
				path: '/administracao/novos-associados',
				roles: []
			},
			{
				name: 'Contatos',
				path: '/administracao/contatos',
				roles: []
			},
			{
				name: 'Usuários',
				path: '/administracao/usuarios',
				roles: []
			},
			{
				name: 'Relatórios',
				path: '/administracao/relatorios',
				roles: []
			},
			{
				name: 'Newsletters',
				path: '/administracao/newsletters',
				roles: []
			},
			{
				name: 'Suporte',
				path: '/administracao/suporte',
				roles: []
			}
		]
	},
	{
		name: 'Conteúdos',
		path: '/conteudos',
		icon: (color) => (
			<Notebook size={18} weight="bold" color={color || '#72b6ae'} />
		),
		collapse: true,
		roles: [],
		items: () => [
			{
				name: 'Carrossel',
				path: '/conteudos/carrossel',
				roles: []
			},
			{
				name: 'Categorias',
				path: '/conteudos/categorias',
				roles: []
			},
			{
				name: 'Posts',
				path: '/conteudos/posts',
				roles: []
			},
			{
				name: 'Tópicos',
				path: '/conteudos/topicos',
				roles: []
			}
		]
	}
];

export default menuItems;
