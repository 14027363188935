import { Text, Select, VStack } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

const Fields = () => {
	const methods = useFormContext();

	const { register } = methods;

	return (
		<VStack spacing={2} alignItems="flex-start">
			<Text size="sm">Tipo</Text>
			<Select placeholder="Selecione uma opção..." {...register('type')}>
				<option value="menu">Menu</option>
				<option value="submenu">Submenu</option>
			</Select>
		</VStack>
	);
};

export default Fields;
