const margin = {
	top: 10,
	right: 20,
	bottom: 35,
	left: 40
};

const padding = 0.3;

const legends = {
	dataFrom: 'keys',
	anchor: 'bottom-right',
	direction: 'column',
	justify: false,
	translateX: 120,
	translateY: 0,
	itemsSpacing: 2,
	itemWidth: 100,
	itemHeight: 20,
	itemDirection: 'left-to-right',
	itemOpacity: 0.85,
	symbolSize: 20,
	symbolShape: 'circle',
	effects: [
		{
			on: 'hover',
			style: {
				itemOpacity: 1
			}
		}
	]
};

export { legends, margin, padding };
