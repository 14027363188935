import { CategoriesProvider } from 'hooks/categories';
import { FiltersProvider } from 'hooks/filters';
import { TopicsProvider } from 'hooks/topics/index';
import { PostsProvider as Provider } from 'hooks/posts/index';
import ManagerQueryProvider from 'services/manager';

const PostsProvider = ({ children }) => (
	<ManagerQueryProvider>
		<FiltersProvider>
			<CategoriesProvider>
				<TopicsProvider>
					<Provider>{children}</Provider>
				</TopicsProvider>
			</CategoriesProvider>
		</FiltersProvider>
	</ManagerQueryProvider>
);

export default PostsProvider;
