import { Grid } from '@chakra-ui/react';

import ChartContainer from 'components/Charts/ChartContainer';
import ExampleChart from './ExampleChart';

const ExampleDashboard = () => (
	<>
		<Grid
			templateColumns={{ sm: '1fr', md: 'repeat(1, 1fr)' }}
			templateRows={{ sm: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' }}
			gap="12px"
			pt={{ sm: '125px', lg: '160px' }}
		>
			<ChartContainer title="Exemplo">
				<ExampleChart />
			</ChartContainer>
		</Grid>
		<Grid
			templateColumns={{ sm: '1fr', md: 'repeat(2, 1fr)' }}
			templateRows={{ sm: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' }}
			gap="12px"
			pt={{ sm: '12px', lg: '12px' }}
		>
			<ChartContainer title="Exemplo">
				<ExampleChart />
			</ChartContainer>
			<ChartContainer title="Exemplo">
				<ExampleChart />
			</ChartContainer>
		</Grid>
		<Grid
			templateColumns={{ sm: '1fr', md: 'repeat(3, 1fr)' }}
			templateRows={{ sm: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' }}
			gap="12px"
			pt={{ sm: '12px', lg: '12px' }}
		>
			<ChartContainer title="Exemplo">
				<ExampleChart />
			</ChartContainer>
			<ChartContainer title="Exemplo">
				<ExampleChart />
			</ChartContainer>
			<ChartContainer title="Exemplo">
				<ExampleChart />
			</ChartContainer>
		</Grid>
		<Grid
			templateColumns={{ sm: '1fr', md: 'repeat(1, 1fr 2fr)' }}
			templateRows={{ sm: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' }}
			gap="12px"
			pt={{ sm: '12px', lg: '12px' }}
		>
			<ChartContainer title="Exemplo">
				<ExampleChart />
			</ChartContainer>
			<ChartContainer title="Exemplo">
				<ExampleChart />
			</ChartContainer>
		</Grid>
	</>
);

export default ExampleDashboard;
