import { Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useTickets, usePaginateTickets } from 'hooks/tickets';

import AsyncTable from 'components/Tables/AsyncTable';
import dateFormat from 'functions/dateFormat';
import TicketStatusIndicator from 'components/StatusIndicator/TicketStatus';
import status from 'variables/status';
import priorities from 'variables/priorities';
import { useFilters } from 'hooks/filters';

const columns = [
	{
		Header: 'Status',
		accessor: 'status',
		Cell: ({ value }) => (
			<TicketStatusIndicator
				color={status.find((st) => st.value == value)?.color}
				label={status.find((st) => st.value == value)?.name}
				size="sm"
			/>
		)
	},
	{
		Header: 'Prioridade',
		accessor: 'priority',
		Cell: ({ value }) => (
			<TicketStatusIndicator
				color={priorities.find((st) => st.value == value)?.color}
				label={priorities.find((st) => st.value == value)?.name}
				size="sm"
			/>
		)
	},
	{
		Header: 'Onde encontrou o erro?',
		accessor: 'source'
	},
	{
		Header: 'O que o erro causa?',
		accessor: 'causes'
	},
	{
		Header: 'Aberto em',
		accessor: 'created_at',
		Cell: ({ value }) => <Text>{dateFormat(value)}</Text>
	}
];

const TableTicket = () => {
	const { filters } = useFilters();
	const { rows, page, perPage, total } = useTickets();
	const [paginateTickets, { loading }] = usePaginateTickets();

	const fetchData = ({ pageSize, pageIndex, search }) => {
		if (pageSize !== perPage) pageIndex = 1;
		paginateTickets({
			variables: {
				first: pageSize || perPage,
				page: pageIndex || page,
				search: search || '',
				...filters
			}
		});
	};

	useEffect(() => {
		if (filters && Object.keys(filters).length > 0) {
			paginateTickets({
				variables: {
					first: perPage,
					page,
					...filters
				}
			});
		}
	}, [filters]);

	return (
		<AsyncTable
			fetchData={fetchData}
			isLoaded={!loading}
			data={!loading ? rows : [{}, {}, {}, {}]}
			columns={columns}
			total={total}
			size={perPage}
			asyncPage={page}
			searchByName
			baseRoute="suporte"
			noDelete
			linkRow
		/>
	);
};

export default TableTicket;
