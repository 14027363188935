import { Text } from '@chakra-ui/react';
import { useNewsletters, usePaginateNewsletters } from 'hooks/newsletters';

import AsyncTable from 'components/Tables/AsyncTable';
import dateFormat from 'functions/dateFormat';

const columns = [
	{
		Header: 'Titulo',
		accessor: 'title'
	},
	{
		Header: 'Agendado para',
		accessor: 'scheduled_to',
		Cell: ({ value }) => <Text>{dateFormat(value, 'dd/MM/yyyy')}</Text>
	},
	{
		Header: 'Total e Envios',
		accessor: 'total_sent'
	}
];

const NewsletterTable = () => {
	// const { filters } = useFilters();
	const { rows, page, perPage, total } = useNewsletters();
	const [paginateNewsletters, { loading }] = usePaginateNewsletters();

	const fetchData = ({ pageSize, pageIndex, search }) => {
		if (pageSize != perPage) pageIndex = 1;
		paginateNewsletters({
			variables: {
				first: pageSize || perPage,
				page: pageIndex || page,
				search: search || '',
				orderBy: [
					{
						column: 'CREATED_AT',
						order: 'DESC'
					}
				]
			}
		});
	};

	return (
		<AsyncTable
			fetchData={fetchData}
			isLoaded={!loading}
			data={!loading ? rows : [{}, {}, {}, {}]}
			columns={columns}
			total={total}
			size={perPage}
			asyncPage={page}
			searchByName
			baseRoute="newsletters"
			noDelete
		/>
	);
};

export default NewsletterTable;
