import { FiltersProvider } from 'hooks/filters';
import { TicketsProvider as Provider } from 'hooks/tickets';

const TicketsProvider = ({ children }) => (
	<FiltersProvider>
		<Provider>{children}</Provider>
	</FiltersProvider>
);

export default TicketsProvider;
