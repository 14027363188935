import {
	Box,
	Flex,
	HStack,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Spinner,
	Text,
	useColorModeValue,
	useToast
} from '@chakra-ui/react';
import { FileArrowDown, Files } from '@phosphor-icons/react';

import pusher from 'services/pusher';
import { useEffect } from 'react';
import dateFormat from 'functions/dateFormat';
import {
	useLastReports,
	useDispatch,
	useGetLastReports,
	useLazyGetLastReports
} from 'hooks/lastReports';
import { updateReport } from 'hooks/lastReports/actionCreators';
import { useAuth } from 'hooks/useAuth';

const Reports = () => {
	const { user } = useAuth();
	const { reports } = useLastReports();
	const { loading } = useGetLastReports(user.id);
	const [getReports, { loading: loadingLazy }] = useLazyGetLastReports(
		user.id
	);
	const dispatch = useDispatch();

	const toast = useToast();

	const hasLoadingReport = reports.find(
		(rep) => rep.status !== 'report_done'
	);

	const iconColor = useColorModeValue('#000', '#FFF');

	/*
	{
		"message": "success",
		"report": {
			"id": "222",
			"label": "Teste 2",
			"status": "loading",
			"url": "localhost",
			"created_at": "2024-03-18 18:00:00"
		}
	}
	*/

	useEffect(() => {
		document.addEventListener('report-created', () => {
			getReports();
		});

		return () => {
			document.removeEventListener('report-created', {});
		};
	}, [reports, dispatch]);

	useEffect(() => {
		// const channel = pusher.subscribe(`private-channel.${user?.id}`);
		const channel = pusher.subscribe(`test-channel`);
		channel.bind('report-finished', (data) => {
			if (data.report && data.report.user_id == user.id) {
				updateReport(data.report, dispatch);
				if (!toast.isActive(`reportFinished_${data.report.id}`))
					toast({
						id: `reportFinished_${data.report.id}`,
						title: `Relatório ${data.report.label} finalizado`,
						duration: 4000,
						position: 'bottom',
						status: 'success'
					});
			}
		});

		return () => {
			channel.unbind('report-finished');
			pusher.unsubscribe('test-channel');
			// pusher.unsubscribe(`private-channel.${user?.id}`);
		};
	}, []);

	const downloadReport = () => {};

	return (
		<Menu closeOnSelect={false}>
			<MenuButton backgroundColor="transparent">
				{hasLoadingReport || loading || loadingLazy ? (
					<Flex alignItems="center">
						<Spinner
							size="sm"
							color="white"
							emptyColor="gray.600"
						/>
					</Flex>
				) : (
					<Files size="24px" weight="fill" color="#FFF" />
				)}
			</MenuButton>
			<MenuList className="customScrollbar" maxH="178px" overflowY="auto">
				{reports.length == 0 ? (
					<Box px="4">
						<Text fontSize="sm">
							Não há relatórios para serem exibidos.
						</Text>
					</Box>
				) : (
					reports
						.sort(
							(a, b) =>
								new Date(b.created_at) - new Date(a.created_at)
						)
						.map((rep) => (
							<a href={rep.url} target="_blank" rel="noreferrer">
								<MenuItem
									key={rep.id}
									py={8}
									h="40px"
									onClick={() => downloadReport(rep.url)}
								>
									<HStack spacing={4}>
										{rep.status !== 'report_done' ? (
											<Spinner size="sm" />
										) : (
											<FileArrowDown
												size="22px"
												weight="fill"
												color={iconColor}
											/>
										)}
										<Flex direction="column">
											<Text>{rep.label}</Text>
											<Text fontSize="sm">
												{dateFormat(
													rep.created_at,
													'dd/MM/yyyy HH:mm'
												)}
											</Text>
										</Flex>
									</HStack>
								</MenuItem>
							</a>
						))
				)}
			</MenuList>
		</Menu>
	);
};

export default Reports;
