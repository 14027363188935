import { FiltersProvider } from 'hooks/filters';
import { ReportsProvider as Provider } from 'hooks/reports/index';
import ManagerQueryProvider from 'services/manager';

const ReportsProvider = ({ children }) => (
	<ManagerQueryProvider>
		<FiltersProvider>
			<Provider>{children}</Provider>
		</FiltersProvider>
	</ManagerQueryProvider>
);

export default ReportsProvider;
