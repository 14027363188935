import { Text, VStack } from '@chakra-ui/react';
import dateFormat from 'functions/dateFormat';
import { useAssociates } from 'hooks/associates';

const Assessments = () => {
	const { associate } = useAssociates();
	const answers = associate?.answers ?? [];

	return (
		<VStack
			w="full"
			spacing={6}
			justifyContent="flex-start"
			alignItems="flex-start"
		>
			{answers
				.filter((ans) => ans.key !== 'label')
				.map((ans, x) => (
					<VStack
						key={x}
						w="full"
						spacing={2}
						justifyContent="flex-start"
						alignItems="flex-start"
					>
						<Text fontSize="md">{ans.key}</Text>
						<Text fontSize="lg">
							{ans.key == 'Nascimento'
								? dateFormat(ans.value)
								: ans.value}
						</Text>
					</VStack>
				))}
		</VStack>
	);
};

export default Assessments;
