import { useEffect } from 'react';
import { usePaginateUsers, useUsers } from 'hooks/users';

import AsyncTable from 'components/Tables/AsyncTable';
import StatusIndicator from 'components/StatusIndicator';
import { useFilters } from 'hooks/filters';

const columns = [
	{
		Header: 'Nome',
		accessor: 'name'
	},
	{
		Header: 'E-mail',
		accessor: 'email'
	},
	{
		Header: 'Perfil',
		accessor: 'type'
	},
	{
		Header: 'Status',
		accessor: 'status',
		Cell: ({ value }) => <StatusIndicator isActive={value} size="sm" />
	}
];

const UsersTable = () => {
	const { filters } = useFilters();
	const { rows, page, perPage, total, setToDelete, setDeleteModalOpen } =
		useUsers();
	const [paginateUsers, { loading }] = usePaginateUsers();

	const deleteUser = (id) => {
		setToDelete(id);
		setDeleteModalOpen(true);
	};

	const fetchData = ({ pageSize, pageIndex, search }) => {
		if (pageSize != perPage) pageIndex = 1;
		paginateUsers({
			variables: {
				first: pageSize || perPage,
				page: pageIndex || page,
				search: search || '',
				...filters
			}
		});
	};

	useEffect(() => {
		if (filters && Object.keys(filters).length > 0) {
			paginateUsers({
				variables: {
					first: perPage,
					page,
					...filters
				}
			});
		}
	}, [filters]);

	return (
		<AsyncTable
			fetchData={fetchData}
			isLoaded={!loading}
			data={!loading ? rows : [{}, {}, {}, {}]}
			columns={columns}
			total={total}
			size={perPage}
			asyncPage={page}
			searchByName
			baseRoute="usuarios"
			removeAction={deleteUser}
			linkRow
		/>
	);
};

export default UsersTable;
