import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	HStack,
	Input,
	Select,
	Text,
	useColorModeValue,
	useToast
} from '@chakra-ui/react';
import { useCreateTicket } from 'hooks/tickets';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';

const schema = yup.object().shape({
	priority: yup.string().required('O campo Prioridade é obrigatório'),
	source: yup.string().required('O campo é obrigatório'),
	causes: yup.string().required('O campo é obrigatório'),
	abstract: yup.string().required('O campo é obrigatório')
});

const CreateTicket = () => {
	const methods = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		resolver: yupResolver(schema)
	});
	const toast = useToast();
	const history = useHistory();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset
	} = methods;

	const [createTicket, { loading }] = useCreateTicket({
		onSuccess: (data) => {
			reset();
			toast({
				title: 'Suporte cadastrado com sucesso.',
				status: 'success',
				duration: 5000,
				isClosable: true
			});
			history.push(
				`/sistema/administracao/suporte/${data?.createTicket?.id}`
			);
		},
		onError: () => {
			toast({
				title: 'Ocorreu um erro',
				status: 'error',
				duration: 5000,
				isClosable: true
			});
		}
	});

	const onSubmit = (data) => {
		const variables = {
			input: {
				status: 'open',
				...data
			}
		};

		createTicket({ variables });
	};

	const inputHover = useColorModeValue('appai.main', 'appai.darker');
	const textColor = useColorModeValue('gray.700', 'white');

	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card w={{ md: '100%', lg: '50%' }}>
				<CardHeader mb="40px">
					<Flex direction="column">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Cadastrar Suporte
						</Text>
						<Text
							color="gray.400"
							fontWeight="normal"
							fontSize="sm"
						>
							Preencha os campos para realizar o cadastro
						</Text>
					</Flex>
				</CardHeader>
				<CardBody>
					<FormProvider {...methods}>
						<form
							style={{ width: '100%' }}
							onSubmit={handleSubmit(onSubmit)}
						>
							<Flex direction="column" w="100%">
								<Grid
									templateColumns={{
										sm: '1fr',
										md: 'repeat(2, 1fr)'
									}}
									gap="24px"
								>
									<FormControl isInvalid={errors.priority}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Prioridade
										</FormLabel>
										<Select
											{...register('priority')}
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
											placeholder="Selecione uma opção..."
										>
											<option value="low">Baixa</option>
											<option value="normal">
												Normal
											</option>
											<option value="high">Alta</option>
											<option value="urgent">
												Urgente
											</option>
										</Select>
										{errors.priority && (
											<FormErrorMessage>
												{errors.priority.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.source}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Onde encontrou o erro?
										</FormLabel>
										<Input
											{...register('source')}
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
										/>
										{errors.source && (
											<FormErrorMessage>
												{errors.source.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.causes}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											O que o erro causa?
										</FormLabel>
										<Input
											{...register('causes')}
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
										/>
										{errors.causes && (
											<FormErrorMessage>
												{errors.causes.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.abstract}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Resuma o erro encontrado
										</FormLabel>
										<Input
											{...register('abstract')}
											focusBorderColor={inputHover}
											borderRadius="8px"
											fontSize="md"
										/>
										{errors.abstract && (
											<FormErrorMessage>
												{errors.abstract.message}
											</FormErrorMessage>
										)}
									</FormControl>
								</Grid>
								<Flex
									w="full"
									justifyContent="flex-end"
									mt="24px"
								>
									<HStack spacing={4}>
										<Button
											disabled={loading}
											variant="ghost"
											colorScheme="gray"
											alignSelf="flex-end"
											size="sm"
											onClick={() => history.goBack()}
										>
											Cancelar
										</Button>
										<Button
											isLoading={loading}
											disabled={loading}
											colorScheme="purple"
											status="submit"
											type="submit"
											alignSelf="flex-end"
											size="md"
										>
											Cadastrar
										</Button>
									</HStack>
								</Flex>
							</Flex>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default CreateTicket;
