const status = [
	{
		name: 'Aberto',
		value: 'open',
		color: 'yellow.500'
	},
	{
		name: 'Em Andamento',
		value: 'working',
		color: 'blue.600'
	},
	{
		name: 'Finalizado',
		value: 'closed',
		color: 'green.600'
	},
	{
		name: 'Cancelado',
		value: 'canceled',
		color: 'red.600'
	}
];

export default status;
