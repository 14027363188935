import { ResponsiveBar } from '@nivo/bar';
import { useColorModeValue } from '@chakra-ui/react';
import { padding } from 'variables/nivo/bar';
import { monochromeMainColors } from 'variables/colors';
import {
	axisLeft,
	themeDark,
	themeLight,
	tooltip
} from 'variables/nivo/default';

const buildData = (data) => {
	const values = [];

	if (!data) return values;
	values.push({
		id: '80',
		label: '>80',
		value: data.eightyplus
	});
	values.push({
		id: '70_80',
		label: '70-80',
		value: data.eighty
	});
	values.push({
		id: '59_69',
		label: '59-69',
		value: data.sixtynine
	});
	values.push({
		id: '54_58',
		label: '54-58',
		value: data.fiftyeight
	});
	values.push({
		id: '49_53',
		label: '49-53',
		value: data.fiftythree
	});
	values.push({
		id: '44_48',
		label: '44-48',
		value: data.fourtyeight
	});
	values.push({
		id: '39_43',
		label: '39-43',
		value: data.fourtythree
	});
	values.push({
		id: '34_38',
		label: '34-38',
		value: data.thirtyeight
	});
	values.push({
		id: '29_33',
		label: '29-33',
		value: data.thirtythree
	});
	values.push({
		id: '24_28',
		label: '24-28',
		value: data.twentyeight
	});
	values.push({
		id: '19_23',
		label: '19-23',
		value: data.twentythree
	});
	values.push({
		id: '0_18',
		label: '0-18',
		value: data.eighteen
	});

	return values;
};

const BeneficiariesByAgeChart = ({ data }) => (
	<ResponsiveBar
		data={buildData(data)}
		keys={['value']}
		indexBy="label"
		layout="horizontal"
		margin={{ top: 5, right: 10, bottom: 5, left: 70 }}
		padding={padding}
		colors={monochromeMainColors}
		labelSkipWidth={11}
		theme={useColorModeValue(themeLight, themeDark)}
		axisBottom={null}
		axisLeft={axisLeft}
		labelTextColor="#FFF"
		tooltip={({ indexValue, value }) => tooltip(indexValue, value)}
	/>
);

export default BeneficiariesByAgeChart;
