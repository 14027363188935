import { Route } from 'react-router-dom';
import LastReportsProvider from 'providers/lastReportsProvider';
import { Box, Flex } from '@chakra-ui/react';
import { useAuth } from 'hooks/useAuth';
import { JWT_TOKEN_COGNITO_ID } from '../../variables/enumLabels';

// eslint-disable-next-line no-unused-vars
const PrivateRoute = ({ component: Component, roles = [], ...props }) => {
	const { user } = useAuth();
	if (!window.localStorage.getItem(JWT_TOKEN_COGNITO_ID)) return null;

	if (!user.id)
		return (
			<Box id="???" minW="100vw" minH="100vh">
				<Flex
					w="100vw"
					h="100vh"
					justifyContent="center"
					alignItems="center"
				>
					<Box w="100px" h="100px" />
				</Flex>
			</Box>
		);

	return (
		<LastReportsProvider>
			<Route {...props} render={() => <Component />} />
		</LastReportsProvider>
	);
};

export default PrivateRoute;
