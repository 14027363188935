import { FiltersProvider } from 'hooks/filters';
import { UsersProvider as Provider } from 'hooks/users/index';

const UsersProvider = ({ children }) => (
	<FiltersProvider>
		<Provider>{children}</Provider>
	</FiltersProvider>
);

export default UsersProvider;
