import {
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	useColorModeValue
} from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';

import Assessments from './Assessments';

const FolderList = [
	{
		label: 'Formulário',
		component: <Assessments />
	}
];

const Folders = ({ isLoading }) => {
	const tabTextColor = useColorModeValue('purple.500', 'purple.300');
	const tabOutlineColor = useColorModeValue(
		'blackAlpha.400',
		'whiteAlpha.400'
	);

	const tabProps = {
		color: tabTextColor,
		outline: '1px solid',
		outlineColor: tabOutlineColor,
		marginRight: '12px',
		isDisabled: isLoading
	};

	return (
		<Card
			px="0px"
			pb="0px"
			minHeight="100px"
			overflowX={{ sm: 'auto', lg: 'unset' }}
		>
			<Tabs variant="soft-rounded" colorScheme="purple" isFitted isLazy>
				<CardHeader px="22px" mb="12px">
					<TabList>
						{FolderList.map((fld) => (
							<Tab {...tabProps}>{fld.label}</Tab>
						))}
					</TabList>
				</CardHeader>
				<CardBody h="100%" px="22px">
					<TabPanels>
						{FolderList.map((fld) => (
							<TabPanel px={0}>{fld.component}</TabPanel>
						))}
					</TabPanels>
				</CardBody>
			</Tabs>
		</Card>
	);
};

export default Folders;
