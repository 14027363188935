const priorities = [
	{
		name: 'Baixa',
		value: 'low',
		color: 'green.600'
	},
	{
		name: 'Normal',
		value: 'normal',
		color: 'blue.600'
	},
	{
		name: 'Alta',
		value: 'high',
		color: 'orange.500'
	},
	{
		name: 'Urgente',
		value: 'urgent',
		color: 'red.600'
	}
];

export default priorities;
