import { createContext, useContext, useReducer } from 'react';

import { useMutation, useQuery } from '@apollo/client';

import {
	GET_CAROUSEL,
	CREATE_CAROUSEL,
	UPDATE_CAROUSEL,
	UPDATE_CAROUSEL_ORDER,
	DELETE_CAROUSEL
} from 'graphql/carousel';

import { ACTION_TYPES, reducers } from './reducers';

const CarouselStateContext = createContext();
const CarouselDispatchContext = createContext();

const initialState = {
	carousel: []
};

const CarouselProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducers, initialState);

	return (
		<CarouselStateContext.Provider
			value={{
				...state
			}}
		>
			<CarouselDispatchContext.Provider value={dispatch}>
				{children}
			</CarouselDispatchContext.Provider>
		</CarouselStateContext.Provider>
	);
};

const useCarousel = () => {
	const context = useContext(CarouselStateContext);

	if (!context)
		throw new Error('useCarousel must be used within an CarouselProvider');

	return context;
};

const useDispatch = () => {
	const dispatch = useContext(CarouselDispatchContext);

	if (dispatch === undefined)
		throw new Error('useDispatch must be used within a CarouselProvider');

	return dispatch;
};

const useAllCarousel = () => {
	const dispatch = useDispatch();

	return useQuery(GET_CAROUSEL, {
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			dispatch({ type: ACTION_TYPES.ALL, data: data.listCarousel });
		}
	});
};

const useCreateCarousel = (options = {}) => {
	const dispatch = useDispatch();
	const { onSuccess, onError } = options;

	return useMutation(CREATE_CAROUSEL, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
			dispatch({ type: ACTION_TYPES.ADD, data: data.createCarousel });
		},
		onError: (error) => {
			if (onError) onError(error);
		}
	});
};

const useUpdateCarousel = (options = {}) => {
	const dispatch = useContext(CarouselDispatchContext);
	const { onSuccess, onError } = options;

	return useMutation(UPDATE_CAROUSEL, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
			dispatch({ type: ACTION_TYPES.UPDATE, data: data.updateCarousel });
		},
		onError: (error) => {
			if (onError) onError(error);
		},
	});
};

const useUpdateCarouselOrder = () => {
	const dispatch = useContext(CarouselDispatchContext);

	return useMutation(UPDATE_CAROUSEL_ORDER, {
		onCompleted: (data) => {
			dispatch({ type: ACTION_TYPES.UPDATE_LIST_ORDER, data: data.updateCarouselOrder });
		}
	});
};

const useDeleteCarousel = (options = {}) => {
	const dispatch = useDispatch();
	const { onSuccess } = options;

	return useMutation(DELETE_CAROUSEL, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess();
			dispatch({
				type: ACTION_TYPES.REMOVE,
				data: data.deleteCarousel
			});
		}
	});
};

export {
	CarouselProvider,
	useDispatch,
	useCarousel,
	useAllCarousel,
	useCreateCarousel,
	useUpdateCarousel,
	useUpdateCarouselOrder,
	useDeleteCarousel
};
