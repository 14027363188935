const hasDateValue = (values) => {
	let returnValues = values;
	values.map((val) => {
		if (
			(val.label.includes('date') || val.label.includes('_at')) &&
			!val.label.includes('accepted') &&
			val.value != null
		) {
			const splitDate = val.value.split('/');
			const date = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
			const dateValue = val.label.includes('end')
				? `${date} 23:59:59`
				: `${date} 00:00:01`;
			returnValues = returnValues.map((ret) => {
				if (ret.label == val.label) {
					return {
						label: val.label,
						value: dateValue
					};
				}
				return ret;
			});
		}
		return null;
	});

	return returnValues;
};

export { hasDateValue };
