import { useFormContext } from 'react-hook-form';

import InputMask from 'react-input-mask';

import {
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	HStack,
	Input,
	Skeleton,
	useColorModeValue
} from '@chakra-ui/react';
import dateFormat from 'functions/dateFormat';

const StringDateValue = (value) => dateFormat(value, 'dd/MM/yyyy');

const NewsletterForm = ({
	loading,
	loadingSubmit,
	defaultValues = {},
	isEdit = false,
	isReadOnly = false,
	onSubmit
}) => {
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useFormContext();

	const inputHover = useColorModeValue('appai.main', 'appai.main');
	const textColor = useColorModeValue('gray.700', 'white');

	return (
		<form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
			<Flex direction="column" w="100%">
				<Grid
					templateColumns={{
						sm: '1fr',
						md: 'repeat(2, 1fr)'
					}}
					gap="24px"
				>
					<FormControl isInvalid={errors.title}>
						<FormLabel
							color={textColor}
							fontWeight="bold"
							fontSize="xs"
						>
							Título
						</FormLabel>
						{loading ? (
							<Skeleton height="40px" />
						) : (
							<Input
								{...register('title')}
								defaultValue={defaultValues.title}
								focusBorderColor={inputHover}
								borderRadius="8px"
								fontSize="md"
								data-testid="title-input"
							/>
						)}
						{errors.title && (
							<FormErrorMessage>
								{errors.title.message}
							</FormErrorMessage>
						)}
					</FormControl>
					<FormControl isInvalid={errors.url}>
						<FormLabel
							color={textColor}
							fontWeight="bold"
							fontSize="xs"
						>
							URL
						</FormLabel>
						{loading ? (
							<Skeleton height="40px" />
						) : (
							<Input
								{...register('url')}
								defaultValue={defaultValues.url}
								focusBorderColor={inputHover}
								borderRadius="8px"
								fontSize="md"
								data-testid="url-input"
							/>
						)}
						{errors.url && (
							<FormErrorMessage>
								{errors.url.message}
							</FormErrorMessage>
						)}
					</FormControl>
					<FormControl isInvalid={errors.scheduled_to}>
						<FormLabel
							color={textColor}
							fontWeight="bold"
							fontSize="xs"
						>
							Agendado para
						</FormLabel>
						<Input
							{...register('scheduled_to')}
							defaultValue={StringDateValue(
								defaultValues.scheduled_to
							)}
							as={InputMask}
							mask="99/99/9999"
							focusBorderColor={inputHover}
							borderRadius="8px"
							fontSize="md"
							data-testid="scheduled_to-input"
						/>
						{errors.scheduled_to && (
							<FormErrorMessage>
								{errors.scheduled_to.message}
							</FormErrorMessage>
						)}
					</FormControl>
				</Grid>
				<Flex w="full" justifyContent="flex-end" mt="24px">
					<HStack spacing={4}>
						{isReadOnly ? (
							<Button
								variant="ghost"
								colorScheme="gray"
								alignSelf="flex-end"
								size="md"
								onClick={() => history.goBack()}
							>
								Voltar
							</Button>
						) : (
							<>
								<Button
									disabled={loadingSubmit}
									variant="ghost"
									colorScheme="gray"
									alignSelf="flex-end"
									size="md"
									onClick={() => history.goBack()}
								>
									Cancelar
								</Button>
								<Button
									isLoading={loadingSubmit}
									disabled={
										loadingSubmit ||
										defaultValues.total_sent > 0
									}
									colorScheme="purple"
									type="submit"
									alignSelf="flex-end"
									size="md"
									data-testid="submit-button"
								>
									{isEdit ? 'Atualizar' : 'Cadastrar'}
								</Button>
							</>
						)}
					</HStack>
				</Flex>
			</Flex>
		</form>
	);
};

export default NewsletterForm;
