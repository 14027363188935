import { gql } from '@apollo/client';

export const GET_ASSOCIATES = gql`
	query GetAssociates {
		listAssociates {
			id
			name
			phone
			email
			newsletter_sent_at
			accept
			status
			created_at
			updated_at
			deleted_at
		}
	}
`;

export const GET_ASSOCIATES_PAGINATE = gql`
	query PaginateAssociates(
		$first: Int! = 30
		$page: Int! = 1
		$search: String
		$where: QueryPaginateAssociatesWhereWhereConditions
		$orderBy: [QueryPaginateAssociatesOrderByOrderByClause!]
	) {
		paginateAssociates(
			first: $first
			page: $page
			search: $search
			where: $where
			orderBy: $orderBy
		) {
			paginatorInfo {
				count
				currentPage
				firstItem
				perPage
				lastItem
				total
			}
			data {
				id
				name
				phone
				email
				newsletter_sent_at
				accept
				accepted_at
				status
				created_at
				updated_at
				deleted_at
			}
		}
	}
`;

export const GET_ASSOCIATE = gql`
	query GetAssociate($id: ID!) {
		getAssociate(id: $id) {
			id
			appai_id
			name
			phone
			email
			newsletter_sent_at
			accept
			accepted_at
			status
			answers {
				key
				tag
				value
			}
			results {
				name
				tag
				value
			}
			created_at
			updated_at
			deleted_at
		}
	}
`;

export const CREATE_ASSOCIATE = gql`
	mutation CreateAssociate($input: CreateAssociate) {
		createAssociate(input: $input) {
			id
			name
			phone
			email
			newsletter_sent_at
			accept
			status
			created_at
			updated_at
			deleted_at
		}
	}
`;

export const UPDATE_ASSOCIATE = gql`
	mutation UpdateAssociate($input: UpdateAssociate) {
		updateAssociate(input: $input) {
			id
			name
			phone
			email
			newsletter_sent_at
			accept
			status
			created_at
			updated_at
			deleted_at
		}
	}
`;

export const UPDATE_ASSOCIATE_STATUS = gql`
	mutation UpdateAssociateStatus($input: UpdateAssociateStatus) {
		updateAssociateStatus(input: $input) {
			id
			status
		}
	}
`;

export const DELETE_ASSOCIATE = gql`
	mutation DeleteAssociate($where: WhereConditions) {
		deleteAssociate(where: $where) {
			id
		}
	}
`;
