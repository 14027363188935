const ACTION_TYPES = {
	LIST: 'LIST_LAST',
	ADD: 'ADD_LAST',
	UPDATE: 'UPDATE_LAST'
};

const reducers = (state, action) => {
	switch (action.type) {
		case ACTION_TYPES.LIST: {
			return {
				...state,
				reports: action.data
			};
		}
		case ACTION_TYPES.ADD: {
			return {
				...state,
				reports: [action.data]
			};
		}
		case ACTION_TYPES.UPDATE: {
			return {
				...state,
				reports: state.reports.map((rep) => {
					if (rep.id == action.data.id) {
						rep = action.data;
					}
					return rep;
				})
			};
		}
		default: {
			return state;
		}
	}
};

export { reducers, ACTION_TYPES };
