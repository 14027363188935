import ExampleDashboard from 'views/Dashboards/ExampleDashboard';
import OperationalDashboard from 'views/Dashboards/OperationalDashboard';
import PostsDashboard from 'views/Dashboards/PostsDashboard';
import DashboardsProvider from 'providers/dashboardsProvider';

import AssociatesProvider from 'providers/associatesProvider';
import Associates from 'views/Associates';
import CreateAssociate from 'views/Associates/CreateAssociate';
import ViewAssociate from 'views/Associates/ViewAssociate';
import EditAssociate from 'views/Associates/EditAssociate';

import ContactsProvider from 'providers/contactsProvider';
import Contacts from 'views/Contacts';
import CreateContact from 'views/Contacts/CreateContact';
import ViewContact from 'views/Contacts/ViewContact';
import EditContact from 'views/Contacts/EditContact';

import Tickets from 'views/Tickets';
import TicketsProvider from 'providers/ticketsProvider';
import CreateTicket from 'views/Tickets/CreateTicket';
import ViewTicket from 'views/Tickets/ViewTicket';
import EditTicket from 'views/Tickets/EditTicket';

import UsersProvider from 'providers/usersProvider';
import Users from 'views/Users';
import CreateUser from 'views/Users/CreateUser';
import ViewUser from 'views/Users/ViewUser';
import EditUser from 'views/Users/EditUser';

import CategoriesProvider from 'providers/categoriesProvider';
import CreateCategory from 'views/Categories/CreateCategory';
import ViewCategory from 'views/Categories/ViewCategory';
import EditCategory from 'views/Categories/EditCategory';
import Categories from 'views/Categories';

import TopicsProvider from 'providers/topicsProvider';
import Topics from 'views/Topics';
import EditTopic from 'views/Topics/EditTopic';
import ViewTopic from 'views/Topics/ViewTopic';
import CreateTopic from 'views/Topics/CreateTopic';

import PostsProvider from 'providers/PostsProvider';
import Posts from 'views/Posts';
import CreatePost from 'views/Posts/CreatePost';
import ViewPost from 'views/Posts/ViewPost';
import EditPost from 'views/Posts/EditPost';
import NewAssociates from 'views/NewAssociates';
import NewAssociatesProvider from 'providers/newAssociatesProvider';

import NewsletterProvider from 'providers/newsletterProvider';
import Newsletters from 'views/Newsletters';
import CreateNewsletter from 'views/Newsletters/CreateNewsletter';
import ViewNewsletter from 'views/Newsletters/ViewNewsletter';
import EditNewsletter from 'views/Newsletters/EditNewsletter';

import CarouselProvider from 'providers/carouselProvider';
import Carousel from 'views/Carousel';

import ReportsProvider from 'providers/ReportsProvider';
import Reports from './views/Reports';

const routes = [
	// DASHBOARDS
	{
		name: 'dashboardOperacional',
		path: '/dashboards/operacional',
		component: OperationalDashboard,
		provider: UsersProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'dashboardPosts',
		path: '/dashboards/conteudo',
		component: PostsDashboard,
		provider: DashboardsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'exemplo',
		path: '/dashboards/exemplo',
		component: ExampleDashboard,
		provider: UsersProvider,
		layout: '/sistema',
		roles: []
	},
	// ASSOCIADOS
	{
		name: 'associates',
		path: '/administracao/associados',
		component: Associates,
		provider: AssociatesProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'createAssociate',
		path: '/administracao/associados/cadastrar',
		component: CreateAssociate,
		provider: AssociatesProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'viewAssociate',
		path: '/administracao/associados/:id',
		component: ViewAssociate,
		provider: AssociatesProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'editAssociate',
		path: '/administracao/associados/:id/editar',
		component: EditAssociate,
		provider: AssociatesProvider,
		layout: '/sistema',
		roles: []
	},
	// NOVOS ASSOCIADOS
	{
		name: 'newAssociates',
		path: '/administracao/novos-associados',
		component: NewAssociates,
		provider: NewAssociatesProvider,
		layout: '/sistema',
		roles: []
	},
	// CONTATOS
	{
		name: 'contacts',
		path: '/administracao/contatos',
		component: Contacts,
		provider: ContactsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'createContact',
		path: '/administracao/contatos/cadastrar',
		component: CreateContact,
		provider: ContactsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'viewContact',
		path: '/administracao/contatos/:id',
		component: ViewContact,
		provider: ContactsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'editContact',
		path: '/administracao/contatos/:id/editar',
		component: EditContact,
		provider: ContactsProvider,
		layout: '/sistema',
		roles: []
	},
	// SUPORTE
	{
		name: 'suporte',
		path: '/administracao/suporte',
		component: Tickets,
		provider: TicketsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'suporte',
		path: '/administracao/suporte/cadastrar',
		component: CreateTicket,
		provider: TicketsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'suporte',
		path: '/administracao/suporte/:id',
		component: ViewTicket,
		provider: TicketsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'suporte',
		path: '/administracao/suporte/:id/editar',
		component: EditTicket,
		provider: TicketsProvider,
		layout: '/sistema',
		roles: []
	},
	// USUARIOS
	{
		name: 'usuarios',
		path: '/administracao/usuarios',
		component: Users,
		provider: UsersProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'usuarios',
		path: '/administracao/usuarios/cadastrar',
		component: CreateUser,
		provider: UsersProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'usuarios',
		path: '/administracao/usuarios/:id',
		component: ViewUser,
		provider: UsersProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'usuarios',
		path: '/administracao/usuarios/:id/editar',
		component: EditUser,
		provider: UsersProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'relatorios',
		path: '/administracao/relatorios',
		component: Reports,
		provider: ReportsProvider,
		layout: '/sistema',
		roles: []
	},
	// CATEGORIAS
	{
		name: 'categorias',
		path: '/conteudos/categorias',
		component: Categories,
		provider: CategoriesProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'categorias',
		path: '/conteudos/categorias/cadastrar',
		component: CreateCategory,
		provider: CategoriesProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'categorias',
		path: '/conteudos/categorias/:id',
		component: ViewCategory,
		provider: CategoriesProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'categorias',
		path: '/conteudos/categorias/:id/editar',
		component: EditCategory,
		provider: CategoriesProvider,
		layout: '/sistema',
		roles: []
	},
	// TÓPICOS
	{
		name: 'topicos',
		path: '/conteudos/topicos',
		component: Topics,
		provider: TopicsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'topicos',
		path: '/conteudos/topicos/cadastrar',
		component: CreateTopic,
		provider: TopicsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'topicos',
		path: '/conteudos/topicos/:id',
		component: ViewTopic,
		provider: TopicsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'topicos',
		path: '/conteudos/topicos/:id/editar',
		component: EditTopic,
		provider: TopicsProvider,
		layout: '/sistema',
		roles: []
	},
	// CAROUSEL
	{
		name: 'carrossel',
		path: '/conteudos/carrossel',
		component: Carousel,
		provider: CarouselProvider,
		layout: '/sistema',
		roles: []
	},
	// POSTS
	{
		name: 'posts',
		path: '/conteudos/posts',
		component: Posts,
		provider: PostsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'posts',
		path: '/conteudos/posts/cadastrar',
		component: CreatePost,
		provider: PostsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'posts',
		path: '/conteudos/posts/:id',
		component: ViewPost,
		provider: PostsProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'posts',
		path: '/conteudos/posts/:id/editar',
		component: EditPost,
		provider: PostsProvider,
		layout: '/sistema',
		roles: []
	},
	// ASSOCIADOS
	{
		name: 'newsletters',
		path: '/administracao/newsletters',
		component: Newsletters,
		provider: NewsletterProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'createNewsletter',
		path: '/administracao/newsletters/cadastrar',
		component: CreateNewsletter,
		provider: NewsletterProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'viewNewsletter',
		path: '/administracao/newsletters/:id',
		component: ViewNewsletter,
		provider: NewsletterProvider,
		layout: '/sistema',
		roles: []
	},
	{
		name: 'editNewsletter',
		path: '/administracao/newsletters/:id/editar',
		component: EditNewsletter,
		provider: NewsletterProvider,
		layout: '/sistema',
		roles: []
	}
];

export default routes;
