import { useHistory, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Flex, Text, useColorModeValue, useToast } from '@chakra-ui/react';

import { isAfter, isValid, parse } from 'date-fns';

import {
	useNewsletter,
	useUpdateNewsletter
} from 'hooks/newsletters';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import NewsletterForm from './NewsletterForm';

const validateDate = (field) => {
	const explodeDate = field.split('/');
	const date = `${explodeDate[1]}-${explodeDate[0]}-${explodeDate[2]}`;
	if (!isValid(new Date(date))) return false;
	return true;
};

const schema = yup.object().shape({
	title: yup.string().required('O campo Título é obrigatório'),
	url: yup.string().required('O campo URL é obrigatório'),
	scheduled_to: yup
		.string()
		.required('O campo Data de Envio é obrigatório')
		.test('date-valid', 'Data inválida', (field) => validateDate(field))
		.test('future-date', 'Insira uma data futura', (field) =>{
			const today = new Date();
			const inputDate = parse(field, 'dd/MM/yyyy', new Date());
			return isAfter(inputDate, today);
		})
});

const EditNewsletter = () => {
	const { id } = useParams();
	const toast = useToast();
	const history = useHistory();

	const { data, loading } = useNewsletter(id);

	const methods = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		resolver: yupResolver(schema)
	});

	const { reset } = methods;

	const [editNewsletter, { loading: loadingSubmit }] = useUpdateNewsletter({
		onSuccess: (data) => {
			reset();
			toast({
				title: 'Newsletter atualizado com sucesso.',
				status: 'success',
				duration: 5000,
				isClosable: true
			});
			history.push(
				`/sistema/administracao/newsletters/${data.updateNewsletter.id}`
			);
		},
		onError: (error) => {
			const errorsArr = error ? Object.keys(error) : null;
			if (errorsArr)
				errorsArr.map((err) => {
					if (error[err]) {
						toast({
							title: error[err][0],
							status: 'error',
							duration: 5000,
							isClosable: true
						});
					}
				});
			else
				toast({
					title: 'Ocorreu um erro',
					status: 'error',
					duration: 5000,
					isClosable: true
				});
		}
	});

	const onSubmit = (data) => {
		const explodeDate = data.scheduled_to.split('/');
		const scheduledTo = `${explodeDate[2]}-${explodeDate[1]}-${explodeDate[0]}`;
		const variables = {
			input: {
				...data,
				id,
				scheduled_to: scheduledTo
			}
		};

		editNewsletter({ variables });
	};

	const newsletter = data?.getNewsletter;
	const textColor = useColorModeValue('gray.700', 'white');

	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card w={{ md: '100%', lg: '50%' }}>
				<CardHeader mb="40px">
					<Flex direction="column">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Editar Newsletter
						</Text>
						<Text
							color="gray.400"
							fontWeight="normal"
							fontSize="sm"
						>
							Preencha os campos para realizar o cadastro
						</Text>
					</Flex>
				</CardHeader>
				<CardBody>
					<FormProvider {...methods}>
						{!loading && (
							<NewsletterForm
								loadng={loading}
								loadgingSubmit={loadingSubmit}
								defaultValues={newsletter}
								onSubmit={onSubmit}
								isEdit
							/>
						)}
					</FormProvider>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default EditNewsletter;
