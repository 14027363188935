import { Flex } from '@aws-amplify/ui-react';
import { Box, Image } from '@chakra-ui/react';
import { Download } from '@phosphor-icons/react';
import { useState } from 'react';

export default ({ link = '' }) => {
	const [downloadIcon, setDownloadIcon] = useState(false);

	return (
		<Flex direction="column" position="relative">
			<Box onClick={() => window.open(link)}>
				{downloadIcon && (
					<Box
						position="absolute"
						top="50%"
						left="50%"
						transform="translate(-50%, -50%)"
						zIndex={1}
						_hover={{ cursor: 'pointer' }}
					>
						<Download size={50} color="white" weight="fill" />
					</Box>
				)}
				<Image
					borderRadius="12px"
					boxSize="220px 400px"
					objectFit="cover"
					_hover={{
						cursor: 'pointer',
						filter: 'brightness(0.7)'
					}}
					onMouseOver={() => setDownloadIcon(true)}
					onMouseOut={() => setDownloadIcon(false)}
					src={link}
				/>
			</Box>
		</Flex>
	);
};
