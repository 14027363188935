import { ResponsivePie } from '@nivo/pie';
import { useColorModeValue } from '@chakra-ui/react';
import { risksColorsPlus } from 'variables/colors';

import { border, tooltip } from 'variables/nivo/default';
import {
	arc,
	arcLabelsTextColor,
	arcLinkLabelsColor,
	legendsDark,
	legendsLight,
	margin,
	pieProps
} from 'variables/nivo/pie';

const buildData = (data) => {
	const values = [];

	if (!data) return values;

	values.push({
		id: 'nunca',
		label: 'Nunca',
		value: data.nunca
	});
	values.push({
		id: 'raramente',
		label: 'Raramente',
		value: data.raramente
	});
	values.push({
		id: 'moderadamente',
		label: 'Moderadamente',
		value: data.moderadamente
	});
	values.push({
		id: 'frequentemente',
		label: 'Frequentemente',
		value: data.frequentemente
	});
	values.push({
		id: 'quase_sempre',
		label: 'Quase sempre',
		value: data.quase_sempre
	});
	return values;
};

const total = (data) =>
	data.nunca +
	data.raramente +
	data.moderadamente +
	data.frequentemente +
	data.quase_sempre;

const AnimalConsumptionChart = ({ data }) => (
	<ResponsivePie
		data={buildData(data)}
		margin={margin}
		colors={risksColorsPlus}
		{...border}
		{...arc}
		arcLinkLabelsColor={arcLinkLabelsColor}
		arcLabelsTextColor={arcLabelsTextColor}
		legends={useColorModeValue(legendsLight, legendsDark)}
		tooltip={({ datum }) =>
			tooltip(datum.label, datum.value, total(data), datum.color)
		}
		{...pieProps}
		enableArcLabels={false}
		innerRadius={0.7}
	/>
);

export default AnimalConsumptionChart;
