import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
	Button,
	Flex,
	Select,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	HStack,
	Input,
	Skeleton,
	Text,
	useColorModeValue,
	useToast
} from '@chakra-ui/react';
import { usePosts, useUpdatePost, usePost, useTemplates } from 'hooks/posts';
import { useAllTopics } from 'hooks/topics';

import UploadField from 'components/UploadField';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import TemplateFields from 'components/TemplateFields';
import Imports from './Imports';

const schema = yup.object().shape({
	title: yup.string().required('O campo Título é obrigatório'),
	topic_id: yup.string().required('O campo Tópico é obrigatório'),
	template_id: yup.string().required('O campo Template é obrigatório')
});

const EditPost = () => {
	const { id } = useParams();
	const posts = usePosts();
	const postImports = posts?.post?.imports ?? [];
	const { data, loading } = usePost(id);
	const post = data?.getPost ?? {};

	const {
		data: { listTemplates: templates } = [],
		loading: loadingTemplates
	} = useTemplates();

	const { data: { listTopics: topics } = [], loading: loadingTopics } =
		useAllTopics();

	const history = useHistory();

	const methods = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		resolver: yupResolver(schema),
		defaultValues: post
	});

	const toast = useToast();

	const {
		register,
		reset,
		handleSubmit,
		watch,
		formState: { errors }
	} = methods;

	const [updatePost, { loading: loadingUpdate }] = useUpdatePost({
		onSuccess: (data) => {
			reset();
			toast({
				title: 'Post atualizado com sucesso.',
				status: 'success',
				duration: 5000,
				isClosable: true
			});
			history.push(`/sistema/conteudos/posts/${data.updatePost.id}`);
		},
		onError: () => {
			toast({
				title: 'Ocorreu um erro',
				status: 'error',
				duration: 5000,
				isClosable: true
			});
		}
	});

	const onSubmit = (data) => {
		data.template_fields = JSON.stringify(data.template_fields);

		const variables = {
			input: {
				id,
				...data,
				class: 'Post'
			}
		};

		updatePost({ variables });
	};

	const [templateFields, setTemplateFields] = useState([]);
	const wTemplate = watch('template_id', post?.template_id);

	useEffect(() => {
		if (!wTemplate) setTemplateFields([]);

		const fields = templates?.find(({ id }) => id === wTemplate)?.fields;

		if (fields) {
			const parsed = JSON.parse(JSON.parse(fields));
			const fieldsArr = [];

			Object.keys(parsed).forEach((key) => {
				const entry = {};
				entry[key] = parsed[key];
				fieldsArr.push(entry);
			});

			setTemplateFields(fieldsArr);
		}
	}, [wTemplate]);

	const inputHover = useColorModeValue('appai.main', 'appai.main');
	const textColor = useColorModeValue('gray.700', 'white');

	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card w={{ md: '100%', lg: '50%' }}>
				<CardHeader mb="40px">
					<Flex direction="column">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Editar Post
						</Text>
					</Flex>
				</CardHeader>
				<CardBody>
					<FormProvider {...methods}>
						<form
							style={{ width: '100%' }}
							onSubmit={handleSubmit(onSubmit)}
						>
							<Flex direction="column" w="100%">
								<Grid
									templateColumns={{
										sm: '1fr',
										md: 'repeat(2, 1fr)'
									}}
									gap="24px"
								>
									<FormControl isInvalid={errors.title}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Título
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('title')}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
												defaultValue={post?.title}
											/>
										)}
										{errors.title && (
											<FormErrorMessage>
												{errors.title.message}
											</FormErrorMessage>
										)}
									</FormControl>

									<FormControl isInvalid={errors.subtitle}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Subtítulo
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('subtitle')}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
												defaultValue={post?.subtitle}
											/>
										)}
										{errors.subtitle && (
											<FormErrorMessage>
												{errors.subtitle.message}
											</FormErrorMessage>
										)}
									</FormControl>

									<FormControl isInvalid={errors.status}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Status
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Select
												{...register('status')}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
												defaultValue={post?.status}
											>
												<option value="draft">
													Rascunho
												</option>
												<option value="published">
													Publicado
												</option>
												<option value="archived">
													Arquivado
												</option>
											</Select>
										)}
										{errors.subtitle && (
											<FormErrorMessage>
												{errors.status.message}
											</FormErrorMessage>
										)}
									</FormControl>

									<FormControl isInvalid={errors.template_id}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Template
										</FormLabel>
										{loading || loadingTemplates ? (
											<Skeleton height="40px" />
										) : (
											<Select
												{...register('template_id')}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
												placeholder="Selecione..."
												defaultValue={
													post?.template?.id
												}
											>
												{templates?.map((template) => (
													<option value={template.id}>
														{template.title}
													</option>
												))}
											</Select>
										)}
										{errors.template_id && (
											<FormErrorMessage>
												{errors.template_id.message}
											</FormErrorMessage>
										)}
									</FormControl>

									<FormControl isInvalid={errors.topic_id}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Tópico
										</FormLabel>
										{loading || loadingTopics ? (
											<Skeleton height="40px" />
										) : (
											<Select
												{...register('topic_id')}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
												placeholder="Selecione..."
												defaultValue={post?.topic?.id}
											>
												{topics?.map((topic) => (
													<option value={topic.id}>
														{topic.title}
													</option>
												))}
											</Select>
										)}
										{errors.topic_id && (
											<FormErrorMessage>
												{errors.topic_id.message}
											</FormErrorMessage>
										)}
									</FormControl>
									{wTemplate == 2 && (
										<FormControl
											isInvalid={errors.url_embed}
										>
											<FormLabel
												color={textColor}
												fontWeight="bold"
												fontSize="xs"
											>
												URL do Vídeo
											</FormLabel>
											{loading ? (
												<Skeleton height="40px" />
											) : (
												<Input
													{...register('url_embed')}
													focusBorderColor={
														inputHover
													}
													borderRadius="8px"
													fontSize="md"
													defaultValue={
														post?.url_embed
													}
												/>
											)}
											{errors.url_embed && (
												<FormErrorMessage>
													{errors.url_embed.message}
												</FormErrorMessage>
											)}
										</FormControl>
									)}
								</Grid>

								<UploadField
									label="Imagem de capa"
									filePrefix="cover"
								/>

								<UploadField
									label="Arquivos para download"
									filePrefix="download"
									multiple
								/>

								<TemplateFields
									fields={templateFields}
									body={post?.template_fields}
								/>

								<Imports imports={postImports} />

								<Flex
									w="full"
									justifyContent="flex-end"
									mt="24px"
								>
									<HStack spacing={4}>
										<Button
											disabled={loadingUpdate}
											variant="ghost"
											colorScheme="gray"
											alignSelf="flex-end"
											size="md"
											onClick={() => history.goBack()}
										>
											Cancelar
										</Button>
										<Button
											isLoading={loadingUpdate}
											disabled={loadingUpdate}
											colorScheme="purple"
											type="submit"
											alignSelf="flex-end"
											size="md"
										>
											Atualizar
										</Button>
									</HStack>
								</Flex>
							</Flex>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default EditPost;
