/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/named */
/* eslint-disable no-unused-vars */

// Chakra Icons
// Chakra Imports
import {
	Button,
	Flex,
	HStack,
	IconButton,
	Input,
	InputGroup,
	InputLeftElement,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Spinner,
	Text,
	useColorModeValue,
	useToast
} from '@chakra-ui/react';
// Custom Icons
// Custom Components
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import TimeAgo from 'javascript-time-ago';
import br from 'javascript-time-ago/locale/br.json';
import { Auth } from 'aws-amplify';
import { IoDocumentsSharp } from 'react-icons/io5';
import ReactTimeAgo from 'react-time-ago';
import Icon from 'components/FontAwesomeIcons/Icon';
import menuItems from 'menu';
import { SidebarResponsive } from 'components/Sidebar/Sidebar';
import { useAuth, useLogout, useLogout_ } from 'hooks/useAuth';
import { JWT_TOKEN_COGNITO, JWT_TOKEN_COGNITO_ID } from 'variables/enumLabels';
import { useNotifications } from 'hooks/notifications';
import { ItemContent } from '../Menu/ItemContent';
import RegionalSelect from './RegionalSelect';
import Reports from './Reports';

TimeAgo.addDefaultLocale(br);
TimeAgo.addLocale(br);

export default function HeaderLinks(props) {
	const { user, logout } = useAuth();
	const { variant, children, fixed, secondary, onOpen, ...rest } = props;

	/* useEffect(() => {
		if (newForm.status && newForm.status == 'form')
			toast({
				title: `Formulário salvo com sucesso!`,
				status: 'success',
				duration: 5000,
				isClosable: true
			});
	}, [newForm]);

	useEffect(() => {
		if (newReport.status && newReport.status == 'generated')
			toast({
				title: `Relatório: ${newReport.label} finalizado.`,
				status: 'info',
				duration: 5000,
				isClosable: true
			});
	}, [newReport]); */

	// Chakra Color Mode
	const mainhealthlab = useColorModeValue('appai.darker', 'appai.darker');
	const inputBg = useColorModeValue('white', 'gray.800');
	let mainText = useColorModeValue('gray.700', 'gray.200');
	let navbarIcon = useColorModeValue('white', 'white');
	const searchIcon = useColorModeValue('gray.700', 'gray.200');

	const handleSignout = () => {
		window.localStorage.removeItem(JWT_TOKEN_COGNITO);
		window.localStorage.removeItem(JWT_TOKEN_COGNITO_ID);
		Auth.signOut({ global: true });
	};

	if (secondary) {
		navbarIcon = 'white';
		mainText = 'white';
	}
	const settingsRef = React.useRef();

	return (
		<Flex
			pe={{ sm: '0px', md: '16px' }}
			w={{ sm: '100%', md: 'auto' }}
			alignItems="center"
		>
			<SidebarResponsive
				logoText={props.logoText}
				secondary={props.secondary}
				routes={menuItems}
				{...rest}
			/>
			{/* <RegionalSelect /> */}
			<Icon
				icon="gear"
				me="16px"
				ref={settingsRef}
				onClick={props.onOpen}
				color={navbarIcon}
				w="20px"
				h="20px"
			/>
			<HStack spacing={2}>
				<Reports />
				<NavLink to="/sistema/perfil">
					<Button
						ms="0px"
						px="0px"
						color={navbarIcon}
						variant="transparent-with-icon"
						leftIcon={
							<Icon
								icon="user"
								color={navbarIcon}
								w="24px"
								h="24px"
								me="0px"
							/>
						}
					>
						<Text
							fontWeight="bold"
							color={navbarIcon}
							cursor="pointer"
							mr="16px"
							display={{ sm: 'none', md: 'flex' }}
						>
							{user?.attributes?.name}
						</Text>
					</Button>
				</NavLink>
				<Text
					fontWeight="bold"
					color={navbarIcon}
					cursor="pointer"
					mr="16px"
					display={{ sm: 'none', md: 'flex' }}
					onClick={handleSignout}
				>
					<Icon icon="logout" margin="8" w="24px" h="24px" />
					Sair
				</Text>
			</HStack>
		</Flex>
	);
}

HeaderLinks.propTypes = {
	variant: PropTypes.string,
	fixed: PropTypes.bool,
	secondary: PropTypes.bool,
	onOpen: PropTypes.func
};
