import { Box, Flex, Spinner, Text, Tooltip } from '@chakra-ui/react';
import { useEffect } from 'react';
import { usePaginateReports, useReports, useDispatch } from 'hooks/reports';

import Icon from 'components/FontAwesomeIcons/Icon';
import AsyncTable from 'components/Tables/AsyncTable';
import { useFilters } from 'hooks/filters';
import pusher from 'services/pusher';
import { updateReport } from 'hooks/reports/actionCreators';
import { CheckCircle } from '@phosphor-icons/react';
import { useAuth } from 'hooks/useAuth';

const columns = [
	{
		Header: 'Título',
		accessor: 'label'
	},
	{
		Header: 'Status',
		accessor: 'status',
		Cell: ({ value }) => (
			<Flex>
				{value == 'report_done' ? (
					<Tooltip placement="top" label="Finalizado">
						<Flex>
							<CheckCircle
								color="#48BB78"
								size="22"
								weight="duotone"
							/>
						</Flex>
					</Tooltip>
				) : (
					<Tooltip placement="top" label="Gerando">
						<Box>
							<Spinner size="sm" />
						</Box>
					</Tooltip>
				)}
			</Flex>
		)
	},
	{
		Header: 'Link',
		accessor: 'url',
		Cell: ({ value }) =>
			value ? (
				<Text
					textAlign="left"
					display="flex"
					justifyContent="start"
					_hover={{ color: 'notrelife.800' }}
				>
					<a
						href={value}
						target="_blank"
						rel="noreferrer"
						aria-label="Download"
					>
						<Icon icon="download" />
					</a>
				</Text>
			) : null
	}
];

const ReportsTable = () => {
	const { user } = useAuth();

	const dispatch = useDispatch();
	const { filters } = useFilters();
	const { rows, page, perPage, total } = useReports();
	const [paginateReports, { loading }] = usePaginateReports({});

	const fetchData = ({ pageSize, pageIndex, search }) => {
		if (pageSize != perPage) pageIndex = 1;
		paginateReports({
			variables: {
				first: pageSize || perPage,
				page: pageIndex || page,
				search: search || '',
				orderBy: [
					{
						column: 'CREATED_AT',
						order: 'DESC'
					}
				],
				where: {
					column: 'USER_ID',
					operator: 'EQ',
					value: user.id
				},
				...filters
			}
		});
	};

	useEffect(() => {
		if (filters && Object.keys(filters).length > 0) {
			paginateReports({
				variables: {
					first: perPage,
					page,
					...filters
				}
			});
		}
	}, [filters]);

	useEffect(() => {
		const channel = pusher.subscribe('test-channel');
		channel.bind('report-finished', (data) => {
			if (data.report && data.report.user_id == user.id) {
				updateReport(data.report, dispatch);
			}
		});

		return () => {
			channel.unbind('report-finished');
			pusher.unsubscribe('test-channel');
		};
	}, []);

	return (
		<AsyncTable
			fetchData={fetchData}
			isLoaded={!loading}
			data={!loading ? rows : [{}, {}, {}, {}]}
			columns={columns}
			total={total}
			size={perPage}
			asyncPage={page}
			searchByName
			withoutActions
			baseRoute="reports"
		/>
	);
};

export default ReportsTable;
