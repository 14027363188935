import { createContext, useContext, useReducer, useState } from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import {
	GET_TOPICS,
	GET_TOPICS_PAGINATE,
	GET_TOPIC,
	CREATE_TOPIC,
	UPDATE_TOPIC,
	DELETE_TOPIC
} from 'graphql/topics';

import { ACTION_TYPES, reducers } from './reducers';

const TopicsStateContext = createContext();
const TopicsDispatchContext = createContext();

const initialState = {
	rows: [],
	page: 1,
	perPage: 15,
	topics: {}
};

const TopicsProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducers, initialState);
	const [toDelete, setToDelete] = useState(null);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	return (
		<TopicsStateContext.Provider
			value={{
				...state,
				toDelete,
				setToDelete,
				deleteModalOpen,
				setDeleteModalOpen
			}}
		>
			<TopicsDispatchContext.Provider value={dispatch}>
				{children}
			</TopicsDispatchContext.Provider>
		</TopicsStateContext.Provider>
	);
};

const useTopics = () => {
	const context = useContext(TopicsStateContext);

	if (!context)
		throw new Error('useTopics must be used within an TopicsProvider');

	return context;
};

const useDispatch = () => {
	const dispatch = useContext(TopicsDispatchContext);

	if (dispatch === undefined)
		throw new Error('useDispatch must be used within a TopicsProvider');

	return dispatch;
};

const useAllTopics = () => {
	const dispatch = useDispatch();

	return useQuery(GET_TOPICS, {
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			dispatch({ type: ACTION_TYPES.ALL, data: data.topics });
		}
	});
};

const useLazyTopics = () => {
	const dispatch = useDispatch();

	return useLazyQuery(GET_TOPICS, {
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			dispatch({ type: ACTION_TYPES.ALL, data: data.topics });
		}
	});
};

const usePaginateTopics = () => {
	const dispatch = useDispatch();

	return useLazyQuery(GET_TOPICS_PAGINATE, {
		onCompleted: (data) => {
			window.history.replaceState(
				null,
				'',
				`./topicos?page=${data.paginateTopics.paginatorInfo.currentPage}&size=${data.paginateTopics.paginatorInfo.perPage}`
			);
			dispatch({
				type: ACTION_TYPES.LIST,
				data: data.paginateTopics
			});
		}
	});
};

const useCreateTopic = (options = {}) => {
	const dispatch = useDispatch();
	const { onSuccess, onError } = options;

	return useMutation(CREATE_TOPIC, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
			dispatch({ type: ACTION_TYPES.ADD, data: data.createTopic });
		},
		onError: (error) => {
			if (onError) onError(error);
		}
	});
};

const useTopic = (id) => {
	const dispatch = useContext(TopicsDispatchContext);

	return useQuery(GET_TOPIC, {
		fetchPolicy: 'no-cache',
		variables: {
			id
		},
		onCompleted: (data) =>
			dispatch({ type: ACTION_TYPES.VIEW, data: data.getTopic })
	});
};

const useUpdateTopic = (options = {}) => {
	const dispatch = useContext(TopicsDispatchContext);
	const { onSuccess, onError } = options;

	return useMutation(UPDATE_TOPIC, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
			dispatch({ type: ACTION_TYPES.UPDATE, data: data.updateTopic });
		},
		onError: (error) => {
			if (onError) onError(error);
		},
		refetchQueries: [
			{
				query: GET_TOPICS_PAGINATE
			}
		]
	});
};

const useDeleteTopic = (options = {}) => {
	const dispatch = useDispatch();
	const { onSuccess } = options;

	return useMutation(DELETE_TOPIC, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess();
			dispatch({
				type: ACTION_TYPES.REMOVE,
				id: data.deleteTopic.id
			});
		}
	});
};

export {
	TopicsProvider,
	useTopics,
	useAllTopics,
	useLazyTopics,
	useCreateTopic,
	usePaginateTopics,
	useTopic,
	useUpdateTopic,
	useDeleteTopic
};
