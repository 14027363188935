import { mode } from '@chakra-ui/theme-tools';

export const globalStyles = {
	colors: {
		gray: {
			700: '#1f2733'
		}
	},
	styles: {
		global: (props) => ({
			body: {
				bg:
					document.documentElement.layout === 'auth'
						? mode('gray.100', 'gray.800')(props)
						: mode('gray.100', 'gray.800')(props),
				fontFamily: 'Helvetica, sans-serif'
			},
			html: {
				fontFamily: 'Helvetica, sans-serif',
				overflowY: 'overlay'
			}
		})
	}
};
