import { ResponsiveBar } from '@nivo/bar';
import { useColorModeValue } from '@chakra-ui/react';
import { padding } from 'variables/nivo/bar';
import { monochromeMainColors } from 'variables/colors';
import {
	axisLeft,
	themeDark,
	themeLight,
	tooltip
} from 'variables/nivo/default';

const buildData = (data) => {
	const values = [];

	if (!data) return values;

	values.push({
		id: 'obesidade_3',
		label: 'Obesidade Grau 3',
		value: data.obesidade_3
	});
	values.push({
		id: 'obesidade_2',
		label: 'Obesidade Grau 2',
		value: data.obesidade_2
	});
	values.push({
		id: 'obesidade_1',
		label: 'Obesidade Grau 1',
		value: data.obesidade_1
	});
	values.push({
		id: 'sobrepeso',
		label: 'Soberpeso',
		value: data.sobrepeso
	});
	values.push({
		id: 'normal',
		label: 'Peso normal',
		value: data.normal
	});
	values.push({
		id: 'abaixo',
		label: 'Abaixo do Peso',
		value: data.abaixo
	});

	return values;
};

const BeneficiariesByIMCChart = ({ data }) => (
	<ResponsiveBar
		data={buildData(data)}
		keys={['value']}
		indexBy="label"
		layout="horizontal"
		margin={{ top: 5, right: 10, bottom: 5, left: 120 }}
		padding={padding}
		colors={monochromeMainColors}
		labelSkipWidth={11}
		theme={useColorModeValue(themeLight, themeDark)}
		axisBottom={null}
		axisLeft={axisLeft}
		labelTextColor="#FFF"
		tooltip={({ indexValue, value }) => tooltip(indexValue, value)}
	/>
);

export default BeneficiariesByIMCChart;
