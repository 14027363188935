import { createContext, useContext, useReducer } from 'react';

import { useLazyQuery, useQuery } from '@apollo/client';

import { GET_REPORTS } from 'graphql/lastReports';

import { reducers } from './reducers';
import { listLastReports } from './actionCreators';

const LastReportsStateContext = createContext();
const LastReportsDispatchContext = createContext();

const initialState = {
	reports: [
		/* {
			id: "111",
			label: "Teste",
			status: "report_done",
			url: "localhost",
			created_at: "2024-03-18 20:00:00"
		},
		{
			id: "222",
			label: "Teste 2",
			status: "loading",
			url: "localhost",
			created_at: "2024-03-18 18:00:00"
		}, */
	]
};

const LastReportsProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducers, initialState);

	return (
		<LastReportsStateContext.Provider
			value={{
				...state
			}}
		>
			<LastReportsDispatchContext.Provider value={dispatch}>
				{children}
			</LastReportsDispatchContext.Provider>
		</LastReportsStateContext.Provider>
	);
};

const useLastReports = () => {
	const context = useContext(LastReportsStateContext);

	if (!context)
		throw new Error('useReports must be used within an ReportsProvider');

	return context;
};

const useDispatch = () => {
	const dispatch = useContext(LastReportsDispatchContext);

	if (dispatch === undefined)
		throw new Error('useDispatch must be used within a ReportsProvider');

	return dispatch;
};

const useGetLastReports = (userId) => {
	const dispatch = useDispatch();

	return useQuery(GET_REPORTS, {
		variables: {
			userId
		},
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			listLastReports(data.getLastReports, dispatch);
		}
	});
};

const useLazyGetLastReports = (userId) => {
	const dispatch = useDispatch();

	return useLazyQuery(GET_REPORTS, {
		variables: {
			userId
		},
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			listLastReports(data.getLastReports, dispatch);
		}
	});
};

export {
	LastReportsProvider,
	useDispatch,
	useLastReports,
	useGetLastReports,
	useLazyGetLastReports
};
