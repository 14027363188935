import { useHistory, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	HStack,
	Input,
	Select,
	Skeleton,
	Switch,
	Text,
	useColorModeValue,
	useToast
} from '@chakra-ui/react';
import { useUpdateUser, useUser } from 'hooks/users';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';

const schema = yup.object().shape({
	name: yup.string().required('O campo Nome é obrigatório'),
	email: yup
		.string()
		.email('Insira um e-mail válido')
		.required('O campo E-mail é obrigatório'),
	type: yup.string().required('O campo Tipo de Perfil é obrigatório')
});

const EditUser = () => {
	const { id } = useParams();
	const { data, loading } = useUser(id);
	const user = data?.getUser ?? {};

	const history = useHistory();

	const methods = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		resolver: yupResolver(schema)
	});
	const toast = useToast();

	const {
		register,
		reset,
		handleSubmit,
		formState: { errors }
	} = methods;

	const [updateUser, { loading: loadingUpdate }] = useUpdateUser({
		onSuccess: (data) => {
			reset();
			toast({
				title: 'Usuário atualizado com sucesso.',
				status: 'success',
				duration: 5000,
				isClosable: true
			});
			history.push(
				`/sistema/administracao/usuarios/${data.updateUser.id}`
			);
		},
		onError: () => {
			toast({
				title: 'Ocorreu um erro',
				status: 'error',
				duration: 5000,
				isClosable: true
			});
		}
	});

	const onSubmit = (data) => {
		updateUser({
			variables: {
				input: {
					id,
					...data
				}
			}
		});
	};

	const inputHover = useColorModeValue('appai.main', 'appai.main');
	const textColor = useColorModeValue('gray.700', 'white');

	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card w={{ md: '100%', lg: '50%' }}>
				<CardHeader mb="40px">
					<Flex direction="column">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Editar Usuário
						</Text>
					</Flex>
				</CardHeader>
				<CardBody>
					<FormProvider {...methods}>
						<form
							style={{ width: '100%' }}
							onSubmit={handleSubmit(onSubmit)}
						>
							<Flex direction="column" w="100%">
								<Grid
									templateColumns={{
										sm: '1fr',
										md: 'repeat(2, 1fr)'
									}}
									gap="24px"
								>
									<FormControl isInvalid={errors.name}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Nome
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('name')}
												defaultValue={user.name || null}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors?.name && (
											<FormErrorMessage>
												{errors?.name?.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.email}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											E-mail
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('email')}
												defaultValue={
													user.email || null
												}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors?.email && (
											<FormErrorMessage>
												{errors?.email?.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.type}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Perfil
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Select
												{...register('type')}
												focusBorderColor={inputHover}
												defaultValue={user.type || null}
												borderRadius="8px"
												fontSize="md"
												placeholder="Selecione uma opção..."
											>
												<option value="administrador">
													Administrador
												</option>
												<option value="operacional">
													Operacional
												</option>
											</Select>
										)}
										{errors?.type && (
											<FormErrorMessage>
												{errors?.type?.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl>
										<HStack alignItems="center" mb="24px">
											<FormLabel
												color={textColor}
												fontWeight="bold"
												fontSize="xs"
												my="auto"
											>
												Ativo
											</FormLabel>
											{loading ? null : (
												<Switch
													{...register('status')}
													defaultChecked={user.status}
													colorScheme="purple"
												/>
											)}
										</HStack>
									</FormControl>
								</Grid>
								<Flex
									w="full"
									justifyContent="flex-end"
									mt="24px"
								>
									<HStack spacing={4}>
										<Button
											disabled={loadingUpdate}
											variant="ghost"
											colorScheme="gray"
											alignSelf="flex-end"
											size="md"
											onClick={() => history.goBack()}
										>
											Cancelar
										</Button>
										<Button
											isLoading={loadingUpdate}
											disabled={loadingUpdate}
											colorScheme="purple"
											type="submit"
											alignSelf="flex-end"
											size="md"
										>
											Atualizar
										</Button>
									</HStack>
								</Flex>
							</Flex>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default EditUser;
