import { gql } from '@apollo/client';

export const GET_USERS = gql`
	query GetUsers {
		listUsers {
			id
			email
			name
			type
			status
			created_at
			updated_at
			deleted_at
		}
	}
`;

export const GET_USERS_PAGINATE = gql`
	query PaginateUsers(
		$first: Int! = 30
		$page: Int! = 1
		$search: String
		$where: QueryPaginateUsersWhereWhereConditions
	) {
		paginateUsers(
			first: $first
			page: $page
			search: $search
			where: $where
		) {
			paginatorInfo {
				count
				currentPage
				firstItem
				perPage
				lastItem
				total
			}
			data {
				id
				email
				name
				type
				status
				created_at
				updated_at
				deleted_at
			}
		}
	}
`;

export const GET_USER = gql`
	query GetUser($id: ID!) {
		getUser(id: $id) {
			id
			email
			name
			type
			status
			created_at
			updated_at
			deleted_at
		}
	}
`;

export const CREATE_USER = gql`
	mutation CreateUser($input: CreateUser) {
		createUser(input: $input) {
			id
			email
			name
			status
			created_at
			updated_at
			deleted_at
		}
	}
`;

export const UPDATE_USER = gql`
	mutation UpdateUser($input: UpdateUser) {
		updateUser(input: $input) {
			id
			email
			name
			status
			created_at
			updated_at
			deleted_at
		}
	}
`;

export const DELETE_USER = gql`
	mutation DeleteUser($where: WhereConditions) {
		deleteUser(where: $where) {
			id
		}
	}
`;
