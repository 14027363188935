import { ResponsivePie } from '@nivo/pie';
import { useColorModeValue } from '@chakra-ui/react';
import { risksColorsPlus } from 'variables/colors';

import { border, tooltip } from 'variables/nivo/default';
import {
	arc,
	arcLabelsTextColor,
	arcLinkLabelsColor,
	legendsDark,
	legendsLight,
	margin,
	pieProps
} from 'variables/nivo/pie';

const buildData = (data) => {
	const values = [];

	if (!data) return values;

	values.push({
		id: 'excelente',
		label: 'Excelente',
		value: data.excelente
	});
	values.push({
		id: 'boa',
		label: 'Boa',
		value: data.boa
	});
	values.push({
		id: 'regular',
		label: 'Regular',
		value: data.regular
	});
	values.push({
		id: 'ruim',
		label: 'Ruim',
		value: data.ruim
	});
	values.push({
		id: 'pessima',
		label: 'Péssima',
		value: data.pessima
	});
	return values;
};

const total = (data) =>
	data.excelente + data.boa + data.regular + data.ruim + data.pessima;

const HealthPerceptionChart = ({ data }) => (
	<ResponsivePie
		data={buildData(data)}
		margin={margin}
		colors={risksColorsPlus}
		{...border}
		{...arc}
		arcLinkLabelsColor={arcLinkLabelsColor}
		arcLabelsTextColor={arcLabelsTextColor}
		legends={useColorModeValue(legendsLight, legendsDark)}
		tooltip={({ datum }) =>
			tooltip(datum.label, datum.value, total(data), datum.color)
		}
		{...pieProps}
		enableArcLabels={false}
		innerRadius={0.7}
	/>
);

export default HealthPerceptionChart;
