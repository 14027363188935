import { ACTION_TYPES } from './reducers';

const updateReport = (report, dispatch) => {
	dispatch({
		type: ACTION_TYPES.UPDATE,
		data: report
	});
};

export { updateReport };
