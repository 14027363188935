import { Grid, HStack, Skeleton, Text, VStack } from '@chakra-ui/react';
import StatusIndicator from 'components/StatusIndicator';
import dateFormat from 'functions/dateFormat';
import { phoneFormat } from 'functions/maskFormats';

const AssociateDetails = ({ associate, isLoading = true }) => (
	<VStack spacing={5} w="full" alignItems="flex-start">
		<VStack spacing={1} alignItems="flex-start">
			<Text fontSize="md">Nome completo</Text>
			<Skeleton height="27px" minW="140" isLoaded={!isLoading}>
				<Text fontSize="lg">{associate?.name}</Text>
			</Skeleton>
		</VStack>
		<VStack spacing={1} alignItems="flex-start">
			<Text fontSize="md">ID APPAI</Text>
			<Skeleton height="27px" minW="140" isLoaded={!isLoading}>
				<Text fontSize="lg">{associate?.appai_id}</Text>
			</Skeleton>
		</VStack>
		<VStack spacing={1} alignItems="flex-start">
			<Text fontSize="md">Data de Inscrição</Text>
			<Skeleton height="27px" minW="140" isLoaded={!isLoading}>
				<Text fontSize="lg">{dateFormat(associate?.created_at)}</Text>
			</Skeleton>
		</VStack>
		<Grid
			w="full"
			templateColumns={{ sm: '1fr', md: 'repeat(2, 1fr)' }}
			gap="24px"
			mb="24px"
		>
			<VStack spacing={1} alignItems="flex-start">
				<Text fontSize="md">Status</Text>
				<Skeleton height="27px" minW="140" isLoaded={!isLoading}>
					<StatusIndicator isActive={associate.status} />
				</Skeleton>
			</VStack>
			<VStack spacing={1} alignItems="flex-start">
				<Text fontSize="md">Aceite</Text>
				<Skeleton height="27px" minW="140" isLoaded={!isLoading}>
					<Text fontSize="lg">
						{associate?.accepted_at ? 'Sim' : 'Não'}
					</Text>
				</Skeleton>
			</VStack>
			<VStack spacing={1} alignItems="flex-start">
				<Text fontSize="md">E-mail</Text>
				<Skeleton height="27px" minW="140" isLoaded={!isLoading}>
					<Text fontSize="lg">{associate?.email}</Text>
				</Skeleton>
			</VStack>
			<Text />
			<VStack spacing={1} alignItems="flex-start">
				<Text fontSize="md">Telefone</Text>
				<Skeleton height="27px" minW="140" isLoaded={!isLoading}>
					<Text fontSize="lg">{phoneFormat(associate?.phone)}</Text>
				</Skeleton>
			</VStack>
			<Text />
			<VStack spacing={1} alignItems="flex-start">
				<Text fontSize="md">Newsletter enviado</Text>
				<Skeleton height="27px" minW="140" isLoaded={!isLoading}>
					<Text fontSize="lg">
						{dateFormat(associate?.newsletter_sent_at)}
					</Text>
				</Skeleton>
			</VStack>
		</Grid>
		<VStack spacing={1} alignItems="flex-start">
			<HStack spacing={4} justifyContent="space-between" />
		</VStack>
	</VStack>
);

export default AssociateDetails;
