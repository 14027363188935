import { gql } from '@apollo/client';

export const GET_CONTACTS = gql`
	query GetContacts {
		listContacts {
			id
			name
			appai_id
			phone
			created_at
			updated_at
			deleted_at
		}
	}
`;

export const GET_CONTACTS_PAGINATE = gql`
	query PaginateContacts(
		$first: Int! = 30
		$page: Int! = 1
		$search: String
	) {
		paginateContacts(first: $first, page: $page, search: $search) {
			paginatorInfo {
				count
				currentPage
				firstItem
				perPage
				lastItem
				total
			}
			data {
				id
				name
				appai_id
				phone
				created_at
				updated_at
				deleted_at
			}
		}
	}
`;

export const GET_CONTACT = gql`
	query GetContact($id: ID!) {
		getContact(id: $id) {
			id
			name
			appai_id
			phone
			created_at
			updated_at
			deleted_at
		}
	}
`;

export const CREATE_CONTACT = gql`
	mutation CreateContact($input: CreateContact) {
		createContact(input: $input) {
			id
			name
			appai_id
			phone
			created_at
			updated_at
			deleted_at
		}
	}
`;

export const IMPORT_CONTACTS = gql`
	mutation ImportContacts($file: Upload!) {
		importContacts(file: $file)
	}
`;

export const UPDATE_CONTACT = gql`
	mutation UpdateContact($input: UpdateContact) {
		updateContact(input: $input) {
			id
			name
			appai_id
			phone
			created_at
			updated_at
			deleted_at
		}
	}
`;

export const DELETE_CONTACT = gql`
	mutation DeleteContact($where: WhereConditions) {
		deleteContact(where: $where) {
			id
		}
	}
`;
