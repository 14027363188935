import { createContext, useContext, useReducer, useState } from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import {
	GET_USER,
	CREATE_USER,
	UPDATE_USER,
	DELETE_USER,
	GET_USERS_PAGINATE
} from 'graphql/users';
import { ACTION_TYPES, reducers } from './reducers';

const UsersStateContext = createContext();
const UsersDispatchContext = createContext();

const initialState = {
	rows: [],
	page: 1,
	perPage: 15,
	user: {}
};

const UsersProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducers, initialState);
	const [toDelete, setToDelete] = useState(null);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	return (
		<UsersStateContext.Provider
			value={{
				...state,
				toDelete,
				setToDelete,
				deleteModalOpen,
				setDeleteModalOpen
			}}
		>
			<UsersDispatchContext.Provider value={dispatch}>
				{children}
			</UsersDispatchContext.Provider>
		</UsersStateContext.Provider>
	);
};

const useUsers = () => {
	const context = useContext(UsersStateContext);

	if (!context)
		throw new Error('useUsers must be used within an UsersProvider');

	return context;
};

const useDispatch = () => {
	const dispatch = useContext(UsersDispatchContext);

	if (dispatch === undefined)
		throw new Error('useDispatch must be used within a UsersProvider');

	return dispatch;
};

const usePaginateUsers = () => {
	const dispatch = useDispatch();

	return useLazyQuery(GET_USERS_PAGINATE, {
		onCompleted: (data) => {
			window.history.replaceState(
				null,
				'',
				`./usuarios?page=${data.paginateUsers.paginatorInfo.currentPage}&size=${data.paginateUsers.paginatorInfo.perPage}`
			);
			dispatch({ type: ACTION_TYPES.LIST, data: data.paginateUsers });
		}
	});
};

const useCreateUser = (options = {}) => {
	const dispatch = useDispatch();
	const { onSuccess, onError } = options;

	return useMutation(CREATE_USER, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
			dispatch({ type: ACTION_TYPES.ADD, data: data.createUser });
		},
		onError: (error) => {
			if (onError) onError(error);
		}
	});
};

const useUser = (id) => {
	const dispatch = useContext(UsersDispatchContext);

	return useQuery(GET_USER, {
		fetchPolicy: 'no-cache',
		variables: {
			id
		},
		onCompleted: (data) =>
			dispatch({ type: ACTION_TYPES.VIEW, data: data.getUser })
	});
};

const useUpdateUser = (options = {}) => {
	const dispatch = useContext(UsersDispatchContext);
	const { onSuccess, onError } = options;

	return useMutation(UPDATE_USER, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
			dispatch({ type: ACTION_TYPES.UPDATE, data: data.updateUser });
		},
		onError: (error) => {
			if (onError) onError(error);
		},
		refetchQueries: [
			{
				query: GET_USERS_PAGINATE
			}
		]
	});
};

const useDeleteUser = (options = {}) => {
	const dispatch = useDispatch();
	const { onSuccess } = options;

	return useMutation(DELETE_USER, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess();
			dispatch({
				type: ACTION_TYPES.REMOVE,
				data: data.deleteUser
			});
		}
	});
};

export {
	UsersProvider,
	useUsers,
	useCreateUser,
	usePaginateUsers,
	useUser,
	useUpdateUser,
	useDeleteUser
};
