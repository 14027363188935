import {
	Box,
	Button,
	Flex,
	Image,
	Text,
	VStack,
	useColorModeValue
} from '@chakra-ui/react';
import {
	PencilLine,
	Trash,
	ArrowUp,
	ArrowDown
} from '@phosphor-icons/react';
import { useDrag, useDrop } from 'react-dnd';

const CarouselImage = ({
	imageIndex,
	imageListLength,
	image = {},
	loadingOrder,
	onEdit,
	onRemove,
	reorderImage,
	moveImage,
	onDrop
}) => {
	const imageUrl = image?.imports[0]?.key;

	const canReorderUp = imageIndex !== 0 && !loadingOrder;
	const canReorderDown = imageIndex + 1 !== imageListLength && !loadingOrder;
	
	const arrowColor = useColorModeValue('#1A202C', '#FAFAFA')
	const arrowDisabledColor = useColorModeValue('#A0AEC0', '#A0AEC0')

	const onReorder = (id, direction = '') => {
		if (direction == 'up' && !canReorderUp) return;
		if (direction == 'down' && !canReorderDown) return;
		reorderImage(id, direction)
	};

	const [, ref] = useDrag({
		type: 'CarouselImage',
		item: { image },
		canDrag: false,
		end: (item, monitor) => {
			const didDrop = monitor.didDrop();
			if (didDrop) {
				onDrop(item.order);
			}
		}
	});

	const [, drop] = useDrop({
		accept: 'CarouselImage',
		hover: (item) => {
			if (item.order === imageIndex + 1) return;

			const hoverIndex = imageIndex + 1;
			const dragIndex = item.order;

			moveImage(dragIndex, hoverIndex);
			item.order = hoverIndex;
		}
	});

	return (
		<Box
			w="full"
			h={200}
			border="1px solid"
			borderColor="gray.300"
			borderRadius={10}
			ref={(node) => ref(drop(node))}
			opacity={1}
		>
			<Flex
				w="full"
				h="full"
				px={8}
				justifyContent="space-between"
				alignItems="center"
			>
				<Flex flexGrow={1} h="full">
					<Flex
						w={20}
						h="full"
						justifyContent="space-around"
						direction="column"
					>
						<Flex
							cursor={canReorderUp ? 'pointer' : 'not-allowed'}
							onClick={() => onReorder(image.id, 'up')}
							h="60px"
							w="60px"
							justifyContent="center"
							alignItems="center"
						>
							<ArrowUp
								size={26}
								color={canReorderUp ? arrowColor : arrowDisabledColor}
							/>
						</Flex>
						<Flex
							cursor={canReorderDown ? 'pointer' : 'not-allowed'}
							onClick={() => onReorder(image.id, 'down')}
							h="60px"
							w="60px"
							justifyContent="center"
							alignItems="center"
						>
							<ArrowDown
								size={26}
								color={canReorderDown ? arrowColor : arrowDisabledColor}
							/>
						</Flex>
					</Flex>
					<Flex w={20} my="auto">
						<Text fontSize="md" fontWeight="bold">
							# {image.order}
						</Text>
					</Flex>
				</Flex>
				<Flex
					flexGrow={3}
					w="280px"
					h="full"
					my="auto"
					justifyContent="center"
				>
					<Image
						w="full"
						h="full"
						objectFit="contain"
						src={imageUrl}
						alt={image.title}
						title={image.title}
					/>
				</Flex>
				<Flex flexGrow={0} my="auto">
					<VStack gap={2} w="130px">
						<Button
							onClick={() => onEdit(image)}
							w="full"
							colorScheme="blue"
							leftIcon={<PencilLine size={16} />}
						>
							Editar
						</Button>
						<Button
							onClick={() => onRemove(image)}
							w="full"
							colorScheme="red"
							leftIcon={<Trash size={16} />}
						>
							Remover
						</Button>
					</VStack>
				</Flex>
			</Flex>
		</Box>
	);
};

export default CarouselImage;
