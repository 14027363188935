const ACTION_TYPES = {
	LIST: 'LIST',
	SELECT: 'SELECT',
	ADD: 'ADD',
	VIEW: 'VIEW',
	UPDATE: 'UPDATE',
	REMOVE: 'REMOVE'
};

const reducers = (state, action) => {
	switch (action.type) {
		case ACTION_TYPES.LIST: {
			return {
				...state,
				rows: action.data.data,
				page: action.data.paginatorInfo.currentPage,
				perPage: action.data.paginatorInfo.perPage,
				total: action.data.paginatorInfo.total,
				currentPage: action.data.paginatorInfo.currentPage
			};
		}
		case ACTION_TYPES.ADD: {
			return {
				...state,
				rows: [...state.rows, action.data],
				total: state.total++
			};
		}
		case ACTION_TYPES.VIEW: {
			return {
				...state,
				report: action.data
			};
		}
		case ACTION_TYPES.UPDATE: {
			return {
				...state,
				rows: state.rows.map((item) => {
					if (action.data.id == item.id) {
						item = action.data;
					}
					return item;
				})
			};
		}
		default: {
			return state;
		}
	}
};

export { reducers, ACTION_TYPES };
