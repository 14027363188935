import { gql } from '@apollo/client';

export const GET_REPORTS = gql`
	query GetLastReports($userId: ID!) {
		getLastReports(userId: $userId) {
			id
			label
			status
			url
			created_at
		}
	}
`;
