/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import { usePagination, useSortBy, useTable } from 'react-table';
import {
	Badge,
	Button,
	Flex,
	Select,
	Skeleton,
	Stack,
	Table,
	Tbody,
	Icon as ChakraIcon,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useColorMode,
	Input,
	useColorModeValue,
	Tooltip,
	Box
} from '@chakra-ui/react';

import {
	TiArrowSortedDown,
	TiArrowSortedUp,
	TiArrowUnsorted
} from 'react-icons/ti';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';

import { Eye, PencilLine, Trash } from '@phosphor-icons/react';
import Icon from '../FontAwesomeIcons/Icon';

const TableActions = ({
	id,
	row,
	route,
	removeAction = null,
	noView = false,
	noEdit = false,
	noDelete = false,
	customActions = []
}) => {
	const hoverColor = useColorModeValue('green.600', 'green.500');

	return (
		<>
			{customActions &&
				customActions.map(
					(action) =>
						((action.visible && action.visible(row)) ||
							!action.visible) && (
							<Tooltip
								key={action.label}
								label={action.label}
								placement="top"
								closeOnClick
							>
								<Text
									fontWeight="bold"
									fontSize="sm"
									mr={4}
									_hover={{
										color: hoverColor,
										cursor: 'pointer'
									}}
									onClick={() => action.callback(id, row)}
								>
									{action.icon ? (
										<Box>{action.icon}</Box>
									) : action.label ? (
										<Text>{action.label}</Text>
									) : null}
								</Text>
							</Tooltip>
						)
				)}
			{!noView && (
				<Text
					fontWeight="bold"
					fontSize="sm"
					mr={4}
					_hover={{ color: hoverColor }}
				>
					<NavLink to={`${route}/${id}`}>
						<Eye size={20} />
					</NavLink>
				</Text>
			)}
			{!noEdit && (
				<Text
					fontWeight="bold"
					fontSize="sm"
					mr={4}
					_hover={{ color: hoverColor }}
				>
					<NavLink to={`${route}/${id}/editar`}>
						<PencilLine size={20} />
					</NavLink>
				</Text>
			)}
			{!noDelete && removeAction && (
				<Text
					fontWeight="bold"
					fontSize="sm"
					_hover={{ color: hoverColor }}
					onClick={() => removeAction(id)}
				>
					<Trash size={20} />
				</Text>
			)}
		</>
	);
};

const AsyncTable = ({
	columns,
	data,
	fetchData,
	route,
	isLoaded,
	removeAction = null,
	linkRow = false,
	noView = false,
	noEdit = false,
	noDelete = false,
	customActions = [],
	pageCount: controlledPageCount,
	total,
	baseRoute,
	size = 30,
	asyncPage = 1,
	noPage = false,
	clickItem,
	withoutActions = false,
	searchByName = false,
	filters = []
}) => {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		// Get the state from the instance
		state: { pageIndex, pageSize }
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0, pageSize: size }, // Pass our hoisted table state
			manualPagination: true, // Tell the usePagination

			// hook that we'll handle our own data fetching
			// This means we'll also have to provide our own
			// pageCount.
			pageCount: controlledPageCount
		},
		useSortBy,
		usePagination
	);

	const rowColor = useColorModeValue('gray.50', 'gray.900');
	const inputHover = useColorModeValue('appai.main', 'appai.main');
	const textColor = useColorModeValue('black', 'white');
	const history = useHistory();
	const [filter, setFilter] = useState(null);

	const [currentPage, setCurrentPage] = React.useState(1);
	const filterChange = (val) => {
		setFilter(val);
	};

	const [searchValue, setSearchValue] = useState(null);

	const handleSearch = (value) => {
		fetchData({
			pageIndex: currentPage == 0 ? 1 : currentPage,
			pageSize,
			search: value,
		});
	};

	useEffect(() => {
		if (typeof searchValue == 'string') {
			if (searchValue.length >= 4 || searchValue.length == 0) {
				const timerId = setTimeout(() => {
					handleSearch(searchValue);
				}, 800);

				return () => {
					clearTimeout(timerId);
				};
			}
		}
	}, [searchValue]);

	const handlSearchChange = (value) => {
		setSearchValue(value);
	};

	React.useEffect(() => {
		fetchData({
			pageIndex: currentPage == 0 ? 1 : currentPage,
			pageSize,
			search: searchValue,
		});
	}, [currentPage, pageSize]);

	const createPages = () => {
		const realSize = size || pageSize;
		const pages = Math.ceil(total / realSize);

		const leftPages = asyncPage - 3 < 1 ? asyncPage - 1 : asyncPage - 3;
		const rightPages = asyncPage + 3 > pages ? pages : asyncPage + 3;

		const arrPageCount = [];

		for (
			let i = asyncPage;
			asyncPage === 3 ? i > leftPages - 1 : i > leftPages;
			i--
		) {
			if (asyncPage !== i) arrPageCount.unshift(i);
		}

		for (
			let i = asyncPage;
			pages === 2 ? i < rightPages + 1 : i < rightPages;
			i++
		) {
			arrPageCount.push(i);
		}

		if (arrPageCount[0] !== 1) arrPageCount.unshift(1);

		if (
			arrPageCount[arrPageCount.length - 1] !== pages &&
			arrPageCount.length > 1
		)
			arrPageCount.push(pages);

		return arrPageCount;
	};

	const handlePageClick = (page) => {
		setCurrentPage(page == 1 ? 0 : page);
	};

	return (
		<Flex
			direction="column"
			w="100%"
			overflowX={{ sm: 'scroll', lg: 'hidden' }}
		>
			<Flex width="100%" justifyContent="space-between">
				<Flex justifyContent="flex-start">
					{searchByName && (
						<Stack
							direction="column"
							spacing="12px"
							my="24px"
							px="22px"
						>
							<Text
								fontSize="xs"
								color="gray.400"
								fontWeight="normal"
							>
								Pesquisar
							</Text>
							<Input
								defaultValue={null}
								focusBorderColor={inputHover}
								value={searchValue}
								placeholder="Pesquisar..."
								onChange={({ target }) => handlSearchChange(target.value)}
								color="gray.500"
								size="sm"
								borderRadius="12px"
								cursor="pointer"
							/>
						</Stack>
					)}
					{filters.length > 0 &&
						filters.map((fil) => (
							<Stack
								key={fil.label}
								direction="column"
								spacing="12px"
								my="24px"
								px="22px"
							>
								<Text
									fontSize="sm"
									color="gray.400"
									fontWeight="normal"
								>
									Filtrar por {fil.label}
								</Text>
								<Select
									defaultValue={null}
									focusBorderColor={inputHover}
									placeholder="Selecione uma opção..."
									onChange={({ target }) =>
										filterChange(target.value)
									}
									color="gray.500"
									size="sm"
									borderRadius="12px"
									cursor="pointer"
								>
									{fil.options.map((op) => (
										<option key={op.value} value={op.value}>
											{op.label}
										</option>
									))}
								</Select>
							</Stack>
						))}
				</Flex>
				<Stack
					hidden={noPage}
					direction="row"
					spacing="12px"
					align="center"
					my="24px"
					px="22px"
				>
					<Skeleton isLoaded={isLoaded}>
						<Select
							value={size}
							defaultValue={size}
							onChange={(e) =>
								setPageSize(Number(e.target.value))
							}
							focusBorderColor={inputHover}
							color="gray.500"
							size="sm"
							borderRadius="12px"
							maxW="75px"
							cursor="pointer"
						>
							<option>5</option>
							<option>10</option>
							<option>15</option>
							<option>20</option>
							<option>25</option>
							<option>30</option>
						</Select>
					</Skeleton>
					<Text fontSize="xs" color="gray.400" fontWeight="normal">
						linhas por páginas
					</Text>
				</Stack>
			</Flex>
			<Table
				{...getTableProps()}
				variant="simple"
				color="gray.500"
				mb="24px"
				whiteSpace={{ sm: 'nowrap', lg: 'normal' }}
				overflow="scroll"
			>
				<Thead>
					{headerGroups.map((headerGroup, index) => (
						<Tr {...headerGroup.getHeaderGroupProps()} key={index}>
							{headerGroup.headers.map((column, index) => (
								<Th
									{...column.getHeaderProps(
										column.getSortByToggleProps()
									)}
									key={index}
								>
									<Flex
										justify="space-between"
										align="center"
										fontSize={{ sm: '10px', lg: '12px' }}
										color="gray.400"
									>
										{column.render('Header')}
										{1 == 2 && (
											<ChakraIcon
												w={{ sm: '10px', md: '14px' }}
												h={{ sm: '10px', md: '14px' }}
												color={
													columns.isSorted
														? 'gray.500'
														: 'gray.400'
												}
												float="right"
												as={
													column.isSorted
														? column.isSortedDesc
															? TiArrowSortedDown
															: TiArrowSortedUp
														: TiArrowUnsorted
												}
											/>
										)}
									</Flex>
								</Th>
							))}
							<Th
								hidden={
									(noView &&
										noEdit &&
										noDelete &&
										customActions.length < 1) ||
									withoutActions
								}
							>
								<Flex
									justify="flex-end"
									align="right"
									fontSize={{ sm: '10px', lg: '12px' }}
									color="gray.400"
								>
									Ações
								</Flex>
							</Th>
						</Tr>
					))}
				</Thead>
				<Tbody {...getTableBodyProps()}>
					{isLoaded && page.length == 0 ? (
						<Tr>
							<Td colSpan={12} textAlign="center">
								<Text py={10} fontSize="lg" color={textColor}>
									Não há registros
								</Text>
							</Td>
						</Tr>
					) : (
						page.map((row, index) => {
							prepareRow(row);
							return (
								<Tr
									style={{
										cursor:
											linkRow || clickItem
												? 'pointer'
												: 'initial'
									}}
									transition="all ease 0.2s"
									_hover={
										(linkRow || clickItem) && {
											backgroundColor: rowColor
										}
									}
									{...row.getRowProps()}
									key={index}
									onClick={() =>
										clickItem
											? clickItem(row.original)
											: null
									}
								>
									{row.cells.map((cell, index) => (
										<Td
											onClick={
												linkRow
													? () =>
														history.push(
															`${baseRoute}/${row.original.id}`
														)
													: null
											}
											{...cell.getCellProps()}
											fontSize={{ sm: '14px' }}
											key={index}
										>
											<Skeleton
												minH="25px"
												height="fit-content"
												isLoaded={isLoaded}
											>
												{cell.render('Cell')}
											</Skeleton>
										</Td>
									))}
									{!withoutActions && (
										<Td hidden={!isLoaded}>
											<Flex
												justify="flex-end"
												align="right"
												fontSize={{
													sm: '10px',
													lg: '12px'
												}}
												color="gray.400"
											>
												<TableActions
													id={row.original.id}
													row={row.original}
													route={baseRoute}
													removeAction={removeAction}
													noView={noView}
													noEdit={noEdit}
													noDelete={noDelete}
													customActions={
														customActions
													}
												/>
											</Flex>
										</Td>
									)}
								</Tr>
							);
						})
					)}
				</Tbody>
			</Table>
			{/*
                    Pagination can be built however you'd like.
                    This is just a very basic UI implementation:
                 */}
			<Flex
				direction={{ sm: 'column', md: 'row' }}
				w="100%"
				justify="space-between"
				align="center"
				px={{ md: '22px' }}
			>
				<Text
					hidden={!isLoaded}
					fontSize="sm"
					color="gray.500"
					fontWeight="normal"
					mb={{ sm: '24px', md: '0px' }}
				>
					Mostrando {pageSize * pageIndex + 1} à{' '}
					{pageSize * pageIndex + data.length} do total de {total}{' '}
					resultados
				</Text>
				<Stack
					direction="row"
					alignSelf="flex-end"
					spacing="4px"
					ms="auto"
				>
					<Button
						variant="no-hover"
						onClick={() => previousPage()}
						transition="all .5s ease"
						w="40px"
						h="40px"
						borderRadius="50%"
						bg="#fff"
						border="1px solid lightgray"
						display={canPreviousPage ? 'flex' : 'none'}
						_hover={{
							bg: 'gray.200',
							opacity: '0.7',
							borderColor: 'gray.500'
						}}
					>
						<ChakraIcon
							as={GrFormPrevious}
							w="16px"
							h="16px"
							color="gray.400"
						/>
					</Button>
					{!noPage &&
						isLoaded &&
						createPages().map((pageNumber, index) => (
							<Button
								variant="no-hover"
								transition="all .5s ease"
								onClick={() => handlePageClick(pageNumber)}
								w="40px"
								h="40px"
								borderRadius="160px"
								bg={
									pageNumber === asyncPage
										? 'appai.main'
										: '#fff'
								}
								border="1px solid lightgray"
								key={index}
							>
								<Text
									fontSize="sm"
									color={
										pageNumber === asyncPage
											? '#fff'
											: 'gray.600'
									}
								>
									{pageNumber}
								</Text>
							</Button>
						))}
				</Stack>
			</Flex>
		</Flex>
	);
};

export default AsyncTable;
