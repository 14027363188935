import {
	Box,
	Button,
	Flex,
	Grid,
	HStack,
	Spinner,
	Text,
	VStack,
	useColorModeValue,
	useDisclosure
} from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';

import { useCallback, useState } from 'react';
import {
	useCarousel,
	useDispatch,
	useAllCarousel,
	useUpdateCarouselOrder
} from 'hooks/carousel';

import CarouselImage from './CarouselImage';

import AddImage from './AddImage';
import EditImage from './EditImage';
import RemoveImage from './RemoveImage';

const Carousel = () => {
	const { carousel: carouselImages } = useCarousel();
	const { loading } = useAllCarousel();
	const [updateCarouselOrder, { loading: loadingOrder }] =
		useUpdateCarouselOrder();

	const dispatch = useDispatch();

	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		isOpen: isEditOpen,
		onOpen: onEditOpen,
		onClose: onEditClose
	} = useDisclosure();
	const {
		isOpen: isRemoveOpen,
		onOpen: onRemoveOpen,
		onClose: onRemoveClose
	} = useDisclosure();
	const textColor = useColorModeValue('gray.700', 'white');

	const [imageRef, setImageRef] = useState({});

	const onEdit = (id) => {
		setImageRef(id);
		onEditOpen();
	};

	const onRemove = (id) => {
		setImageRef(id);
		onRemoveOpen();
	};

	const reorderImage = (id, direction) => {
		const movingImage = carouselImages.find((img) => img.id == id);
		const images = [...carouselImages];
		const directionSum = direction == 'up' ? -1 : 1;
		const lastOrder = Number(movingImage.order);
		const newOrder = lastOrder + directionSum;
		movingImage.order = newOrder;
		const newCarouselImages = images.map((img) => {
			if (img.id == id) img = movingImage;
			else if (
				direction == 'up' &&
				img.order <= lastOrder &&
				img.order >= newOrder
			) {
				const newOrder = Number(img.order) + 1;
				img.order = newOrder;
			} else if (
				direction == 'down' &&
				img.order >= lastOrder &&
				img.order <= newOrder
			) {
				const newOrder = Number(img.order) - 1;
				img.order = newOrder;
			}
			return {
				id: img.id,
				order: String(img.order)
			};
		});

		updateCarouselOrder({
			variables: {
				input: {
					images: newCarouselImages
				}
			}
		});
	};

	const reorderOnRemove = (id, order) => {
		if (order == carouselImages.length) return;
		const images = carouselImages.filter((img) => img.id != id);
		const newImages = images.map((img) => {
			if (img.order > order) {
				const newOrder = Number(img.order) - 1;
				img.order = newOrder;
			}
			return {
				id: img.id,
				order: String(img.order)
			};
		});

		updateCarouselOrder({
			variables: {
				input: {
					images: newImages
				}
			}
		});
	};

	const [draggingIndex, setDraggingIndex] = useState(null);

	const moveImage = useCallback(
		(dragIndex, hoverIndex) => {
			const dragItem = carouselImages[dragIndex];
			const updatedComponents = [...carouselImages];
			updatedComponents.splice(dragIndex, 1);
			updatedComponents.splice(hoverIndex, 0, dragItem);

			setDraggingIndex(hoverIndex);
		},
		[carouselImages]
	);

	const onDrop = (finalIndex) => {
		if (draggingIndex !== null) {
			const updatedComponents = [...carouselImages];
			const dragItem = carouselImages[draggingIndex - 1];
			updatedComponents.splice(draggingIndex - 1, 1);
			updatedComponents.splice(finalIndex, 0, dragItem);

			setDraggingIndex(null);
			dispatch({ type: 'UPDATE_LIST_ORDER', data: updatedComponents });
		}
	};

	return (
		<Grid
			templateColumns={{ sm: '1fr', md: '1fr' }}
			templateRows={{ sm: 'repeat(8, 1fr)', md: 'repeat(1, 1fr)' }}
			gap="12px"
			pt={{ sm: '125px', lg: '160px' }}
		>
			<Card px="0px" pb="24px" minHeight="320px">
				<CardHeader mb="34px" px="22px">
					<Box w="100%">
						<Flex
							justify="space-between"
							align="center"
							fontSize={{ sm: '10px', lg: '12px' }}
							color="gray.400"
						>
							<Text
								color={textColor}
								fontSize="lg"
								fontWeight="bold"
							>
								Carrossel
							</Text>
							<HStack spacing={4}>
								<Button colorScheme="purple" onClick={onOpen}>
									Cadastrar Imagem
								</Button>
							</HStack>
						</Flex>
					</Box>
				</CardHeader>
				<CardBody h="full">
					<VStack w="full" mx={8} spacing={2}>
						{loading ? (
							<Flex
								w="full"
								h="full"
								justifyContent="center"
								alignItems="center"
							>
								<Spinner size="md" />
							</Flex>
						) : carouselImages.length === 0 ? (
							<Flex
								w="full"
								h="full"
								justifyContent="center"
								alignItems="center"
							>
								<Text fontSize="md">
									Não existem imagens cadastradas no
									carrossel.
								</Text>
							</Flex>
						) : (
							carouselImages
								.sort((a, b) => (a.order > b.order ? 1 : -1))
								.map((img, x) => (
									<CarouselImage
										key={img.order}
										imageIndex={x}
										imageListLength={carouselImages.length}
										image={img}
										loadingOrder={loadingOrder}
										onEdit={onEdit}
										onRemove={onRemove}
										reorderImage={reorderImage}
										moveImage={moveImage}
										onDrop={onDrop}
									/>
								))
						)}
					</VStack>
				</CardBody>
			</Card>
			<AddImage
				isOpen={isOpen}
				onClose={onClose}
				images={carouselImages.length}
			/>
			{isEditOpen && (
				<EditImage
					image={imageRef}
					isOpen={isEditOpen}
					onClose={onEditClose}
				/>
			)}
			<RemoveImage
				image={imageRef}
				isOpen={isRemoveOpen}
				onClose={onRemoveClose}
				reorder={reorderOnRemove}
			/>
		</Grid>
	);
};

export default Carousel;
