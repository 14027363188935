import { useHistory, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	HStack,
	Input,
	Select,
	Skeleton,
	Text,
	useColorModeValue,
	useToast
} from '@chakra-ui/react';
import {
	useUpdateCategory,
	useCategory,
	useAllCategories
} from 'hooks/categories';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import UploadField from 'components/UploadField';

const schema = yup.object().shape({
	title: yup.string().required('O campo Título é obrigatório')
});

const EditCategory = () => {
	const { id } = useParams();
	const { data, loading } = useCategory(id);
	const category = data?.getCategory ?? {};

	const history = useHistory();

	const methods = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		resolver: yupResolver(schema),
		defaultValues: category
	});

	const {
		data: { listCategories: categories } = [],
		loading: loadingCategories
	} = useAllCategories();

	const toast = useToast();

	const {
		register,
		reset,
		handleSubmit,
		formState: { errors }
	} = methods;

	const [updateCategory, { loading: loadingUpdate }] = useUpdateCategory({
		onSuccess: (data) => {
			reset();
			toast({
				title: 'Categoria atualizada com sucesso.',
				status: 'success',
				duration: 5000,
				isClosable: true
			});
			history.push(
				`/sistema/conteudos/categorias/${data.updateCategory.id}`
			);
		},
		onError: () => {
			toast({
				title: 'Ocorreu um erro',
				status: 'error',
				duration: 5000,
				isClosable: true
			});
		}
	});

	const onSubmit = (data) => {
		if (data?.parent_id === '') data.parent_id = null;

		const variables = {
			input: {
				id,
				...data,
				class: 'Category',
				column_to_store: 'icon'
			}
		};

		updateCategory({ variables });
	};

	const inputHover = useColorModeValue('appai.main', 'appai.main');
	const textColor = useColorModeValue('gray.700', 'white');

	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card w={{ md: '100%', lg: '50%' }}>
				<CardHeader mb="40px">
					<Flex direction="column">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Editar Categoria
						</Text>
					</Flex>
				</CardHeader>
				<CardBody>
					<FormProvider {...methods}>
						<form
							style={{ width: '100%' }}
							onSubmit={handleSubmit(onSubmit)}
						>
							<Flex direction="column" w="100%">
								<Grid
									templateColumns={{
										sm: '1fr',
										md: 'repeat(2, 1fr)'
									}}
									gap="24px"
								>
									<FormControl isInvalid={errors.title}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Título
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('title')}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
												defaultValue={category?.title}
											/>
										)}
										{errors.title && (
											<FormErrorMessage>
												{errors.title.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.description}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Descrição
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('description')}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
												defaultValue={
													category?.description
												}
											/>
										)}
										{errors.description && (
											<FormErrorMessage>
												{errors.description.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.parent_id}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Categoria pai
										</FormLabel>
										{loadingCategories ? (
											<Skeleton height="40px" />
										) : (
											<Select
												{...register('parent_id')}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
												placeholder="Selecione..."
												defaultValue={
													category?.parent_id
												}
											>
												{categories
													?.filter(
														(c) =>
															c?.id !=
															category?.id
													)
													?.map((category) => (
														<option
															value={category.id}
														>
															{category.title}
														</option>
													))}
											</Select>
										)}
										{errors.parent_id && (
											<FormErrorMessage>
												{errors.parent_id.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl
										isInvalid={errors.button_label}
									>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Etiqueta do botão
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('button_label')}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
												defaultValue={
													category?.button_label
												}
											/>
										)}
										{errors.button_label && (
											<FormErrorMessage>
												{errors.button_label.message}
											</FormErrorMessage>
										)}
									</FormControl>
								</Grid>

								<UploadField
									label="Imagem da capa"
									filePrefix="icon"
								/>

								<Flex
									w="full"
									justifyContent="flex-end"
									mt="24px"
								>
									<HStack spacing={4}>
										<Button
											disabled={loadingUpdate}
											variant="ghost"
											colorScheme="gray"
											alignSelf="flex-end"
											size="md"
											onClick={() => history.goBack()}
										>
											Cancelar
										</Button>
										<Button
											isLoading={loadingUpdate}
											disabled={loadingUpdate}
											colorScheme="purple"
											type="submit"
											alignSelf="flex-end"
											size="md"
										>
											Atualizar
										</Button>
									</HStack>
								</Flex>
							</Flex>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default EditCategory;
