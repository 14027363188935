import { gql } from '@apollo/client';

export const GET_CAROUSEL = gql`
	query GetCarousel {
		listCarousel {
			id
			title
			order
			imports {
				id
				type
				filename
				key
			}
		}
	}
`;

export const CREATE_CAROUSEL = gql`
	mutation CreateCarousel($input: CreateCarousel) {
		createCarousel(input: $input) {
			id
			title
			order
			imports {
				id
				type
				filename
				key
			}
		}
	}
`;

export const UPDATE_CAROUSEL = gql`
	mutation UpdateCarousel($input: UpdateCarousel) {
		updateCarousel(input: $input) {
			id
			title
			order
			imports {
				id
				type
				filename
				key
			}
		}
	}
`;

export const UPDATE_CAROUSEL_ORDER = gql`
	mutation UpdateCarouselOrder($input: UpdateCarouselOrderInput) {
		updateCarouselOrder(input: $input) {
			id
			title
			order
		}
	}
`;

export const DELETE_CAROUSEL = gql`
	mutation DeleteCarousel($where: WhereConditions) {
		deleteCarousel(where: $where) {
			id
		}
	}
`;
