import { ResponsivePie } from '@nivo/pie';
import { useColorModeValue } from '@chakra-ui/react';
import { risksColorsPlus } from 'variables/colors';

import { border, tooltip } from 'variables/nivo/default';
import {
	arc,
	arcLabelsTextColor,
	arcLinkLabelsColor,
	legendsDark,
	legendsLight,
	margin,
	pieProps
} from 'variables/nivo/pie';

const buildData = (data) => {
	const values = [];

	if (!data) return values;

	values.push({
		id: 'nunca',
		label: 'Nunca',
		value: data.nunca
	});
	values.push({
		id: 'raramente',
		label: 'Raramente',
		value: data.raramente
	});
	values.push({
		id: 'menos_tres',
		label: 'Menos de três doses',
		value: data.menos_tres
	});
	values.push({
		id: 'tres_seis',
		label: 'Três a seis doses',
		value: data.tres_seis
	});
	values.push({
		id: 'sete_mais',
		label: 'Sete ou mais doses',
		value: data.sete_mais
	});
	return values;
};

const total = (data) =>
	data.nunca +
	data.raramente +
	data.menos_tres +
	data.tres_seis +
	data.sete_mais;

const DrinkConsumptionChart = ({ data }) => (
	<ResponsivePie
		data={buildData(data)}
		margin={margin}
		colors={risksColorsPlus}
		{...border}
		{...arc}
		arcLinkLabelsColor={arcLinkLabelsColor}
		arcLabelsTextColor={arcLabelsTextColor}
		legends={useColorModeValue(legendsLight, legendsDark)}
		tooltip={({ datum }) =>
			tooltip(datum.label, datum.value, total(data), datum.color)
		}
		{...pieProps}
		enableArcLabels={false}
		innerRadius={0.7}
	/>
);

export default DrinkConsumptionChart;
