import { useHistory, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	HStack,
	Input,
	Select,
	Skeleton,
	Text,
	useColorModeValue,
	useToast
} from '@chakra-ui/react';
import { useTicket, useUpdateTicket } from 'hooks/tickets';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';

const schema = yup.object().shape({
	status: yup.string().required('O campo Status é obrigatório'),
	priority: yup.string().required('O campo Prioridade é obrigatório'),
	source: yup.string().required('O campo é obrigatório'),
	causes: yup.string().required('O campo é obrigatório'),
	abstract: yup.string().required('O campo é obrigatório')
});

const EditTicket = () => {
	const { id } = useParams();
	const { data, loading } = useTicket(id);
	const ticket = data?.getTicket ?? {};

	const methods = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		resolver: yupResolver(schema)
	});

	const toast = useToast();
	const history = useHistory();

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors }
	} = methods;

	const [updateTicket, { loading: loadingUpdate }] = useUpdateTicket({
		onSuccess: (data) => {
			reset();
			toast({
				title: 'Suporte atualizado com sucesso.',
				status: 'success',
				duration: 5000,
				isClosable: true
			});
			history.push(
				`/sistema/administracao/suporte/${data?.updateTicket?.id}`
			);
		},
		onError: () => {
			toast({
				title: 'Ocorreu um erro',
				status: 'error',
				duration: 5000,
				isClosable: true
			});
		}
	});

	const onSubmit = (values) => {
		const variables = {
			input: {
				id: ticket.id,
				...values
			}
		};

		updateTicket({ variables });
	};

	const inputHover = useColorModeValue('appai.main', 'appai.main');
	const textColor = useColorModeValue('gray.700', 'white');

	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card w={{ md: '100%', lg: '50%' }}>
				<CardHeader mb="40px">
					<Flex direction="column">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Editar Suporte
						</Text>
					</Flex>
				</CardHeader>
				<CardBody>
					<FormProvider {...methods}>
						<form
							style={{ width: '100%' }}
							onSubmit={handleSubmit(onSubmit)}
						>
							<Flex direction="column" w="100%">
								<Grid
									templateColumns={{
										sm: '1fr',
										md: 'repeat(2, 1fr)'
									}}
									gap="24px"
								>
									<FormControl isInvalid={errors.status}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Status
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Select
												{...register('status')}
												defaultValue={ticket.status}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
												placeholder="Selecione uma opção..."
											>
												<option value="open">
													Aberto
												</option>
												<option value="working">
													Em andamento
												</option>
												<option value="closed">
													Finalizado
												</option>
											</Select>
										)}
										{errors.status && (
											<FormErrorMessage>
												{errors.status.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.priority}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Prioridade
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Select
												{...register('priority')}
												defaultValue={ticket.priority}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
												placeholder="Selecione uma opção..."
											>
												<option value="low">
													Baixa
												</option>
												<option value="normal">
													Normal
												</option>
												<option value="high">
													Alta
												</option>
												<option value="urgent">
													Urgente
												</option>
											</Select>
										)}
										{errors.priority && (
											<FormErrorMessage>
												{errors.priority.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.source}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Onde encontrou o erro?
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('source')}
												defaultValue={ticket.source}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors.source && (
											<FormErrorMessage>
												{errors.source.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.causes}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											O que o erro causa?
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('causes')}
												defaultValue={ticket.causes}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors.causes && (
											<FormErrorMessage>
												{errors.causes.message}
											</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={errors.abstract}>
										<FormLabel
											color={textColor}
											fontWeight="bold"
											fontSize="xs"
										>
											Resuma o erro encontrado
										</FormLabel>
										{loading ? (
											<Skeleton height="40px" />
										) : (
											<Input
												{...register('abstract')}
												defaultValue={ticket.abstract}
												focusBorderColor={inputHover}
												borderRadius="8px"
												fontSize="md"
											/>
										)}
										{errors.abstract && (
											<FormErrorMessage>
												{errors.abstract.message}
											</FormErrorMessage>
										)}
									</FormControl>
								</Grid>
								<Flex
									w="full"
									justifyContent="flex-end"
									mt="24px"
								>
									<HStack spacing={4}>
										<Button
											disabled={loadingUpdate}
											variant="ghost"
											colorScheme="gray"
											alignSelf="flex-end"
											size="sm"
											onClick={() => history.goBack()}
										>
											Cancelar
										</Button>
										<Button
											isLoading={loadingUpdate}
											disabled={loadingUpdate}
											colorScheme="purple"
											type="submit"
											alignSelf="flex-end"
											size="md"
										>
											Atualizar
										</Button>
									</HStack>
								</Flex>
							</Flex>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default EditTicket;
