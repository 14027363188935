import { useState, useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import {
	Center,
	Flex,
	FormControl,
	FormLabel,
	useColorModeValue
} from '@chakra-ui/react';
import Dropzone from 'react-dropzone';

export default ({
	label = 'Anexos',
	multiple = false,
	filePrefix = 'file'
}) => {
	const [files, setFiles] = useState([]);
	const [originalFiles, setOriginalFiles] = useState([]);

	const textColor = useColorModeValue('gray.700', 'white');

	const { setValue, unregister, getValues } = useFormContext();

	const getFilename = (file) => {
		const extension = file?.name?.split('.').pop();
		const timestamp = Date.now().toString(16);
		const random = Math.random().toString(16).substr(2, 8);

		return `${filePrefix}-${timestamp + random}.${extension}`;
	};

	const handleDrop = (uploads) => {
		const filesArr = [];
		const prevFiles = getValues('imports');

		setOriginalFiles(uploads);

		uploads.map((upload) => {
			const file = new File(
				[upload],
				`${getFilename(upload)}#${upload?.name}`,
				{
					type: upload?.type
				}
			);

			filesArr.push(file);
		});

		setFiles([...prevFiles, ...filesArr]);
	};

	useEffect(() => {
		setValue('imports', files);

		if (!files) unregister('imports');
	}, [files]);

	return (
		<FormControl>
			<FormLabel color={textColor} fontWeight="bold" fontSize="xs">
				{label}
			</FormLabel>
			<Center>
				<Flex
					justify="center"
					align="center"
					border="1px dashed #C7CCD4"
					borderRadius="15px"
					w="99%"
					mb="12px"
					padding="1em"
					minH="224px"
					cursor="pointer"
				>
					<Dropzone onDrop={handleDrop} multiple={multiple}>
						{({ getRootProps, getInputProps }) => (
							<div
								{...getRootProps({
									className: 'dropzone'
								})}
							>
								<input {...getInputProps()} />
								{files.length < 1 ? (
									<p>
										Arraste ou selecione{' '}
										{multiple && 'um ou mais arquivos'}
									</p>
								) : (
									<p>
										Selecionado{' '}
										{multiple && files.length > 1
											? `${files.length} itens`
											: originalFiles[0]?.name}{' '}
									</p>
								)}
							</div>
						)}
					</Dropzone>
				</Flex>
			</Center>
		</FormControl>
	);
};
