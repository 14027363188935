import { CategoriesProvider } from 'hooks/categories';
import { FiltersProvider } from 'hooks/filters';
import { TopicsProvider as Provider } from 'hooks/topics/index';
import ManagerQueryProvider from 'services/manager';

const TopicsProvider = ({ children }) => (
	<ManagerQueryProvider>
		<FiltersProvider>
			<CategoriesProvider>
				<Provider>{children}</Provider>
			</CategoriesProvider>
		</FiltersProvider>
	</ManagerQueryProvider>
);

export default TopicsProvider;
