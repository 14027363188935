import { createContext, useContext, useReducer, useState } from 'react';

import { useQuery, useLazyQuery, useMutation } from '@apollo/client';

import {
	PAGINATE_NEWSLETTER,
	GET_NEWSLETTER,
	CREATE_NEWSLETTER,
	UPDATE_NEWSLETTER
} from 'graphql/newsletters';

import { ACTION_TYPES, reducers } from './reducers';

const NewslettersStateContext = createContext();
const NewslettersDispatchContext = createContext();

const initialState = {
	rows: [],
	page: 1,
	perPage: 15,
	newsletter: {}
};

const NewsletterProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducers, initialState);
	const [toDelete, setToDelete] = useState(null);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	return (
		<NewslettersStateContext.Provider
			value={{
				...state,
				toDelete,
				setToDelete,
				deleteModalOpen,
				setDeleteModalOpen
			}}
		>
			<NewslettersDispatchContext.Provider value={dispatch}>
				{children}
			</NewslettersDispatchContext.Provider>
		</NewslettersStateContext.Provider>
	);
};

const useNewsletters = () => {
	const context = useContext(NewslettersStateContext);

	if (!context)
		throw new Error(
			'useNewsletters must be used within an NewslettersProvider'
		);

	return context;
};

const useDispatch = () => {
	const dispatch = useContext(NewslettersDispatchContext);

	if (dispatch === undefined)
		throw new Error(
			'useDispatch must be used within a NewslettersProvider'
		);

	return dispatch;
};

const usePaginateNewsletters = () => {
	const dispatch = useDispatch();

	return useLazyQuery(PAGINATE_NEWSLETTER, {
		onCompleted: (data) => {
			window.history.replaceState(
				null,
				'',
				`./newsletter?page=${data.paginateNewsletters.paginatorInfo.currentPage}&size=${data.paginateNewsletters.paginatorInfo.perPage}`
			);
			dispatch({
				type: ACTION_TYPES.LIST,
				data: data.paginateNewsletters
			});
		}
	});
};

const useNewsletter = (id) => {
	const dispatch = useContext(NewslettersDispatchContext);

	return useQuery(GET_NEWSLETTER, {
		fetchPolicy: 'no-cache',
		variables: {
			id
		},
		onCompleted: (data) =>
			dispatch({ type: ACTION_TYPES.VIEW, data: data.getNewsletter })
	});
};

const useCreateNewsletter = (options = {}) => {
	const dispatch = useDispatch();
	const { onSuccess, onError } = options;

	return useMutation(CREATE_NEWSLETTER, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
			dispatch({ type: ACTION_TYPES.ADD, data: data.createNewsletter });
		},
		onError: (error) => {
			if (onError) {
				onError(error?.graphQLErrors[0]?.extensions?.validation);}
		}
	});
};

const useUpdateNewsletter = (options = {}) => {
	const dispatch = useDispatch();
	const { onSuccess, onError } = options;

	return useMutation(UPDATE_NEWSLETTER, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
			dispatch({ type: ACTION_TYPES.ADD, data: data.updateNewsletter });
		},
		onError: (error) => {
			if (onError)
				onError(error?.graphQLErrors[0]?.extensions?.validation);
		}
	});
};

export {
	NewsletterProvider,
	useNewsletters,
	usePaginateNewsletters,
	useNewsletter,
	useCreateNewsletter,
	useUpdateNewsletter
};
