import { FiltersProvider } from 'hooks/filters';
import { CategoriesProvider as Provider } from 'hooks/categories/index';
import ManagerQueryProvider from 'services/manager';

const CategoriesProvider = ({ children }) => (
	<ManagerQueryProvider>
		<FiltersProvider>
			<Provider>{children}</Provider>
		</FiltersProvider>
	</ManagerQueryProvider>
);

export default CategoriesProvider;
