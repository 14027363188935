import { Link, useHistory, useParams } from 'react-router-dom';
import {
	Box,
	Button,
	Flex,
	FormControl,
	FormLabel,
	Grid,
	HStack,
	Text,
	Tooltip,
	useColorModeValue
} from '@chakra-ui/react';

import { useNewsletters, useNewsletter } from 'hooks/newsletters';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import dateFormat from 'functions/dateFormat';

const ViewNewsletter = () => {
	const { id } = useParams();
	const { newsletter } = useNewsletters();
	const { loading } = useNewsletter(id);
	const history = useHistory();
	const textColor = useColorModeValue('gray.700', 'white');

	if (loading) return <Text>Carregando...</Text>;
	return (
		<Flex
			direction="column"
			minH="100vh"
			align="center"
			pt={{ sm: '120px', lg: '160px' }}
		>
			<Card w={{ md: '100%', lg: '50%' }}>
				<CardHeader mb="40px">
					<Flex direction="row" w="100%" justify="space-between">
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							mb="3px"
						>
							Newsletter
						</Text>
						<Flex>
							<HStack spacing={4} alignItems="flex-start">
								{newsletter.total_sent > 0 ? (
									<Tooltip label="Newsletter já enviada e não pode ser editada">
										<Box>
											<Button
												colorScheme="purple"
												size="md"
												disabled
											>
												Editar
											</Button>
										</Box>
									</Tooltip>
								) : (
									<Link to={`${id}/editar`}>
										<Button colorScheme="purple" size="md">
											Editar
										</Button>
									</Link>
								)}
								<Button
									variant="ghost"
									colorScheme="gray"
									size="md"
									onClick={() => history.goBack()}
								>
									Voltar
								</Button>
							</HStack>
						</Flex>
					</Flex>
				</CardHeader>
				<CardBody>
					<Flex direction="column" w="100%">
						<Grid
							templateColumns={{
								sm: '1fr',
								md: 'repeat(2, 1fr)'
							}}
							gap="24px"
						>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Título
								</FormLabel>
								<Text fontSize="lg">{newsletter.title}</Text>
							</FormControl>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									URL
								</FormLabel>
								<Text fontSize="lg">{newsletter.url}</Text>
							</FormControl>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Agendado para
								</FormLabel>
								<Text fontSize="lg">
									{dateFormat(
										newsletter.scheduled_to,
										'dd/MM/yyyy'
									)}
								</Text>
							</FormControl>
							<FormControl>
								<FormLabel
									color={textColor}
									fontWeight="bold"
									fontSize="xs"
								>
									Total de Envios
								</FormLabel>
								<Text fontSize="lg">
									{newsletter.total_sent}
								</Text>
							</FormControl>
						</Grid>
					</Flex>
				</CardBody>
			</Card>
		</Flex>
	);
};

export default ViewNewsletter;
