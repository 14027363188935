import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useToast
} from '@chakra-ui/react';
import { useUpdateAssociateStatus } from 'hooks/associates';

const ActiveAssociateModal = ({ id, isOpen, onClose, isActive }) => {
	const toast = useToast();

	const [updateUser, { loading }] = useUpdateAssociateStatus({
		onSuccess: () => {
			toast({
				title: `Associado ${
					isActive ? 'desativado' : 'ativado'
				} com sucesso.`,
				status: 'success',
				duration: 5000,
				isClosable: true
			});
			onClose();
		},
		onError: () => {
			toast({
				title: 'Ocorreu um erro.',
				status: 'error',
				duration: 5000,
				isClosable: true
			});
		}
	});

	const onUpdate = () => {
		updateUser({
			variables: {
				input: {
					id,
					status: !isActive
				}
			}
		});
	};

	return (
		<Modal
			isCentered
			onClose={onClose}
			isOpen={isOpen}
			motionPreset="slideInBottom"
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					{isActive ? 'Desativar' : 'Ativar'} Associado
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					Por favor, confirme a{' '}
					{isActive ? 'desativação' : 'ativação'}.
				</ModalBody>
				<ModalFooter>
					<Button mr={3} variant="ghost" onClick={onClose}>
						Cancelar
					</Button>
					<Button
						isLoading={loading}
						colorScheme={isActive ? 'red' : 'green'}
						onClick={onUpdate}
					>
						{isActive ? 'Desativar' : 'Ativar'}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default ActiveAssociateModal;
