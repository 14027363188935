import { createContext, useContext, useReducer, useState } from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import {
	GET_POSTS,
	GET_POSTS_PAGINATE,
	GET_POST,
	CREATE_POST,
	UPDATE_POST,
	DELETE_POST,
	DELETE_IMPORT,
	GET_TEMPLATES
} from 'graphql/posts';

import { ACTION_TYPES, reducers } from './reducers';

const PostsStateContext = createContext();
const PostsDispatchContext = createContext();

const initialState = {
	rows: [],
	page: 1,
	perPage: 15,
	posts: {},
	templates: {}
};

const PostsProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducers, initialState);
	const [toDelete, setToDelete] = useState(null);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	return (
		<PostsStateContext.Provider
			value={{
				...state,
				toDelete,
				setToDelete,
				deleteModalOpen,
				setDeleteModalOpen
			}}
		>
			<PostsDispatchContext.Provider value={dispatch}>
				{children}
			</PostsDispatchContext.Provider>
		</PostsStateContext.Provider>
	);
};

const usePosts = () => {
	const context = useContext(PostsStateContext);

	if (!context)
		throw new Error('usePosts must be used within an PostsProvider');

	return context;
};

const useDispatch = () => {
	const dispatch = useContext(PostsDispatchContext);

	if (dispatch === undefined)
		throw new Error('useDispatch must be used within a PostsProvider');

	return dispatch;
};

const useAllPosts = () => {
	const dispatch = useDispatch();

	return useQuery(GET_POSTS, {
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			dispatch({ type: ACTION_TYPES.ALL, data: data.listPosts });
		}
	});
};

const usePaginatePosts = () => {
	const dispatch = useDispatch();

	return useLazyQuery(GET_POSTS_PAGINATE, {
		onCompleted: (data) => {
			window.history.replaceState(
				null,
				'',
				`./posts?page=${data.paginatePosts.paginatorInfo.currentPage}&size=${data.paginatePosts.paginatorInfo.perPage}`
			);
			dispatch({
				type: ACTION_TYPES.LIST,
				data: data.paginatePosts
			});
		}
	});
};

const useCreatePost = (options = {}) => {
	const dispatch = useDispatch();
	const { onSuccess, onError } = options;

	return useMutation(CREATE_POST, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
			dispatch({ type: ACTION_TYPES.ADD, data: data.createPost });
		},
		onError: (error) => {
			if (onError) onError(error);
		}
	});
};

const usePost = (id) => {
	const dispatch = useContext(PostsDispatchContext);

	return useQuery(GET_POST, {
		fetchPolicy: 'no-cache',
		variables: {
			id
		},
		onCompleted: (data) =>
			dispatch({ type: ACTION_TYPES.VIEW, data: data.getPost })
	});
};

const useUpdatePost = (options = {}) => {
	const dispatch = useContext(PostsDispatchContext);
	const { onSuccess, onError } = options;

	return useMutation(UPDATE_POST, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess(data);
			dispatch({ type: ACTION_TYPES.UPDATE, data: data.updatePost });
		},
		onError: (error) => {
			if (onError) onError(error);
		},
		refetchQueries: [
			{
				query: GET_POSTS_PAGINATE
			}
		]
	});
};

const useDeletePost = (options = {}) => {
	const dispatch = useDispatch();
	const { onSuccess } = options;

	return useMutation(DELETE_POST, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess();
			dispatch({
				type: ACTION_TYPES.REMOVE,
				id: data.deletePost.id
			});
		}
	});
};

const useDeleteImport = (options = {}) => {
	const dispatch = useDispatch();
	const { onSuccess } = options;

	return useMutation(DELETE_IMPORT, {
		onCompleted: (data) => {
			if (onSuccess) onSuccess();
			dispatch({
				type: ACTION_TYPES.REMOVE_IMPORT,
				id: data.deleteImport.id
			});
		}
	});
};

const useTemplates = () => {
	const dispatch = useDispatch();

	return useQuery(GET_TEMPLATES, {
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			dispatch({
				type: ACTION_TYPES.LIST_TEMPLATES,
				data: data.listTemplates
			});
		}
	});
};

export {
	PostsProvider,
	usePosts,
	useAllPosts,
	useCreatePost,
	usePaginatePosts,
	usePost,
	useUpdatePost,
	useDeletePost,
	useDeleteImport,
	useTemplates
};
